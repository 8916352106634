/* eslint-disable react/react-in-jsx-scope */
import { useState, useEffect } from 'react'
import { GoogleMap, Marker } from '@react-google-maps/api'
import axios from 'axios'
import './MapComponent.css'
import { locationSuggestion } from '../../services/Service'
import { normalizeAddress } from '../../helpers/Utility'
import { Images } from '../../helpers'

const MapComponent = ({
  inputFields,
  setInputFields,
  markerPosition,
  onMapPositionChange
}) => {
  const mapStyles = {
    height: '100%',
    width: '100%'
  }

  const [mapCenter, setMapCenter] = useState({
    lat: Number(inputFields.latitude) || 51.16,
    lng: Number(inputFields.longitude) || 10.45
  })

  const handleMapClick = (e) => {
    const { latLng } = e
    const lat = latLng.lat()
    const lng = latLng.lng()

    onMapPositionChange(lat, lng)

    reverseGeocode(lat, lng)
  }

  const reverseGeocode = async (lat, lng) => {
    try {
      const apiKey = locationSuggestion
      const response = await axios.get(
        `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${apiKey}`
      )

      if (response.data.status === 'OK') {
        const formattedAddress = response.data.results[0].formatted_address
        setInputFields((prev) => ({
          ...prev,
          address: normalizeAddress(formattedAddress),
          latitude: lat,
          longitude: lng
        }))
        setMapCenter({ lat, lng })
      } else {
        console.error('Reverse geocoding failed:', response.data.status)
      }
    } catch (error) {
      console.error('Error during reverse geocoding:', error)
    }
  }

  const fetchLocationFromInput = async (
    city_name,
    zip_code,
    country,
    province_name
  ) => {
    try {
      const apiKey = locationSuggestion
      const address = `${country},${province_name},${city_name},${zip_code}`
      const addressDecode = normalizeAddress(address)

      const response = await axios.get(
        `https://maps.googleapis.com/maps/api/geocode/json?address=${addressDecode}&key=${apiKey}`
      )

      if (response.data.status === 'OK') {
        const { location } = response.data.results[0].geometry
        setMapCenter({
          lat: location.lat,
          lng: location.lng
        })
        // setInputFields((prev) => ({
        //   ...prev,
        //   latitude: location.lat,
        //   longitude: location.lng
        // }))
      } else {
        console.error(
          'Geocode was not successful for the following reason:',
          response.data.status
        )
      }
    } catch (error) {
      console.error('Error fetching location:', error)
    }
  }

  useEffect(() => {
    if (
      inputFields.city_name ||
      inputFields.zip_code ||
      inputFields.country ||
      inputFields.province_name
    ) {
      fetchLocationFromInput(
        inputFields.city_name,
        inputFields.zip_code,
        inputFields.country,
        inputFields.province_name
      )
    }
  }, [
    inputFields.city_name,
    inputFields.zip_code,
    inputFields.country,
    inputFields.province_name
  ])

  // Update map center when marker position changes
  useEffect(() => {
    if (markerPosition) {
      setMapCenter(markerPosition)
    }
  }, [markerPosition])

  const mapOptions = {
    streetViewControl: false,
    fullscreenControl: false,
    zoomControl: false,
    mapTypeControl: false,
    disableDefaultUI: true,
    keyboardShortcuts: false
  }

  return (
    // <LoadScript googleMapsApiKey={locationSuggestion}>
    //   <GoogleMap
    //     mapContainerStyle={mapStyles}
    //     zoom={13}
    //     center={mapCenter}
    //     options={mapOptions}
    //     onClick={handleMapClick}
    //   >
    //     {markerPosition && <Marker position={markerPosition} />}
    //   </GoogleMap>
    // </LoadScript>
    <GoogleMap
      mapContainerStyle={mapStyles}
      zoom={13}
      center={mapCenter}
      options={mapOptions}
      onClick={handleMapClick}
    >
      {/* {markerPosition && <Marker position={markerPosition} />} */}
      {markerPosition && (
        <Marker
          position={markerPosition}
          icon={{
            url: Images.Location,
            scaledSize: new window.google.maps.Size(40, 40)
          }}
        />
      )}
    </GoogleMap>
  )
}

export default MapComponent
