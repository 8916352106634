/* eslint-disable max-len */
/* eslint-disable no-nested-ternary */
import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import '../../App.css'
import { English, Images } from '../../helpers'
import { setUserSliceData } from '../../services/http-services'
import PrimaryButtonIcon from '../LandingPages/Component/PrimaryButtonIcon'
// import English from '../../helpers/English'

const UserTypeSelectPage = () => {
  // const UserData = useSelector((state) => state.user?.userData)
  const navigate = useNavigate()
  const [activeType, setActiveType] = useState(0)

  const TypeItem = ({ type = 0 }) => (
    <div className={`w-full p-[16px] max-[1200px]:!p-2 bg-electricWhite flex flex-col rounded-[15px] ${activeType === type ? 'login-type-active' : 'border border-[#E4E4E4]'}`}>
      <div className="w-full h-[207px] max-[1700px]:h-[200px] bg-authInput mb-[20px] flex max-[1200px]:hidden items-center justify-center rounded-2xl">
        <img
          src={type === 1 ? Images.play_pink : type === 2 ? Images.play_blue : Images.play_dark_blue}
          alt="logo"
        />
      </div>
      <div className="d-blok flex items-center mb-[20px]">
        <img
          src={type === 1 ? Images.memberLogo : type === 2 ? Images.creatorLogo : Images.businessLogo}
          alt="logo"
        />
        <div className="flex flex-col ml-[8px]">
          <span className="italic text-main font-black text-[22px]">{type === 1 ? English.A12 : type === 2 ? English.A13 : English.A14 }</span>
          <span className="italic text-main font-black text-[12px]">{type === 1 ? English.B31 : type === 2 ? English.B32 : English.B33 }</span>
        </div>
      </div>
      <div className="text-[#303030] flex flex-1 font-medium text-[14px] max-[1200px]:text-[12px] mb-[20px] max-[1200px]:!mb-2">
        {type === 1 ? English.B34 : type === 2 ? English.B35 : English.B36}
      </div>
      <div className="border-t-2 border-dashed border-[#D4D4D4] w-full mb-[12px]" />
      <div className="mb-[10px]" onClick={() => setActiveType(type)}>
        {activeType === type && <img
          src={Images.checkedRoundDarkBlue}
          className="w-6 h-6"
          alt="Checked"
        />}
        {activeType !== type && <div className="w-6 h-6 border-3 border-[#8D8D8D] rounded-full" />}
      </div>
      <div className="d-blok flex items-center justify-between">
        <span className="italic text-main underline font-black text-[16px]">
          {type === 1 ? English.B37 : type === 2 ? English.B38 : English.B39}
        </span>
        <img alt="arrow" src={Images.arrowRight} />
      </div>
    </div>
  )


  const next = () => {
    const userTypeMap = {
      1: 'member',
      2: 'creator',
      3: 'business'
    }
    const userTypeName = userTypeMap[activeType]

    if (userTypeName) {
      setUserSliceData(userTypeName, 'user_type')
      navigate(
        activeType === 2
          ? '/user-information'
          : activeType === 3
          ? '/business-setup'
          : '/member-slide'
      )
    } else {
      console.error('Invalid activeType:', activeType)
    }
  }


  return (
    <div>
      <div className="py-[25px] px-[50px] bg-authInput h-fit max-[1440px]:py-[15px]">
        <div className="bg-electricWhite rounded-[28px] py-[30px] max-[1440px]:py-[15px] !px-5 max-w-[1412px] mx-auto h-fit relative">
          <img src={Images.typeSelectoreBg} alt="BG" className="absolute top-0 left-0" />
          <div className="bg-electricWhite flex flex-col !mb-5 max-[1200px]:!mb-2 max-w-[1110px] mx-auto items-center justify-center d-flex rounded-[20px]">
            <img
              src={Images.GbnLogo}
              className="mt-[18px] mb-[15px] h-[62px] w-[62px]"
              alt="logo"
            />
            <span className="italic text-main font-black text-[32px]">
              {English.B28}
            </span>
            <span className="italic text-main -translate-y-2 font-black text-[24px]">
              {English.B29}
            </span>
            <span className="-translate-y-2 mt-[12px] mb-[15px] text-secondary font-normal text-[16px]">
              {English.B30}
            </span>
          </div>
          <div className="max-w-[1110px] mx-auto grid grid-cols-3 !gap-4 !mb-5 max-[1200px]:!mb-2">
            <TypeItem type={1} />
            <TypeItem type={2} />
            <TypeItem type={3} />
          </div>
          <div className="d-flex flex items-center justify-center">
            <PrimaryButtonIcon
              text="NEXT"
              txtClass="italic text-white font-black text-[16px]"
              onClick={next}
              icon={Images.rightArrowWhite}
            />
          </div>
        </div>
      </div>
    </div>
  )
}
export default UserTypeSelectPage
