import React from 'react'
import Skeleton from 'react-loading-skeleton'

const DiscountSkeleton = () => {
  return (
    <div className="add-item-content add_room_modal px-4">
      <div className="type_close mb-2  local_sticky_header">
        <div className="d-flex align-items-center col-gap-10">
          <Skeleton count={1} height={25} width={250} />
        </div>
        <button className="cancel_icon" type="button">
          <Skeleton circle height={25} width={25} />
        </button>
      </div>

      <div className="item-details-input pt-0 mt-3">
        <div className="input-grid detail-grid">
          <div className="deal-label">
            <div className="my-1">
              <Skeleton count={1} height={40} width={320} />
            </div>
            <div className="my-1">
              <Skeleton count={1} height={40} width={320} />
            </div>
            <div className="my-1">
              <Skeleton count={1} height={40} width={320} />
            </div>
            <div className="my-1">
              <Skeleton count={1} height={40} width={320} />
            </div>
          </div>
          <div className="item-details-input ">
            <div className="room_image">
              <Skeleton count={1} height={250} width={320} />
            </div>
          </div>
        </div>
        <div className="local_button_row">
          <Skeleton borderRadius={12.5} count={1} height={40} width={100} />
        </div>
      </div>
    </div>
  )
}
export default DiscountSkeleton
