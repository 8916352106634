import React from 'react'
import Skeleton from 'react-loading-skeleton'
import { English, Images } from '../../../helpers'
import { urlSystemIcon } from '../../../services/Service'
import 'react-loading-skeleton/dist/skeleton.css'

const AppsItemMember = ({ item, toggleActive, loading }) => {
  return (
    <div
      key={item.id}
      className={`w-[265.5px] h-auto p-[16px] rounded-2xl bg-white ${item.isSelected ? 'selected' : 'selectable'
        }`}
    >
      {loading ?
      (
        <div className="flex flex-col gap-3">
          <div>
            <div className="flex items-center justify-between">
              <div className="flex items-center gap-2">
                <Skeleton circle width={40} height={40} />
                <div className="flex flex-col gap-2">
                  <Skeleton width={80} height={12} />
                  <Skeleton width={120} height={16} />
                  <Skeleton width={60} height={10} />
                </div>
              </div>
              <div>
                <Skeleton width={40} height={20} />
              </div>
            </div>
          </div>
          <div className="flex justify-between items-center border-t border-[#D4D4D4] border-dashed cursor-pointer mt-2">
            <Skeleton width={80} height={20} />
            <Skeleton width={20} height={20} />
          </div>
        </div>
    ) : (
      <div className="flex flex-col gap-3">
        <div>
          <div className="flex items-center justify-between">
            <div className="flex items-center gap-2">
              <img src={urlSystemIcon + item.new_icon} alt="" />
              <div className="flex-row items-center gap-2">
                <p className="italic text-[12px] font-bold text-gradient mb-0">{item.type}</p>
                <h3 className="text-lg font-semibold mb-0">{item.name}</h3>
                <span
                  className="text-gradient italic text-[10px] font-bold"
                >
                  {item.active ? English.A127 : English.A128}
                </span>
              </div>
            </div>
            <div className="">
              <div className="custum-toggle">
                <label className="switch" htmlFor={`title-${item.id}`}>
                  <input checked={item.active} onChange={() => toggleActive(item.id)} id={`title-${item.id}`} key={`title-${item.id}`} type="checkbox" />
                  <div className="slider round" />
                </label>
              </div>
            </div>
          </div>
        </div>
        <div className="flex justify-between items-center border-t border-[#D4D4D4] border-dashed cursor-pointer">
          <div
            className="text-[#0B0A0A] font-extrabold text-base underline italic mt-2"
            type="button"
          >
            <a
              className="text-[#0B0A0A] font-extrabold text-base underline italic mt-2"
              href={item.web_link}
              target="_blank"
              rel="noreferrer"
            >
              {English.A123}
            </a>
          </div>
          <img
            className="ml-1 mt-2"
            src={Images.arrow_left_black}
            alt="arrow"
          />
        </div>
      </div>
    )}
    </div>
  )
}

export default AppsItemMember
