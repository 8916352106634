/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import Skeleton from 'react-loading-skeleton'
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs'
import ReactDatePicker from 'react-datepicker'
import { useNavigate, useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
import Pagination from '../Pagination/Pagination'
import {
  s3ImageOrganizer
} from '../../services/Service'
import ConfirmationModal from '../ConfirmationModal/ConfirmationModal'
import Loader from '../Loader/Loader'
import { compressImage, dataDecrypt } from '../../services/http-services'
import CommonButton from '../ButtonComponent/ButtonCommon'
import EventPopup from '../Local/addPopup/eventPopup'
import Event from '../Local/EventComponent/Event'
import CommonInput from '../InputComponet/CommonInput'
import {
  capitalizeFirstLetter,
  ForMobileNumber,
  lockScroll,
  normalizeAddress,
  unlockScroll
} from '../../helpers/Utility'
import OrderChart from '../Local/chart'
import CommonImageUpload from '../ImageCroper/StoreImageCroper'
import English from '../../helpers/English'
import Images from '../../helpers/Images'
import { APICall, EndPoints } from '../../services'

const EventComponent = ({ showCount, clickable = true }) => {
  const navigate = useNavigate()
  const { id } = useParams()
  const app_id = useSelector((state) => state.user?.app_id)
  const [isOrganizerLoading, setIsOrganizerLoading] = useState(false)
  const [loading, setLoading] = useState(false)
  const [delEventId, setDelEventId] = useState()
  const [ShowConfirmEventModel, setShowConfirmEventModel] = useState(false)
  const [isOrganizerData, setIsOrganizerData] = useState([])
  const [currentPage, setCurrentPage] = useState(1)
  const [totalPages, setTotalPages] = useState(1)
  const [updatingIndex, setUpdatingIndex] = useState(null)
  const [isEvent, setIsEvent] = useState(false)

  const [inputFields, setInputFields] = useState({
    city_id: '',
    contact_name: '',
    contact_designation: '',
    contact_phone: '',
    contact_profile_pic: '',
    contact_id: '',
    organizer_id: ''
  })

  const [isParticularEventData, setIsParticularEventData] = useState([])
  const [isOrganizerId, setIsOrganizerId] = useState()
  const [editData, setEditData] = useState({})
  const [isShow, setIsShow] = useState(false)

  const [particularOrganizerDetail, setParticularOrganizerDetail] =
    useState(false)
  const [personDetails, setPersonDetails] = useState(false)
  const [contactIndex, setContactIndex] = useState(null)
  const [delContactId, setDelContactId] = useState()
  const [isShowEventList, setIsShowEventList] = useState(true)
  const [isEdit, setIsEdit] = useState(false)
  const [isParticularLoading, setIsParticularLoading] = useState(false)
  const [ShowConfirmModel, setShowConfirmModel] = useState(false)
  const [isEventEdit, setIsEventEdit] = useState(false)
  const [isStep4Modified, setIsStep4Modified] = useState(false)
  const [initialInputFields, setInitialInputFields] = useState({
    contact_name: '',
    contact_designation: '',
    contact_phone: '',
    contact_profile_pic: ''
  })
  const [expandedIndex, setExpandedIndex] = useState(null)
  const [startDate, setStartDate] = useState(new Date())
  const [isFromOrganizerComponent, setIsFromOrganizerComponent] =
    useState(false)
  const [isPlatformData, setIsPlatformData] = useState([])

  const ShowConfirm = (videoId) => {
    setDelEventId(videoId)
    setShowConfirmEventModel(true)
  }

  const handleDeleteCancel = () => {
    setShowConfirmEventModel(false)
  }

  const clickNewOrganizerAdd = () => {
    setIsPlatformData([app_id.id])
    setIsFromOrganizerComponent(true)
    setIsEvent(true)
  }

  const clearContactValue = () => {
    setInputFields({
      ...inputFields,
      city_id: '',
      contact_name: '',
      contact_designation: '',
      contact_phone: '',
      contact_profile_pic: '',
      contact_id: ''
    })
    setIsEdit(false)
  }

  const onClickEdit = (data) => {
    setIsEvent(true)
    setIsEventEdit(true)
    setEditData({
      organizer_id: data?.organizer_id,
      name: data?.name,
      address: normalizeAddress(data?.address),
      description: data?.description,
      country_id: data?.country_id,
      province_id: data?.province_id,
      city_id: data?.city_id,
      zip_code: data?.zip_code,
      longitude: data?.longitude,
      latitude: data?.latitude,
      website: data?.website,
      logo: data?.logo,
      images: data?.images,
      contact_name1: data?.contact_details?.[0]?.contact_name ?? '',
      contact_name2: data?.contact_details?.[1]?.contact_name ?? '',
      contact_designation1:
        data?.contact_details?.[0]?.contact_designation ?? '',
      contact_designation2:
        data?.contact_details?.[1]?.contact_designation ?? '',
      contact_phone1: data?.contact_details?.[0]?.contact_phone ?? null,
      contact_phone2: data?.contact_details?.[1]?.contact_phone ?? null,
      contact_profile_pic: [
        data?.contact_details?.[0]?.contact_profile_pic,
        data?.contact_details?.[1]?.contact_profile_pic
      ]
    })
  }

  const onChange = (e) => {
    setInputFields((prev) => ({ ...prev, [e.target.name]: e.target.value }))
  }

  const clearValues = () => {
    setIsParticularEventData([])
  }

  const handleCancel = () => {
    setIsEvent(false)
  }

  const getStoreLink = (website) => {
    if (!website) return undefined
    return website.startsWith('http') ? website : `http://${website}`
  }

  const ShowContactConfirm = (videoId) => {
    setDelContactId(videoId)
    setShowConfirmModel(true)
  }

  const clickHotelList = () => {
    setParticularOrganizerDetail(false)
    setIsShowEventList(true)
    setIsShow(false)
  }

  const editOnClick = (item) => {
    particularEventData(item.organizer_id, 'isEdit')
  }

  const onUploadImage = async (file, fieldName) => {
    if (file) {
      const reader = new FileReader()
      reader.onload = async () => {
        const imageFile = await compressImage(file)
        const coverFormData = new FormData()
        coverFormData.append('file', imageFile)
        coverFormData.append('type', 'organizer_contact')

        try {
          const res = await APICall(
            'post',
            coverFormData,
            '',
            'imageUploadLocal',
            true
          )

          if (res.status === 200 && res.data?.code === 200) {
            const decryptedCoverData = dataDecrypt(res.data.data)
            setInputFields((prev) => {
              return {
                ...prev,
                [fieldName]: decryptedCoverData
              }
            })
          } else {
            toast.error(res?.data?.message || 'Error uploading image')
          }
        } catch (err) {
          toast.error(err?.response?.data?.message || 'Upload failed')
        }
      }

      reader.readAsDataURL(file)
    }
  }

  const onClickContactEdit = (item = {}) => {
    setPersonDetails(true)
    if (Object.keys(item).length > 0) {
      setIsEdit(true)
      const updatedInputFields = {
        organizer_id: isOrganizerId,
        contact_name: item.contact_name,
        contact_designation: item.contact_designation,
        contact_phone: item.contact_phone,
        contact_profile_pic: item.contact_profile_pic,
        contact_id: item.contact_id
      }
      setInputFields(updatedInputFields)
      setInitialInputFields(updatedInputFields)
    }
  }

  const cancelContact = () => {
    setPersonDetails(false)
    clearContactValue()
  }

  const totalEvent = isOrganizerData?.length

  // api call

  const allOrganizerData = (currentPage) => {
    setIsOrganizerLoading(true)
    const payload = {
      app_id: app_id.id
    }
    APICall(
      'post',
      payload,
      `${EndPoints.getOrganizer}?page=${currentPage}`,
      'local'
    )
      .then((res) => {
        if (res.status === 200 && res.data?.code === 200) {
          setIsOrganizerData(res?.decryptedData.data)
          setIsOrganizerLoading(false)
          setTotalPages(res?.decryptedData.last_page)
          setCurrentPage(res?.decryptedData.current_page)
        } else {
          toast.error(res?.data?.message)
          setIsOrganizerLoading(false)
        }
      })
      .catch((error) => {
        setIsOrganizerLoading(false)
        toast.error(error?.data?.message)
      })
  }

  const deleteEvent = () => {
    const data = {
      organizer_id: delEventId
    }
    setLoading(true)
    APICall('post', data, `${EndPoints.deleteOrganizer}`, 'local')
      .then((res) => {
        if (res.status === 200 && res.data?.code === 200) {
          setShowConfirmEventModel(false)
          setLoading(false)
          setTimeout(() => {
            allOrganizerData(null, currentPage)
            toast.success(res?.data?.message)
          }, 3000)
        } else {
          toast.error(res?.data?.message)
          setLoading(false)
        }
      })
      .catch((error) => {
        toast.error(error?.data?.message)
        setLoading(false)
      })
  }

  const particularEventData = (id, type = null) => {
    setIsParticularLoading(true)
    setIsOrganizerId(id)
    const payload = {
      organizer_id: id
    }
    APICall('post', payload, `${EndPoints.particularOrganizer}`, 'local')
      .then((res) => {
        if (res.status === 200 && res.data?.code === 200) {
          setIsParticularEventData(res?.decryptedData)
          setParticularOrganizerDetail(true)
          setIsParticularLoading(false)
          if (type === 'isEdit') {
            onClickEdit(res?.decryptedData[0])
          }
        } else {
          setIsParticularLoading(false)
          toast.error(res?.data?.message)
        }
      })
      .catch((error) => {
        setIsParticularLoading(false)
        toast.error(error?.data?.message)
      })
  }

  const eventContactEditApi = async () => {
    const data = {
      organizer_id: isOrganizerId,
      contact_name: inputFields.contact_name,
      contact_designation: inputFields.contact_designation,
      contact_phone: inputFields.contact_phone,
      contact_profile_pic: inputFields.contact_profile_pic,
      contact_id: inputFields.contact_id
    }
    if (isEdit) {
      APICall('post', data, `${EndPoints.organizerContactEdit}`, 'local')
        .then((res) => {
          if (res.status === 200 && res.data?.code === 200) {
            setPersonDetails(false)
            setIsEdit(false)
            clearContactValue()
            setTimeout(() => {
              particularEventData(inputFields.organizer_id)
              toast.success(res?.data?.message)
            }, 2500)
          } else {
            setIsEdit(false)
            toast.error(res?.data?.message)
          }
        })
        .catch((error) => {
          setIsEdit(false)
          toast.error(error?.data?.message)
        })
    } else {
      APICall('post', data, `${EndPoints.organizerContactAdd}`, 'local')
        .then((res) => {
          if (res.status === 200 && res.data?.code === 200) {
            setPersonDetails(false)
            setTimeout(() => {
              particularEventData(isOrganizerId)
              toast.success(res?.data?.message)
            }, 2500)
            clearContactValue()
          } else {
            toast.error(res?.data?.message)
          }
        })
        .catch((error) => {
          toast.error(error?.data?.message)
        })
    }
  }

  const eventContactDeleteApi = async () => {
    const data = {
      contact_id: delContactId
    }
    setLoading(true)
    APICall('post', data, `${EndPoints.organizerContactDelete}`, 'local')
      .then((res) => {
        if (res.status === 200 && res.data?.code === 200) {
          setShowConfirmModel(false)
          setLoading(false)
          setTimeout(() => {
            particularEventData(isOrganizerId)
            toast.success(res?.data?.message)
          }, 2500)
        } else {
          setLoading(false)
          toast.error(res?.data?.message)
        }
      })
      .catch((error) => {
        setLoading(false)
        toast.error(error?.data?.message)
      })
  }

  const handlePageChange = (pageNumber) => {
    allOrganizerData(pageNumber)
    setCurrentPage(pageNumber)
  }

  const isStep4Valid = () => {
    return (
      isStep4Modified &&
      inputFields.contact_profile_pic.length > 0 &&
      inputFields.contact_name &&
      inputFields.contact_designation &&
      inputFields.contact_phone.length > 9
    )
  }

  useEffect(() => {
    setIsStep4Modified(
      inputFields.contact_profile_pic !==
        initialInputFields.contact_profile_pic ||
        inputFields.contact_name !== initialInputFields.contact_name ||
        inputFields.contact_designation !==
          initialInputFields.contact_designation ||
        inputFields.contact_phone !== initialInputFields.contact_phone
    )
  }, [inputFields])

  useEffect(() => {
    allOrganizerData(currentPage)
  }, [])

  useEffect(() => {
    if (isEvent) {
      lockScroll()
    } else {
      unlockScroll()
    }

    return () => {
      unlockScroll()
    }
  }, [isEvent])

  useEffect(() => {
    if (isEvent || ShowConfirmEventModel) {
      lockScroll()
    } else {
      unlockScroll()
    }
    return () => {
      unlockScroll()
    }
  }, [isEvent, ShowConfirmEventModel])

  return (
    <div className="">
      <div className="discussions-wrapper-header common-contnt-wrapper">
        {showCount && (
          <div className="tag_link mb-0">
            <h2>{English.G229}</h2>
            <div className="add-video-rows">
              <CommonButton
                label="Add New Restaurant"
                imgSrc={Images.AddIco}
                onClick={clickNewOrganizerAdd}
                isPrimary
                className="add_new_content"
              />
            </div>
          </div>
        )}
      </div>

      <div
        className={
          showCount !== 3 &&
          'content-manager-awards-wrapper common-card-body add-profile-video'
        }
      >
        {!showCount && isShow && particularOrganizerDetail ? (
          <div className="add-video-rows main_btn">
            <CommonButton
              label="All Organizer List"
              imgSrc={Images.AddIco}
              onClick={clickHotelList}
              isPrimary
              className="add_new_content"
            />
          </div>
        ) : !showCount && !isShow ? (
          <div className="add-video-rows main_btn">
            <CommonButton
              label="Add New Organizer"
              imgSrc={Images.AddIco}
              onClick={clickNewOrganizerAdd}
              isPrimary
              className="add_new_content"
            />
          </div>
        ) : null}
        {isShowEventList && (
          <div className="">
            <div className="hotel-offer--grid grid-3 mt-30">
              {isOrganizerData?.length === 0 && !isOrganizerLoading ? (
                <div className="awards-item">
                  <div className="add-profile-video-cover add-product-video-cover">
                    <button type="button" onClick={clickNewOrganizerAdd}>
                      <img src={Images.Plus} alt="add library" />
                    </button>
                  </div>
                  <div className="product-footer">
                    <p className="add-value" />
                    <span className="add-value" />
                  </div>
                </div>
              ) : isOrganizerLoading ? (
                <div className="awards-item">
                  <div className="add-profile-video-cover add-product-video-cover" />
                  <div className="product-footer">
                    <p className="add-value">
                      <Skeleton className="add-value" width={202} height={35} />
                    </p>
                    <span className="add-value">
                      <Skeleton className="add-value" width={67} height={35} />
                    </span>
                  </div>
                </div>
              ) : (
                isOrganizerData.map(
                  (item, index) => index < (showCount || totalEvent) && (
                  <div className="hotel-offer-grid--item" key={item.name}>
                    {console.log(item)}
                    <div
                      className="hotel-offer--cover position-relative height-184"
                          // onClick={() => {
                          //   if (!ShowConfirmEventModel) {
                          //     setIsShow(true)
                          //     setIsShowEventList(false)
                          //     particularEventData(item.organizer_id)
                          //   }
                          // }}
                      onClick={
                            clickable
                              ? () => {
                                  if (!ShowConfirmEventModel) {
                                    setIsShow(true)
                                    setIsShowEventList(false)
                                    particularEventData(item.organizer_id)
                                  }
                                }
                              : undefined
                          }
                      style={{
                            background: `url(${s3ImageOrganizer + item.images[0]})`,
                            backgroundRepeat: 'no-repeat',
                            backgroundSize: 'cover',
                            backgroundPosition: 'center'
                          }}
                    >
                      <div className="rating">
                        <img alt="" src={Images.RatingStar} />
                        <span>4.9</span>
                      </div>
                      <div className="image-buttons z-1">
                        <label className="edit_event_pencil">
                          <button
                            type="button"
                            onClick={(e) => {
                                  e.stopPropagation()
                                  editOnClick(item)
                                }}
                          />
                        </label>
                        <button
                          type="button"
                          className="p-0"
                          onClick={(e) => {
                                e.stopPropagation()
                                setUpdatingIndex(index)
                                ShowConfirm(item.organizer_id)
                              }}
                        />
                      </div>
                      <ConfirmationModal
                        isVisible={
                          ShowConfirmEventModel && updatingIndex === index
                        }
                        onConfirm={deleteEvent}
                        onCancel={handleDeleteCancel}
                        isLoading={loading}
                      />
                      {loading && updatingIndex === index && <Loader />}
                    </div>
                    <div className="hotel-offer-grid--detail">
                      <div className="d-flex align-items-center justify-content-between mb-1">
                        <h6 className="local_item_name">{item.name}</h6>
                        {item.min_price && (
                        <p className="price_night">
                          {item.min_price}
                          <span>
                            {' '}
                            {English.G273}
                          </span>
                        </p>
                            )}
                      </div>
                      <div className="d-flex align-items-center justify-content-between">
                        <span className="location d-flex align-items-center col-gap-10">
                          <img
                            className="location_pin"
                            alt=""
                            src={Images.Location}
                          />
                          <span>{item.address}</span>
                        </span>
                      </div>
                    </div>
                  </div>
                    )
                )
              )}
            </div>
            {!showCount && totalPages > 1 && (
              <Pagination
                currentPage={currentPage}
                totalPages={totalPages}
                onPageChange={handlePageChange}
              />
            )}
          </div>
        )}

        {isShow && particularOrganizerDetail && (
          <div className="offers--hotel p-2 mb-3">
            {isParticularLoading ? (
              <div className="particular_skeleton">
                <div className="w-50">
                  <div className="details_section_profile_header">
                    <div className="name_logo">
                      <Skeleton circle height={50} width={50} />
                      <div>
                        <Skeleton count={1} height={30} width={200} />
                        <Skeleton count={1} height={20} width={300} />
                      </div>
                    </div>
                  </div>
                  <div className="details_section_button_row d-flex gap-2">
                    <Skeleton count={1} height={40} width={80} />
                    <Skeleton count={1} height={40} width={80} />
                  </div>

                  <div className="details_section p-3">
                    <Skeleton count={4} height={20} width="100%" />
                    <Skeleton count={1} height={20} width={150} />
                  </div>

                  <div className="contact-details">
                    <h4>
                      <Skeleton width={100} />
                    </h4>
                    <div className="mt-3 mb-3">
                      <Skeleton count={3} height={50} width="100%" />
                    </div>
                  </div>
                </div>
                <div className="w-50">
                  <div className="d-flex gap-3 w-100 mt-5">
                    <div className="image-placeholder w-50">
                      <Skeleton height={150} width="100%" />
                    </div>
                    <div className="image-placeholder w-50">
                      <Skeleton height={150} width="100%" />
                    </div>
                  </div>
                  <div className="d-flex gap-3 w-100">
                    <div className="image-placeholder w-50">
                      <Skeleton height={150} width="100%" />
                    </div>
                    <div className="image-placeholder w-50">
                      <Skeleton height={150} width="100%" />
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              isParticularEventData.map((data, index) => (
                <div key={index}>
                  <div className="details_section_profile_header">
                    <div className="name_logo">
                      {data.logo ? (
                        <img src={s3ImageOrganizer + data.logo} alt="profile" />
                      ) : null}
                      <div>
                        <div className="d-flex gap-3 align-items-center">
                          <h1 className="mb-0 pe-2">{data.name}</h1>
                        </div>
                        <p>{data.address}</p>
                      </div>
                    </div>
                    <div className="details_section_button_row d-flex gap-2">
                      <button
                        type="button"
                        className="local_type_cancel"
                        onClick={() => onClickEdit(data)}
                      >
                        <span>{English.G276}</span>
                      </button>
                    </div>
                  </div>
                  <div className="details_section pt-0">
                    <div>
                      <div
                        className={`description_link ${expandedIndex === index ? 'expanded' : ''}`}
                      >
                        <p>
                          {expandedIndex === index
                            ? data?.description
                            : `${data?.description?.slice(0, 180)}...`}
                          {data?.description?.length > 180 && (
                            <span
                              className="see_more_local"
                              onClick={(e) => {
                                e.stopPropagation()
                                setExpandedIndex(
                                  expandedIndex === index ? null : index
                                )
                              }}
                            >
                              {expandedIndex === index
                                ? English.G193
                                : English.G194}
                            </span>
                          )}
                        </p>
                        <div className="profile-redirect">
                          <a
                            className="gredient-txt"
                            target="_blank"
                            href={getStoreLink(data.website)}
                            rel="noreferrer"
                          >
                            <img alt="" src={Images.Redirect} />
                            {data.website ? data.website : English.G278}
                          </a>
                        </div>
                      </div>
                      <div
                        className="contact-details"
                        style={{ width: '560px' }}
                      >
                        <h4>{English.G279}</h4>
                        <div className="mt-3 mb-3">
                          <div className="d-flex row">
                            {data?.contact_details?.map((item, index) => (
                              <div
                                className="col position-relative"
                                key={index}
                              >
                                <div className="podcast-profile-img">
                                  <img
                                    src={
                                      s3ImageOrganizer +
                                      item.contact_profile_pic
                                    }
                                    alt=""
                                  />
                                  <div className="podcast-profile-detail">
                                    <span>
                                      {item?.contact_name?.slice(0, 17)}
                                      {item?.contact_name?.length > 17 && '...'}
                                    </span>
                                    <p className="contact_designation">
                                      {item?.contact_designation?.slice(0, 17)}
                                      {item?.contact_designation?.length > 17 &&
                                        '...'}
                                    </p>
                                  </div>
                                </div>
                                <div className="local_contact_buttons z-1">
                                  <label>
                                    <button
                                      type="button"
                                      onClick={(e) => {
                                        e.stopPropagation()
                                        onClickContactEdit(item)
                                      }}
                                      className="d-none"
                                    />
                                  </label>
                                  <button
                                    type="button"
                                    className="p-0"
                                    onClick={() => {
                                      setContactIndex(index)
                                      ShowContactConfirm(item.contact_id)
                                    }}
                                  />
                                </div>
                                <ConfirmationModal
                                  isVisible={
                                    ShowConfirmModel && contactIndex === index
                                  }
                                  onConfirm={eventContactDeleteApi}
                                  onCancel={handleDeleteCancel}
                                  isLoading={loading}
                                />
                                {loading && contactIndex === index && (
                                  <Loader />
                                )}
                              </div>
                            )) || []}
                            {(!data?.contact_details ||
                              data.contact_details.length <= 1) && (
                              <div className="col position-relative">
                                <div
                                  className="podcast-profile-img"
                                  onClick={() => onClickContactEdit({})}
                                >
                                  <button
                                    type="button"
                                    className="add_contact_person"
                                    onClick={() => onClickContactEdit({})}
                                  >
                                    <img
                                      className="add_image_local"
                                      src={Images.Plus}
                                      alt="add library"
                                    />
                                  </button>
                                  <div className="podcast-profile-detail new_contact_add">
                                    <span className="new_contact_add">
                                      {English.G280}
                                    </span>
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                          <div />
                        </div>
                        <div>
                          <div className="hotel-details row">
                            <div className="col">
                              <h5>{English.G305}</h5>
                              <div className="podcast-profile-detail">
                                <p className="designation">
                                  {data.intrested_industries}
                                </p>
                              </div>
                            </div>
                            <div className="col">
                              <h5>{English.G282}</h5>
                              <div className="podcast-profile-detail">
                                <p className="designation">
                                  {English.G283}
                                  {' '}
                                  {data.min_price}
                                  {' '}
                                  -
                                  {' '}
                                  {English.G283}
                                  {' '}
                                  {data.max_price}
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="image_hotel_container">
                      <div className="image_hotel profile_image_hotel">
                        {data.images && data.images.length > 0 ? (
                          data.images.map((item, index) => (
                            <img
                              key={index}
                              src={s3ImageOrganizer + item}
                              alt="hotel img"
                            />
                          ))
                        ) : (
                          <p className="no_images_text">{English.G285}</p>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              ))
            )}
          </div>
        )}

        {isShow && (
          <div className="position-relative local-tabs section-local">
            <Tabs>
              <div className="list_overview local_profile_tab">
                <TabList>
                  <Tab>{English.G286}</Tab>
                  <Tab>{English.G306}</Tab>
                </TabList>
              </div>

              <TabPanel>
                {' '}
                <div className="local_box">
                  <div className="local_manager_section impress-section">
                    <div className="local-manager-item">
                      <div className="icon_local">
                        <img src={Images.impression} alt="visitor" />
                      </div>
                      <div className="local-manager-item-info">
                        <p>{English.G299}</p>
                        <span>
                          0
                          <img src={Images.UpPath} alt="" />
                          <span className="positive-trend">0%</span>
                        </span>
                      </div>
                    </div>
                    <div className="local-manager-item">
                      <div className="icon_local">
                        <img src={Images.Clicks} alt="impression" />
                      </div>
                      <div className="local-manager-item-info">
                        <p>{English.G300}</p>
                        <span>
                          0
                          <img src={Images.UpPath} alt="" />
                          <span className="positive-trend">0%</span>
                        </span>
                      </div>
                    </div>
                    <div className="local-manager-item">
                      <div className="icon_local">
                        <img src={Images.Requests} alt="Clicks" />
                      </div>
                      <div className="local-manager-item-info">
                        <p>{English.G301}</p>
                        <span>
                          0
                          <img src={Images.UpPath} alt="" />
                          <span className="positive-trend">0%</span>
                        </span>
                      </div>
                    </div>
                    <div className="local-manager-item">
                      <div className="icon_local">
                        <img src={Images.StatusImg} alt="request" />
                      </div>
                      <div className="local-manager-item-info">
                        <p>{English.G302}</p>
                        <span>
                          0
                          <img src={Images.DownPath} alt="" />
                          <span className="negetive-trend">0%</span>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="content-manager-Statistics p-20">
                  <div className="statistic-chart-container position-relative">
                    <h4>{English.G286}</h4>
                    <div className="drop-and-date top-0">
                      <ReactDatePicker
                        selected={startDate}
                        onChange={(date) => setStartDate(date)}
                        showYearPicker
                        dateFormat="yyyy"
                      />
                    </div>
                    <OrderChart />
                  </div>
                </div>
              </TabPanel>
              <TabPanel>
                <Event isOrganizerId={isOrganizerId} />
              </TabPanel>
            </Tabs>
          </div>
        )}

        {personDetails && (
          <div className="add-item-model">
            <div className="add-item-content local_type_modal">
              <div className="type_close mb-2  local_sticky_header">
                <div className="text-Main text-base italic font-black uppercase flex items-center !gap-3">
                  {English.G289}
                </div>
                <button
                  className="cancel_icon"
                  type="button"
                  onClick={cancelContact}
                >
                  <img src={Images.RemoveLocal} alt="" />
                </button>
              </div>
              <div className="item-details-input pt-0 local_contact_scroll_bars">
                <div className="logo_btn">
                  <div className="image_upload_section">
                    <div className="add_photos mb-4 mt-2">
                      <div className="add_logo_text text-center align-self-center">
                        <CommonImageUpload
                          name="local_upload_logo"
                          handleImageChange={(file) => onUploadImage(file, 'contact_profile_pic')}
                          index={0}
                          forImage={English.G290}
                          className="profile-upload"
                          selectedImg={
                            inputFields.contact_profile_pic
                              ? `${s3ImageOrganizer}${inputFields.contact_profile_pic}`
                              : ''
                          }
                          defaultImg={Images.local_add_img}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="person_detail_section">
                    <div className="input-grid">
                      <div>
                        <label>{English.G130}</label>
                        <CommonInput
                          placeholder={English.G130}
                          onChange={onChange}
                          name="contact_name"
                          value={capitalizeFirstLetter(inputFields.contact_name)}
                        />
                      </div>
                      <div>
                        <label>{English.G291}</label>
                        <CommonInput
                          placeholder={English.G291}
                          onChange={onChange}
                          name="contact_designation"
                          value={capitalizeFirstLetter(
                            inputFields.contact_designation
                          )}
                        />
                      </div>
                    </div>
                    <div>
                      <label>{English.G186}</label>
                      <CommonInput
                        placeholder={English.G292}
                        name="contact_phone"
                        pattern="/^-?\d+\.?\d*$/"
                        type="text"
                        onChange={(e) => {
                          const sanitizedValue = e.target.value.replace(
                            /[^0-9+]/g,
                            ''
                          )
                          const maxLength = sanitizedValue.startsWith('+')
                            ? 13
                            : 12
                          if (sanitizedValue.length <= maxLength) {
                            onChange(e)
                          }
                        }}
                        onKeyPress={ForMobileNumber}
                        value={inputFields.contact_phone}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="local_button_row">
                <CommonButton
                  label={English.G116}
                  onClick={cancelContact}
                  isPrimary={false}
                  context={English.G232}
                />
                <CommonButton
                  label={English.G69}
                  type="button"
                  isPrimary
                  onClick={eventContactEditApi}
                  disabled={!isStep4Valid()}
                  context={English.G232}
                />
              </div>
            </div>
          </div>
        )}
      </div>

      {isEvent && (
        <EventPopup
          onCancel={handleCancel}
          defaultData={editData}
          apiSuccess={() => {
            allOrganizerData(currentPage)
            clearValues()
            setIsEvent(false)
          }}
          platformData={isPlatformData}
          isFromOrganizerComponent={isFromOrganizerComponent}
          setIsShowEventList={setIsShowEventList}
          setIsShow={setIsShow}
          editEvent={isEventEdit}
        />
      )}

      {isOrganizerData?.length >= 2 && showCount && (
        <div
          className="view_all text-end mt-4"
          onClick={() => navigate(`/local-builder/${id}`, {
              state: { defaultIndex: 4 }
            })}
        >
          {English.G144}
        </div>
      )}
    </div>
  )
}

export default EventComponent
