/* eslint-disable no-nested-ternary */
/* eslint-disable max-len */
/* eslint-disable no-restricted-globals */
import React, { useEffect, useMemo, useState } from 'react'
import '../HotelComponent/Discount.css'
import './Event.css'
import {
  Button,
  Image,
  Input,
  Menu,
  Table,
  TimePicker,
  Dropdown as AntDropdown
} from 'antd'
import dayjs from 'dayjs'
import { toast } from 'react-toastify'
import _ from 'lodash'
import { LoadScriptNext } from '@react-google-maps/api'
import { useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
import Dropdown from '../../Dropdown/Dropdown'
import CommonImageUpload from '../../ImageCroper/StoreImageCroper'
import CommonInput from '../../InputComponet/CommonInput'
import CommonButton from '../../ButtonComponent/ButtonCommon'
import {
  capitalizeFirstLetter,
  lockScroll,
  unlockScroll
} from '../../../helpers/Utility'
import {
  locationSuggestion,
  s3ImageOrganizer
} from '../../../services/Service'
import { compressImage, dataDecrypt } from '../../../services/http-services'
import Loader from '../../Loader/Loader'
import Pagination from '../../Pagination/Pagination'
import ConfirmationModal from '../../ConfirmationModal/ConfirmationModal'
import MapComponent from '../../MapComponent/MapComponent'
import AutocompleteInput from '../../MapComponent/AutoComplete'
import CommonDatePicker from '../../CommonDatePicker/CommonDatePicker'
import English from '../../../helpers/English'
import Images from '../../../helpers/Images'
import { APICall, EndPoints } from '../../../services'

const libraries = ['places']
const Event = ({ isOrganizerId, latitude, longitude }) => {
  const app_id = useSelector((state) => state.user?.app_id)
  const { id } = useParams()
  const [isAddDiscount, setIsAddDiscount] = useState(false)
  const [isRoomFeature, setIsRoomFeature] = useState(false)
  const [isAddRoom, setIsAddRoom] = useState(false)
  const [searchText, setSearchText] = useState('')
  const [inputFields, setInputFields] = useState({
    name: '',
    industry_type_id: '',
    performer_type_id: '',
    start_time: '',
    end_time: '',
    min_price: '',
    max_price: '',
    tickets_qty: '',
    date: '',
    description: '',
    images: [],
    website: '',
    latitude: latitude || '',
    longitude: longitude || '',
    event_performer: [],
    contact_name1: '',
    contact_profile_pic: [],
    organizer_id: isOrganizerId,
    event_id: '',
    performer_id: '',
    address: '',
    price: ''
  })
  const [isIndustryType, setIsIndustryType] = useState([])
  const [isPerformerType, setIsPerformerType] = useState([])
  const [isImageLoading, setIsImageLoading] = useState({
    index: null,
    loading: false
  })
  const [draggedIndex, setDraggedIndex] = useState(null)
  const [personDetails, setPersonDetails] = useState(false)
  const [eventListData, setEventListData] = useState([])
  const [currentPage, setCurrentPage] = useState(1)
  const [totalPages, setTotalPages] = useState(1)
  const [delEventId, setDelEventId] = useState()
  const [ShowConfirmModel, setShowConfirmModel] = useState(false)
  const [ShowConfirmPerformerModel, setShowConfirmPerformerModel] =
    useState(false)
  const [loading, setLoading] = useState(false)
  const [performerLoading, setPerformerLoading] = useState(false)
  const [isEdit, setIsEdit] = useState(false)
  const [initialValues, setInitialValues] = useState({})
  const [particularEvent, setParticularEvent] = useState([])
  const [isFormComplete, setIsFormComplete] = useState(false)
  const [isEditPerformer, setIsEditPerformer] = useState(false)
  const [error, setError] = useState({
    start_time: '',
    end_time: ''
  })

  const [initialInputFields, setInitialInputFields] = useState({
    contact_profile_pic: '',
    performer_id: '',
    contact_name1: '',
    performer_type_id: ''
  })
  const [delContactId, setDelContactId] = useState()
  const [contactIndex, setContactIndex] = useState(null)
  const [eventID, setEventID] = useState('')
  const [EditAddPerformer, setEditNewAddPerformer] = useState(false)
  const [AddPerformer, setAddPerformer] = useState(false)
  const [currentEditIndex, setCurrentEditIndex] = useState(null)
  const clearValue = () => {
    setInputFields({
      ...inputFields,
      name: '',
      industry_type_id: '',
      performer_type_id: '',
      performer_id: '',
      start_time: '',
      end_time: '',
      min_price: '',
      max_price: '',
      tickets_qty: '',
      date: '',
      description: '',
      images: [],
      website: '',
      latitude: '',
      longitude: '',
      event_performer: [],
      contact_name1: '',
      contact_profile_pic: [],
      event_id: '',
      address: '',
      price: ''
    })
    setIsEdit(false)
    setEditNewAddPerformer(false)
  }

  const [isEventLoading, setEventLoading] = useState(false)
  const [showAll, setShowAll] = useState(false)

  const toggleShowAll = () => {
    setShowAll(!showAll)
  }

  const [markerPosition, setMarkerPosition] = useState({
    lat: latitude || 0,
    lng: longitude || 0
  })

  const clearPerformer = () => {
    setInputFields({
      ...inputFields,
      performer_type_id: '',
      performer_id: '',
      contact_name1: '',
      contact_profile_pic: []
    })
  }

  const addDiscount = () => {
    setIsAddDiscount(true)
  }

  const cancelContact = () => {
    setPersonDetails(false)
    setIsRoomFeature(true)
    clearPerformer()
  }

  const performerAdd = () => {
    const updatedPerformer = {
      performer_type_id: inputFields.performer_type_id,
      event_performers_name: inputFields.contact_name1,
      performer_profile_pic: inputFields.contact_profile_pic,
      performer_id: inputFields.performer_id
    }

    if (isEditPerformer && currentEditIndex !== null) {
      const updatedPerformers = [...inputFields.event_performer]
      updatedPerformers[currentEditIndex] = updatedPerformer

      setInputFields({
        ...inputFields,
        event_performer: updatedPerformers,
        performer_type_id: '',
        contact_name1: '',
        contact_profile_pic: []
      })
    } else {
      setInputFields({
        ...inputFields,
        event_performer: [...inputFields.event_performer, updatedPerformer],
        performer_type_id: '',
        contact_name1: '',
        contact_profile_pic: []
      })
    }
    setIsEditPerformer(false)
    setCurrentEditIndex(null)
    setPersonDetails(false)
    setIsRoomFeature(true)
  }

  const handleCancels = () => {
    clearValue()
    setIsAddDiscount(false)
    setIsRoomFeature(false)
    setIsAddRoom(false)
    setIsEdit(false)
  }

  const nextClick = () => {
    if (isEdit) {
      EventAddEditApiCall()
      // setIsAddDiscount(false)
      setIsRoomFeature(false)
    } else {
      setIsAddDiscount(false)
      setEditNewAddPerformer(false)
      setAddPerformer(false)
      setIsRoomFeature(true)
    }
  }

  const showEvent = (record) => {
    setIsAddRoom(true)
    particularEventData(record.event_id)
  }

  const EditEventData = async (record) => {
    // const data = await particularEventData(record.event_id)
    if (Object.keys(record).length > 0) {
      setIsEdit(true)
      // const mappedInputFields = data.map((d) => ({
      const mappedInputFields = {
        name: record.name,
        industry_type_id: record.industry_type_id,
        start_time: record.start_time,
        event_id: record.event_id,
        end_time: record.end_time,
        price: record.price,
        tickets_qty: record.tickets_qty,
        date: record.date,
        description: record.description,
        images: record.images,
        website: record.website,
        latitude: record.latitude,
        longitude: record.longitude,
        address: record.address,
        organizer_id: isOrganizerId
      }
      // }))

      // const mappedInitialValues = data.map((d) => ({
      const mappedInitialValues = {
        name: record.name,
        industry_type_id: record.industry_type_id,
        start_time: record.start_time,
        event_id: record.event_id,
        end_time: record.end_time,
        price: record.price,
        tickets_qty: record.tickets_qty,
        date: record.date,
        description: record.description,
        images: record.images,
        website: record.website,
        latitude: record.latitude,
        longitude: record.longitude,
        address: record.address,
        organizer_id: isOrganizerId
      }
      // }))

      setInputFields(mappedInputFields)
      setInitialValues(mappedInitialValues)
    }

    setIsAddDiscount(true)
  }

  const handleSearch = (e) => {
    setSearchText(e.target.value.toLowerCase())
  }

  const ShowConfirm = (record) => {
    setDelEventId(record.event_id)
    setShowConfirmModel(true)
  }
  const handleDeleteCancel = () => {
    setShowConfirmModel(false)
    setShowConfirmPerformerModel(false)
  }
  const handleCancelSpeaker = () => {
    setIsRoomFeature(false)
    setIsAddDiscount(true)
  }

  const formatViews = (views) => {
    if (views >= 1_000_000_000) {
      return `${(views / 1_000_000_000).toFixed(1)}B`
    }
    if (views >= 1_000_000) {
      return `${(views / 1_000_000).toFixed(1)}M`
    }
    if (views >= 1_000) {
      return `${(views / 1_000).toFixed(2)}k`
    }
    return views.toString()
  }

  const columns = [
    {
      title: 'EVENT NAME & IMAGE',
      dataIndex: 'name',
      key: 'name',
      render: (text, record) => (
        <div className="image_name">
          <Image
            width={100}
            src={s3ImageOrganizer + record.images[0]}
            alt="img"
            style={{ marginRight: 10 }}
            preview={false}
          />
          <span>
            {record.name.length > 15
              ? `${record.name.substring(0, 15)}...`
              : record.name}
          </span>
        </div>
      ),
      sorter: (a, b) => a.name.localeCompare(b.name),
      onFilter: (value, record) => record.name.toLowerCase().includes(value.toLowerCase()),
      className: 'custom-column'
    },
    {
      title: 'DATE',
      dataIndex: 'date',
      key: 'date',
      sorter: (a, b) => a.date.localeCompare(b.date)
    },
    {
      title: 'TIMING',
      dataIndex: 'timing',
      key: 'timing',
      sorter: (a, b) => a.start_time.localeCompare(b.start_time),
      render: (text, record) => {
        const formatTime = (time) => time.slice(0, 5)
        return `${formatTime(record.start_time)} - ${formatTime(record.end_time)}`
      }
    },
    {
      title: 'INDUSTRY',
      dataIndex: 'industry_type',
      key: 'industry_type',
      sorter: (a, b) => a.industry_type.localeCompare(b.industry_type)
    },
    {
      title: 'TICKET QTY',
      dataIndex: 'tickets_qty',
      key: 'tickets_qty',
      sorter: (a, b) => a.tickets_qty - b.tickets_qty,
      render: (text) => formatViews(text)
    },
    {
      title: 'SPEAKERS',
      dataIndex: 'performer_details',
      key: 'Speakers',
      render: (performer_details) => {
        const visiblePerformers = performer_details.slice(0, 3)
        const hiddenPerformersCount =
          performer_details.length - visiblePerformers.length

        return (
          <div className="follower-shows">
            <div className="performer-img-group d-flex">
              {visiblePerformers.map((performer, index) => (
                <img
                  key={performer.performer_id}
                  alt={`speaker ${index + 1}`}
                  src={s3ImageOrganizer + performer.performer_profile_pic}
                  className="w-10 h-10 rounded-full border-2 border-white shadow-sm"
                />
              ))}
              {hiddenPerformersCount > 0 && (
                <div className="w-[30px] h-[30px] rounded-full flex items-center ml-[-6px] justify-center bg-[#01104D] from-gray-800 to-blue-900 text-white text-sm font-semibold">
                  +
                  {hiddenPerformersCount}
                </div>
              )}
            </div>
          </div>
        )
      },
      sorter: (a, b) => a.performer_details.length - b.performer_details.length
    },

    {
      title: 'LIKE',
      key: 'like',
      render: (text, record) => (
        <Button
          icon={
            <img
              src={record.is_like ? Images.Likes : Images.UnLikes}
              alt={record.is_like ? 'Like' : 'Unlike'}
            />
          }
          onClick={(e) => {
            e.stopPropagation()
            eventLikeData(record.event_id)
          }}
        />
      )
    },
    {
      title: 'ACTION',
      key: 'action',
      render: (text, record) => (
        <div className="image-buttons-discounts">
          <Button
            icon={<img src={Images.Edit} alt="Edit" />}
            onClick={(e) => {
              e.stopPropagation()
              EditEventData(record)
            }}
          />
          <Button
            icon={<img src={Images.DeleteDiscount} alt="Delete" />}
            onClick={(e) => {
              e.stopPropagation()
              ShowConfirm(record)
            }}
          />
        </div>
      )
    }
  ]

  const filteredData = eventListData?.filter((item) => item.name.toLowerCase().includes(searchText))

  const handleChange = (e) => {
    const { name, value } = e.target

    setInputFields((prev) => ({
      ...prev,
      [name]: value
    }))

    if (name === 'min_price' || name === 'max_price') {
      const minPrice =
        name === 'min_price'
          ? parseFloat(value)
          : parseFloat(inputFields.min_price)
      const maxPrice =
        name === 'max_price'
          ? parseFloat(value)
          : parseFloat(inputFields.max_price)

      if (!isNaN(minPrice) && !isNaN(maxPrice) && maxPrice > minPrice) {
        setError((prev) => ({
          ...prev,
          max_price: ''
        }))
      }
    }
  }

  const onUploadImage = async (file, fieldName, index) => {
    if (file) {
      const reader = new FileReader()
      reader.onload = async () => {
        const imageFile = await compressImage(file)
        const coverFormData = new FormData()
        coverFormData.append('file', imageFile)
        if (fieldName === 'contact_profile_pic') {
          coverFormData.append('type', 'organizer_event_perfomer')
        } else {
          coverFormData.append('type', 'organizer_event')
        }

        try {
          const res = await APICall(
            'post',
            coverFormData,
            '',
            'imageUploadLocal',
            true
          )
          if (res.status === 200 && res.data?.code === 200) {
            const decryptedImageData = dataDecrypt(res.data.data)
            setInputFields((prev) => {
              const prevData = prev[fieldName]
              if (
                (index !== undefined || index !== null) &&
                Array.isArray(prevData)
              ) {
                prevData[index] = decryptedImageData
              }
              return {
                ...prev,
                [fieldName]: prevData
              }
            })
          } else {
            toast.error(res?.data?.message || 'Error uploading image')
          }
        } catch (err) {
          toast.error(err?.response?.data?.message || 'Upload failed')
        }
      }
      reader.readAsDataURL(file)
    }
  }

  const [isDropdownOpen, setIsDropdownOpen] = useState(false)
  const [timer1, setTimer1] = useState(false)
  const [timer2, setTimer2] = useState(false)

  const handleTimeChange = (time, field) => {
    const timeString = time ? time.format('HH:mm') : ''

    setInputFields((prevState) => {
      const updatedFields = { ...prevState, [field]: timeString }

      if (prevState[field]) {
        const prevHour = dayjs(prevState[field], 'HH:mm').hour()
        const prevMinute = dayjs(prevState[field], 'HH:mm').minute()
        const newHour = time.hour()
        const newMinute = time.minute()

        if (newHour !== prevHour) {
          // console.log(`Hour selected: ${newHour}`)
        } else if (newMinute !== prevMinute) {
          // console.log(`Minute selected: ${newMinute}`)
          if (field === 'start_time') {
            setTimer1(false)
          } else {
            setTimer2(false)
          }
          setTimeout(() => {
            if (inputFields.start_time && inputFields.end_time) {
              setIsDropdownOpen(false)
            }
          }, 500)
        }
      }

      if (
        field === 'end_time' &&
        updatedFields.start_time &&
        timeString < updatedFields.start_time
      ) {
        setError((prev) => ({
          ...prev,
          end_time: English.A195
        }))
      } else if (
        field === 'start_time' &&
        updatedFields.end_time &&
        timeString > updatedFields.end_time
      ) {
        setError((prev) => ({
          ...prev,
          end_time: English.A195
        }))
      } else {
        setError((prev) => ({
          ...prev,
          end_time: ''
        }))
      }

      return updatedFields
    })
  }

  const handleDropdownVisibleChange = (visible) => {
    if (!visible) {
      setTimer1(false)
      setTimer2(false)
    }
    setTimeout(() => {
      setIsDropdownOpen(visible)
    }, 100)
  }

  const menu = (
    <Menu
      style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gap: '8px' }}
    >
      <Menu.Item key="start" style={{ gridColumn: '1' }}>
        <TimePicker
          format="HH:mm"
          placeholder="Start Time"
          showNow={false}
          showOk={false}
          suffixIcon={null}
          open={timer1}
          onOpenChange={setTimer1}
          onCalendarChange={(time) => handleTimeChange(time, 'start_time')}
          value={
            inputFields.start_time
              ? dayjs(inputFields.start_time, 'HH:mm')
              : null
          }
          allowClear={false}
        />
      </Menu.Item>
      <Menu.Item key="end" style={{ gridColumn: '2' }}>
        <TimePicker
          format="HH:mm"
          placeholder="End Time"
          showNow={false}
          showOk={false}
          suffixIcon={null}
          open={timer2}
          onOpenChange={setTimer2}
          onCalendarChange={(time) => handleTimeChange(time, 'end_time')}
          value={
            inputFields.end_time ? dayjs(inputFields.end_time, 'HH:mm') : null
          }
          allowClear={false}
        />
      </Menu.Item>
    </Menu>
  )

  const typeofIndustryListData = () => {
    APICall('post', {}, `${EndPoints.industryType}`, 'local')
      .then((res) => {
        if (res.status === 200 && res.data?.code === 200) {
          setIsIndustryType(res?.decryptedData)
        } else {
          toast.error(res?.data?.message)
        }
      })
      .catch((error) => {
        toast.error(error?.data?.message)
      })
  }

  const typeofPerformerListData = () => {
    APICall('post', {}, `${EndPoints.performerType}`, 'local')
      .then((res) => {
        if (res.status === 200 && res.data?.code === 200) {
          setIsPerformerType(res?.decryptedData)
        } else {
          toast.error(res?.data?.message)
        }
      })
      .catch((error) => {
        toast.error(error?.data?.message)
      })
  }

  const getIndustryName = (id) => {
    const categoryObj = isIndustryType.find((cat) => cat.id === id)
    return categoryObj ? categoryObj.industry_type : 'Select industry'
  }

  const getPerformerName = (id) => {
    const categoryObj = isPerformerType.find((cat) => cat.id === id)
    return categoryObj ? categoryObj.performer_type : 'Select performer'
  }

  const removeImage = (index) => {
    const updatedImages = [...inputFields.images]
    updatedImages.splice(index, 1)
    setInputFields((prev) => ({
      ...prev,
      images: updatedImages
    }))
  }

  const handleDragStart = (index) => {
    setDraggedIndex(index)
  }

  const handleDrop = (index) => {
    if (index !== draggedIndex) {
      setInputFields((prev) => {
        const copy = [...prev.images]
        const b = copy[draggedIndex]
        copy[draggedIndex] = copy[index]
        copy[index] = b
        return {
          ...prev,
          images: copy
        }
      })

      setDraggedIndex(null)
    }
  }

  const handleLoad = (index) => {
    setIsImageLoading({
      index,
      loading: true
    })
    setTimeout(() => {
      setIsImageLoading({
        index: null,
        loading: false
      })
    }, 500)
  }

  const renderImg = useMemo(() => {
    return (
      <>
        {inputFields.images.map((image, index) => {
          return (
            <div
              className={`image position-relative !shadow-none ${
                index === 0 ? 'first-image' : ''
              }`}
              key={index}
              alt={`image-${index}`}
              draggable
              onDragStart={() => {
                handleDragStart(index)
              }}
              onDragOver={(event) => event.preventDefault()}
              onDragEnter={(event) => event.preventDefault()}
              onDrop={(event) => {
                event.preventDefault()
                handleDrop(index)
              }}
            >
              {isImageLoading.index === index && isImageLoading.loading && (
                <Loader />
              )}
              <img
                className={`image position-relative !shadow-none ${
                  index === 0 ? 'first-image' : ''
                }`}
                src={
                  typeof image === 'string'
                    ? s3ImageOrganizer + image
                    : URL.createObjectURL(image)
                }
                alt={`image-${index}`}
                style={{
                  width: '100%',
                  height: '100%',
                  objectFit: 'cover'
                }}
                onLoad={() => handleLoad(index)}
              />
              <button
                className="image_remove_icon"
                type="button"
                onClick={() => removeImage(index)}
              >
                <img src={Images.RemoveLocal} alt="" />
              </button>
              <button type="button" className="plus-img">
                <CommonImageUpload
                  handleImageChange={(file) => {
                    onUploadImage(file, 'images', index)
                  }}
                  selectedImg=""
                  defaultImg={Images.plusimg}
                  className="plsimg"
                />
              </button>
            </div>
          )
        })}
      </>
    )
  }, [inputFields.images, handleDragStart])

  const allEventData = (currentPage) => {
    const isEventPath = window.location.pathname === '/event'
    const payload = {
      organizer_id: isOrganizerId,
      ...(isEventPath && { app_id: 2 })
    }
    APICall(
      'post',
      payload,
      `${EndPoints.getEvent}?page=${currentPage}`,
      'local'
    )
      .then((res) => {
        if (res.status === 200 && res.data?.code === 200) {
          setEventListData(res?.decryptedData.data)
          setTotalPages(res?.decryptedData.last_page)
          setCurrentPage(res?.decryptedData.current_page)
        } else {
          toast.error(res?.data?.message)
        }
      })
      .catch((error) => {
        toast.error(error?.data?.message)
      })
  }

  const EventAddEditApiCall = () => {
    setEventLoading(true)
    if (isEdit) {
      const EditPayload = {
        name: inputFields.name,
        address: inputFields.address,
        event_id: inputFields.event_id,
        industry_type_id: inputFields.industry_type_id,
        start_time: inputFields.start_time.slice(0, 5),
        end_time: inputFields.end_time.slice(0, 5),
        price: inputFields.price,
        tickets_qty: inputFields.tickets_qty,
        date: inputFields.date,
        description: inputFields.description,
        images: inputFields.images,
        organizer_id: isOrganizerId,
        latitude: inputFields.latitude,
        longitude: inputFields.longitude,
        website: inputFields.website
      }
      APICall('post', EditPayload, `${EndPoints.editEvent}`, 'local')
        .then((res) => {
          if (res.status === 200 && res.data?.code === 200) {
            setIsAddDiscount(false)
            setIsRoomFeature(false)
            setPersonDetails(false)
            setIsEdit(false)
            setTimeout(() => {
              allEventData(currentPage)
              toast.success(res?.data?.message)
            }, 4000)
            clearValue()
            setEventLoading(false)
          } else {
            setIsEdit(false)
            setEventLoading(false)
            toast.error(res?.data?.message)
          }
        })
        .catch((error) => {
          setIsEdit(false)
          setEventLoading(false)
          toast.error(error?.data?.message)
        })
    } else {
      const payload = {
        name: inputFields.name,
        event_id: inputFields.event_id,
        address: inputFields.address,
        industry_type_id: inputFields.industry_type_id,
        start_time: inputFields.start_time.slice(0, 5),
        end_time: inputFields.end_time.slice(0, 5),
        price: inputFields.price,
        tickets_qty: inputFields.tickets_qty,
        date: inputFields.date,
        description: inputFields.description,
        images: inputFields.images,
        event_performers_name: inputFields.event_performer.map(
          (d) => d.event_performers_name
        ),
        performer_type_id: inputFields.event_performer.map(
          (d) => d.performer_type_id
        ),
        performer_profile_pic: inputFields.event_performer.map((d) => d.performer_profile_pic.toString()),
        organizer_id: isOrganizerId,
        latitude: inputFields.latitude,
        longitude: inputFields.longitude,
        website: inputFields.website
      }
      APICall('post', payload, `${EndPoints.addEvent}`, 'local')
        .then((res) => {
          if (res.status === 200 && res.data?.code === 200) {
            setIsAddDiscount(false)
            setIsRoomFeature(false)
            setPersonDetails(false)
            clearValue()
            setTimeout(() => {
              allEventData(currentPage)
              toast.success(res?.data?.message)
            }, 4000)
            setEventLoading(false)
          } else {
            setEventLoading(false)
            toast.error(res?.data?.message)
          }
        })
        .catch((error) => {
          setEventLoading(false)
          toast.error(error?.data?.message)
        })
    }
  }

  const deleteEvent = () => {
    setLoading(true)
    const data = {
      event_id: delEventId
    }
    APICall('post', data, `${EndPoints.deleteEvent}`, 'local')
      .then((res) => {
        if (res.status === 200 && res.data?.code === 200) {
          setShowConfirmModel(false)
          setLoading(false)
          setTimeout(() => {
            allEventData(currentPage)
            toast.success(res?.data?.message)
          }, 2500)
        } else {
          setLoading(false)
          toast.error(res?.data?.message)
        }
      })
      .catch((error) => {
        setLoading(false)
        toast.error(error?.data?.message)
      })
  }

  const eventLikeData = (event_id) => {
    const isLocalBuilderPath =
      window.location.pathname === `/local-builder/${id}`
    const platFormData = {
      ...(isLocalBuilderPath && {
        app_id: app_id?.id
      }),
      event_id
    }
    APICall('post', platFormData, `${EndPoints.eventLike}`, 'local')
      .then((res) => {
        if (res.status === 200 && res.data?.code === 200) {
          setEventListData(
            eventListData.map((item) => (item.event_id === event_id
                ? { ...item, is_like: !item.is_like }
                : item))
          )
        } else {
          toast.error(res?.data?.message)
        }
      })
      .catch((error) => {
        toast.error(error?.data?.message)
      })
  }

  const particularEventData = async (event_id) => {
    setEventID(event_id)
    const payload = {
      organizer_id: isOrganizerId,
      event_id
    }
    APICall('post', payload, `${EndPoints.particularEvent}`, 'local')
      .then((res) => {
        if (res.status === 200 && res.data?.code === 200) {
          setParticularEvent(res?.decryptedData)
        } else {
          toast.error(res?.data?.message)
        }
      })
      .catch((error) => {
        toast.error(error?.data?.message)
      })
  }

  const handlePageChange = (pageNumber) => {
    allEventData(pageNumber)
    setCurrentPage(pageNumber)
  }

  const onClickPerformerEdit = (data, index) => {
    setIsEditPerformer(true)
    // setPersonDetails(true)
    // setIsRoomFeature(false)
    setInputFields({
      ...inputFields,
      performer_type_id: data?.performer_type_id,
      contact_name1: data?.event_performers_name,
      contact_profile_pic: [data?.performer_profile_pic],
      performer_id: data?.performer_id
    })
    setCurrentEditIndex(index)
  }

  const ShowContactConfirm = (performer_id) => {
    setDelContactId(performer_id)
    setShowConfirmPerformerModel(true)
  }

  const onClickContactEdit = (event_id, item = {}) => {
    setPersonDetails(true)
    setIsRoomFeature(false)
    setIsAddRoom(false)
    setEventID(event_id)
    setEditNewAddPerformer(true)
    if (item && Object.keys(item).length > 0) {
      const updatedInputFields = {
        event_id: eventID,
        performer_type_id: item.performer_type_id || '',
        contact_name1: item.performer_name || '',
        contact_profile_pic: item.performer_profile_pic
          ? [item.performer_profile_pic]
          : [],
        performer_id: item.performer_id || ''
      }
      setInputFields((prevFields) => ({
        ...prevFields,
        ...updatedInputFields
      }))
      setInitialInputFields(updatedInputFields)
    }
  }

  const onClickContactAdd = (event_id) => {
    setPersonDetails(true)
    setIsRoomFeature(false)
    setIsAddRoom(false)
    setEventID(event_id)
    setAddPerformer(true)
  }

  const AddEditPerformer = () => {
    const payload = {
      event_id: eventID,
      performer_type_id: inputFields.performer_type_id,
      performer_name: inputFields.contact_name1,
      performer_profile_pic: inputFields.contact_profile_pic.toString(),
      performer_id: inputFields.performer_id
    }

    if (!EditAddPerformer && !AddPerformer) {
      performerAdd()
    } else if (EditAddPerformer) {
      APICall('post', payload, `${EndPoints.eventPerformerEdit}`, 'local')
        .then((res) => {
          if (res.status === 200 && res.data?.code === 200) {
            setPersonDetails(false)
            setIsAddRoom(true)
            setEditNewAddPerformer(false)
            setTimeout(() => {
              particularEventData(inputFields.event_id)
              toast.success(res?.data?.message)
            }, 3000)
            clearPerformer()
          } else {
            setEditNewAddPerformer(false)
            toast.error(res?.data?.message)
          }
        })
        .catch((error) => {
          setEditNewAddPerformer(false)
          toast.error(error?.data?.message)
        })
    } else if (AddPerformer) {
      APICall('post', payload, `${EndPoints.eventPerformerAdd}`, 'local')
        .then((res) => {
          if (res.status === 200 && res.data?.code === 200) {
            setIsAddRoom(true)
            setPersonDetails(false)
            setEditNewAddPerformer(false)
            setTimeout(() => {
              toast.success(res?.data?.message)
              particularEventData(eventID)
            }, 3000)
            clearPerformer()
          } else {
            setEditNewAddPerformer(false)
            toast.error(res?.data?.message)
          }
        })
        .catch((error) => {
          setEditNewAddPerformer(false)
          toast.error(error?.data?.message)
        })
    }
  }

  const performerDeleteApi = async () => {
    const data = {
      performer_id: delContactId
    }
    setPerformerLoading(true)
    APICall('post', data, `${EndPoints.eventPerformerDelete}`, 'local')
      .then((res) => {
        if (res.status === 200 && res.data?.code === 200) {
          setShowConfirmPerformerModel(false)
          setPerformerLoading(false)
          particularEventData(eventID)
          toast.success(res?.data?.message)
        } else {
          setPerformerLoading(false)
          toast.error(res?.data?.message)
        }
      })
      .catch((error) => {
        setPerformerLoading(false)
        toast.error(error?.data?.message)
      })
  }

  const clickOnParticularEdit = (data) => {
    EditEventData(data)
    setIsAddRoom(false)
  }

  const [isStep4Modified, setIsStep4Modified] = useState(false)

  const isStep4Valid = () => {
    return (
      isStep4Modified &&
      inputFields.contact_profile_pic &&
      inputFields.contact_name1 &&
      inputFields.performer_type_id
    )
  }

  useEffect(() => {
    setIsStep4Modified(
      inputFields.contact_profile_pic !==
        initialInputFields.contact_profile_pic ||
        inputFields.performer_type_id !==
          initialInputFields.performer_type_id ||
        inputFields.contact_name1 !== initialInputFields.contact_name1
    )
  }, [inputFields])

  const handleRemovePerformer = (data, index) => {
    if (isEdit) {
      deletePerformer(data.performer_id, index)
    } else {
      const updatedPerformers = inputFields.event_performer.filter(
        (performer, i) => i !== index
      )
      setInputFields({
        ...inputFields,
        event_performer: updatedPerformers
      })
    }
  }

  const deletePerformer = (id, index) => {
    const data = {
      performer_id: id
    }
    APICall('post', data, `${EndPoints.eventPerformerDelete}`, 'local')
      .then((res) => {
        if (res.status === 200 && res.data?.code === 200) {
          const updatedPerformers = inputFields.event_performer?.filter(
            (performer, i) => i !== index
          )
          setInputFields({
            ...inputFields,
            event_performer: updatedPerformers
          })
          toast.success(res?.data?.message)
        } else {
          toast.error(res?.data?.message)
        }
      })
      .catch((error) => {
        toast.error(error?.data?.message)
      })
  }

  const validateForm = () => {
    const {
      name,
      industry_type_id,
      start_time,
      end_time,
      price,
      tickets_qty,
      date,
      description,
      images,
      website
    } = inputFields

    if (
      name &&
      industry_type_id &&
      start_time &&
      end_time &&
      tickets_qty &&
      date &&
      description &&
      price &&
      images.length > 0 &&
      website
    ) {
      setIsFormComplete(true)
    } else {
      setIsFormComplete(false)
    }
  }

  const handleMapPositionChange = (lat, lng) => {
    setMarkerPosition({ lat, lng })
    setInputFields((prev) => ({
      ...prev,
      latitude: lat,
      longitude: lng
    }))
  }

  useEffect(() => {
    if (!isEdit) {
      validateForm()
    }
  }, [inputFields])

  useEffect(() => {
    if (isEdit) {
      setIsFormComplete(!_.isEqual(inputFields, initialValues))
    }
  }, [inputFields, initialValues, isEdit])

  useEffect(() => {
    typeofIndustryListData()
    typeofPerformerListData()
    allEventData(currentPage)
  }, [])

  useEffect(() => {
    if (
      isAddDiscount ||
      isRoomFeature ||
      ShowConfirmModel ||
      isAddRoom ||
      ShowConfirmPerformerModel
    ) {
      lockScroll()
    } else {
      unlockScroll()
    }
    return () => {
      unlockScroll()
    }
  }, [
    isAddDiscount,
    isRoomFeature,
    ShowConfirmModel,
    isAddRoom,
    ShowConfirmPerformerModel
  ])

  useEffect(() => {
    if (inputFields.latitude && inputFields.longitude) {
      setMarkerPosition({
        lat: Number(inputFields.latitude),
        lng: Number(inputFields.longitude)
      })
    }
  }, [inputFields.latitude, inputFields.longitude])

  return (
    <div className="">
      <div className="statistic-chart-container position-relative">
        {eventListData?.length === 0 ? (
          <div className="add_room_text">
            <img
              className="d-block mb-2"
              src={Images.Event_Logo}
              alt="bedroom"
            />
            <h3 className="uppercase pe-2">{English.G377}</h3>
            <p>{English.G378}</p>
            <div className="add-video-rows mt-3" onClick={addDiscount}>
              <div className="add_new_content uppercase italic">
                <img src={Images.plusrounded} alt="" />
                <span className="mr-2">{English.G379}</span>
              </div>
            </div>
          </div>
        ) : (
          <div>
            <div className="local-room-main">
              <h3 className="d-flex align-items-center justify-content-between uppercase italic">
                {English.G306}
                <button
                  type="button"
                  className="primary-btn text-sm font-black italic uppercase"
                  onClick={addDiscount}
                >
                  {/* <img src={Images.PlusIco} alt="add" /> */}
                  {English.G379}
                </button>
              </h3>
              <div className="discount_list">
                <Input
                  placeholder="Search by Event Name"
                  onChange={handleSearch}
                  style={{ width: 200, marginBottom: '1rem' }}
                />
                <div className="discount_list">
                  <Table
                    columns={columns}
                    dataSource={filteredData}
                    pagination={false}
                    onRow={(record) => {
                      return {
                        onClick: () => {
                          showEvent(record)
                        }
                      }
                    }}
                  />
                </div>
                <ConfirmationModal
                  isVisible={ShowConfirmModel}
                  onConfirm={deleteEvent}
                  onCancel={handleDeleteCancel}
                  isLoading={loading}
                />
                {loading && <Loader />}
                {totalPages > 1 && (
                  <Pagination
                    currentPage={currentPage}
                    totalPages={totalPages}
                    onPageChange={handlePageChange}
                  />
                )}
              </div>
            </div>
          </div>
        )}

        {isAddDiscount && (
          <div className="add-item-model">
            {isEventLoading && <Loader />}
            <div className="add-item-content local_type_modal !max-w-[1200px] !m-5">
              <div className="type_close mb-2 local_sticky_header">
                <div className="text-Main text-base italic font-black uppercase flex items-center !gap-3">
                  {isEdit ? 'EDIT EVENT' : 'ADD EVENT'}
                </div>
                <button
                  className="cancel_icon"
                  type="button"
                  onClick={handleCancels}
                >
                  <img src={Images.RemoveLocal} alt="" />
                </button>
              </div>
              <div className="item-details-input pt-0 local_contact_scroll_bars">
                <div className="grid grid-cols-2 !gap-5">
                  <div>
                    <div className="input-grid">
                      <div>
                        <label>{English.G380}</label>
                        <CommonInput
                          placeholder={English.G381}
                          name="name"
                          allowTextOnly
                          onChange={handleChange}
                          value={capitalizeFirstLetter(inputFields.name)}
                        />
                      </div>
                      <div className="performer_dropdown">
                        <label>{English.G382}</label>
                        <Dropdown
                          title={getIndustryName(inputFields.industry_type_id)}
                          name="industry_type_id"
                          onChanged={(d) => {
                            setInputFields({
                              ...inputFields,
                              industry_type_id: d.id
                            })
                          }}
                          Data={isIndustryType.map((d) => ({
                            title: d.industry_type,
                            id: d.id
                          }))}
                          customClass={
                            inputFields.industry_type_id
                              ? 'selected_class_local'
                              : 'local-dropdown'
                          }
                        />
                      </div>
                    </div>
                    <div className="input-grid">
                      <div className="discount-date">
                        <label>{English.G383}</label>
                        <div className="calendar">
                          <CommonDatePicker
                            inputFields={inputFields}
                            setInputFields={setInputFields}
                            fieldName="date"
                            placeholder={English.G57}
                            minDate={new Date()}
                            maxDate={
                              new Date(new Date().getFullYear() + 10, 11, 31)
                            }
                            popperPlacement="bottom"
                          />
                        </div>
                      </div>

                      <div>
                        <label>Timing</label>
                        <AntDropdown
                          overlay={menu}
                          trigger={['click']}
                          visible={isDropdownOpen}
                          onVisibleChange={handleDropdownVisibleChange}
                          onClick={(e) => e.stopPropagation()}
                        >
                          <div
                            className={`custom-timepicker !bg-[#fafafa] ${
                              inputFields.start_time && inputFields.end_time
                                ? 'text-color-time'
                                : 'text-gray-500'
                            }`}
                          >
                            {inputFields.start_time && inputFields.end_time
                              ? `${inputFields.start_time} - ${inputFields.end_time}`
                              : 'Select from options'}
                          </div>
                        </AntDropdown>
                        {error.end_time && (
                          <span className="error-message">
                            {error.end_time}
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="">
                      <div>
                        <label>{English.G388}</label>
                        <CommonInput
                          placeholder={English.G389}
                          name="website"
                          onChange={handleChange}
                          value={inputFields.website}
                        />
                      </div>
                    </div>
                    <div className="input-grid">
                      <div>
                        <label>{English.G131}</label>
                        <CommonInput
                          placeholder={English.G368}
                          name="price"
                          type="number"
                          onChange={handleChange}
                          pattern="/^-?\d+\.?\d*$/"
                          maxLength={4}
                          onKeyPress={(e) => {
                            if (e.target.value.length === 4) {
                              e.preventDefault()
                            }
                          }}
                          value={inputFields.price}
                        />
                      </div>
                      <div>
                        <label>{English.G384}</label>
                        <CommonInput
                          placeholder={English.G385}
                          name="tickets_qty"
                          pattern="/^-?\d+\.?\d*$/"
                          maxLength={4}
                          onKeyPress={(e) => {
                            if (e.target.value.length === 4) {
                              e.preventDefault()
                            }
                          }}
                          type="number"
                          onChange={handleChange}
                          value={inputFields.tickets_qty}
                        />
                      </div>
                    </div>
                    <div className="">
                      <div className="local_event_description">
                        <label>{English.G112}</label>
                        <CommonInput
                          placeholder={English.G390}
                          name="description"
                          onChange={handleChange}
                          value={capitalizeFirstLetter(inputFields.description)}
                          maxLength={150}
                          isTextArea
                          className="mb-4"
                        />
                      </div>
                    </div>
                    <LoadScriptNext
                      googleMapsApiKey={locationSuggestion}
                      libraries={libraries}
                    >
                      <div className="form-group">
                        <label>{English.G187}</label>
                        <AutocompleteInput
                          setInputFields={setInputFields}
                          inputFields={inputFields}
                        />
                      </div>
                    </LoadScriptNext>
                    <div className="form-group h-[calc(80vh-245px)] sticky top-0 rounded-[15px] overflow-hidden">
                      <LoadScriptNext
                        googleMapsApiKey={locationSuggestion}
                        libraries={libraries}
                      >
                        <MapComponent
                          inputFields={inputFields}
                          setInputFields={setInputFields}
                          onMapPositionChange={handleMapPositionChange}
                          markerPosition={markerPosition}
                        />
                      </LoadScriptNext>
                    </div>
                  </div>
                  <div className="hotel_image_upload_section">
                    <div className="hotel_upload_div mt-0 bg-[#FAFAFA] h-[calc(80vh-220px)] overflow-y-auto sticky top-0 flex items-center justify-center">
                      {inputFields.images.length < 1 ? (
                        <CommonImageUpload
                          handleImageChange={(file) => onUploadImage(
                              file,
                              'images',
                              inputFields.images.length
                            )}
                          buttonText="Add Image"
                          selectedImg=""
                          defaultImg={Images.plusGiveaway}
                          className="room_add_img"
                          forImage="hotel"
                        />
                      ) : (
                        <div className="input-image-show m-0 local_images_show">
                          {renderImg}
                          {inputFields.images.length < 6 && (
                            <div className="input-img-label">
                              <CommonImageUpload
                                handleImageChange={(file) => onUploadImage(
                                    file,
                                    'images',
                                    inputFields.images.length
                                  )}
                                buttonText="Add Image"
                                selectedImg=""
                                defaultImg={Images.plusGiveaway}
                                className="room_add_img plsimg"
                              />
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="item-details-input pt-0">
                <div className="local_button_row local_sticky_btn">
                  <CommonButton
                    label={English.G116}
                    onClick={handleCancels}
                    isPrimary={false}
                    context={English.A197}
                  />
                  <CommonButton
                    label={English.G69}
                    type="button"
                    isPrimary
                    onClick={nextClick}
                    disabled={!isFormComplete}
                    context={English.A197}
                  />
                </div>
              </div>
            </div>
          </div>
        )}

        {isRoomFeature && (
          <div className="add-item-model">
            {isEventLoading && <Loader />}
            <div className="add-item-content add_room_modal !max-w-[1200px] !m-5">
              <div className="type_close mb-2 local_sticky_header">
                <div className="text-Main text-base italic font-black uppercase flex items-center !gap-3">
                  {isEdit ? 'EDIT EVENT' : 'ADD EVENT'}
                </div>
                <button
                  className="cancel_icon"
                  type="button"
                  onClick={handleCancels}
                >
                  <img src={Images.RemoveLocal} alt="" />
                </button>
              </div>
              <div
                className={`item-details-input pt-0 local_contact_scroll_bars ${
                  inputFields?.event_performer?.length > 2
                    ? 'local_scroll_bars'
                    : ''
                }`}
              >
                <div className="text-base font-bold text-Secondary !mb-5">
                  Speaker Details
                </div>
                <div className="grid grid-cols-2 !gap-5">
                  <div>
                    <div className="logo_btn mb-2">
                      <div className="image_upload_section">
                        <div className="add_photos mb-4 mt-2">
                          <div className="add_logo_text text-center align-self-center">
                            <CommonImageUpload
                              name="local_upload_logo"
                              handleImageChange={(file) => onUploadImage(file, 'contact_profile_pic', 0)}
                              index={0}
                              forImage={English.G290}
                              className="profile-upload"
                              selectedImg={
                                inputFields.contact_profile_pic.length > 0
                                  ? `${s3ImageOrganizer}${inputFields.contact_profile_pic}`
                                  : ''
                              }
                              defaultImg={Images.local_add_img}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="upload_btn_logo">
                        <p className="image_validation mb-0">
                          {English.G317}
                          <br />
                          {English.G318}
                        </p>
                      </div>
                    </div>
                    <div>
                      <label>{English.G130}</label>
                      <CommonInput
                        placeholder={English.G130}
                        onChange={handleChange}
                        name="contact_name1"
                        allowTextOnly
                        value={capitalizeFirstLetter(inputFields.contact_name1)}
                      />
                    </div>
                    {/* <div>
                      <label>Designation</label>
                      <CommonInput placeholder="Type designation" />
                    </div> */}
                    <div className="mb-3 performer_dropdown">
                      <label>{English.G399}</label>
                      <Dropdown
                        title={getPerformerName(inputFields.performer_type_id)}
                        name="performer_type_id"
                        onChanged={(d) => {
                          setInputFields({
                            ...inputFields,
                            performer_type_id: d.id
                          })
                        }}
                        Data={isPerformerType.map((d) => ({
                          title: d.performer_type,
                          id: d.id
                        }))}
                        customClass={
                          inputFields.performer_type_id
                            ? 'selected_class_local'
                            : 'local-dropdown'
                        }
                      />
                    </div>
                    <CommonButton
                      label="Add Speaker"
                      type="button"
                      className="common-button !w-full"
                      isPrimary
                      onClick={() => {
                        AddEditPerformer()
                      }}
                      disabled={!isStep4Valid()}
                      context={English.G232}
                    />
                  </div>
                  <div className="grid grid-cols-3 !gap-5 items-start">
                    {/* <div className="border border-BorderLight rounded-[10px] p-2.5"> */}
                    {inputFields?.event_performer
                      ?.filter(
                        (data) => data &&
                          data.performer_profile_pic &&
                          data.event_performers_name
                      )
                      ?.map((data, index) => (
                        <div
                          className="event_speaker position-relative"
                          key={index}
                        >
                          <div className="speaker_img">
                            <img
                              src={
                                s3ImageOrganizer + data.performer_profile_pic
                              }
                              alt={data.performer_profile_pic}
                            />
                          </div>
                          <div className="event_speaker_name">
                            <h1>
                              {data.event_performers_name.slice(0, 17)}
                              {data.event_performers_name.length > 17 && '...'}
                            </h1>
                            <p>{getPerformerName(data.performer_type_id)}</p>
                          </div>
                          <div className="event_speaker_remove">
                            <CommonButton
                              label={English.G392}
                              type="button"
                              isPrimary={false}
                              context={English.G232}
                              className="event_speaker_btn"
                              onClick={() => handleRemovePerformer(data, index)}
                            />
                          </div>
                          <div className="local_contact_buttons_event z-1">
                            <label>
                              <button
                                type="button"
                                onClick={(e) => {
                                  e.stopPropagation()
                                  onClickPerformerEdit(data, index)
                                }}
                                className="d-none"
                              />
                            </label>
                          </div>
                        </div>
                      ))}
                    {/* </div> */}
                  </div>
                </div>
              </div>
              <div className="local_button_row local_sticky_btn mt-0">
                <CommonButton
                  label={English.G359}
                  onClick={handleCancelSpeaker}
                  isPrimary={false}
                  context={English.A197}
                  className="common-button"
                />
                <CommonButton
                  label={English.G117}
                  type="button"
                  isPrimary
                  disabled={inputFields.event_performer?.length === 0}
                  onClick={EventAddEditApiCall}
                  context={English.A197}
                />
              </div>
            </div>
          </div>
        )}

        {isAddRoom && (
          <div className="add-item-model">
            {particularEvent.map((data, index) => (
              <div
                className="add-item-content local_type_modal !max-w-[1200px] !m-5"
                key={index}
              >
                <div className="type_close mb-2 local_sticky_header">
                  <div className="text-Main text-base italic font-black uppercase flex items-center !gap-3">
                    {data.name}
                  </div>
                  <button
                    className="cancel_icon"
                    type="button"
                    onClick={handleCancels}
                  >
                    <img src={Images.RemoveLocal} alt="" />
                  </button>
                </div>
                <div className="item-details-input pt-0 local_contact_scroll_bars">
                  <div className="grid grid-cols-2 !gap-5">
                    <div>
                      <div className="edit_description ">
                        <p className="mt-0 text-base text-[#303030] !mb-5">
                          {data.description}
                        </p>
                      </div>
                      <div className="mb-3">
                        <div>
                          <label className="performer_text">Speakers</label>
                          <div className="mb-3">
                            <div className="d-flex flex-wrap row performer_data">
                              {data.performer_details
                                ?.slice(
                                  0,
                                  showAll ? data.performer_details.length : 3
                                )
                                .map((item, index) => {
                                  const totalItems =
                                    data.performer_details.length
                                  const columnClass =
                                    totalItems === 2
                                      ? 'col-6 btn-delete-edit'
                                      : 'col-6 btn-delete-edit'

                                  return (
                                    <div
                                      key={index}
                                      className={`${columnClass} position-relative`}
                                      style={{ marginBottom: '10px' }}
                                    >
                                      <div
                                        className="podcast-profile-img"
                                        style={{ marginRight: '10px' }}
                                      >
                                        <img
                                          src={
                                            s3ImageOrganizer +
                                            item.performer_profile_pic
                                          }
                                          alt=""
                                        />
                                        <div className="podcast-profile-detail">
                                          <span>{item.performer_name}</span>
                                          <p className="designation">
                                            {item.performer_type_name}
                                          </p>
                                        </div>
                                      </div>

                                      <div className="local_contact_buttons z-1">
                                        <label>
                                          <button
                                            type="button"
                                            onClick={(e) => {
                                              e.stopPropagation()
                                              onClickContactEdit(
                                                data.event_id,
                                                item
                                              )
                                            }}
                                            className="d-none"
                                          />
                                        </label>
                                        <button
                                          type="button"
                                          className="p-0"
                                          onClick={() => {
                                            setContactIndex(index)
                                            ShowContactConfirm(
                                              item.performer_id
                                            )
                                          }}
                                        />
                                      </div>
                                      <ConfirmationModal
                                        isVisible={
                                          ShowConfirmPerformerModel &&
                                          contactIndex === index
                                        }
                                        onConfirm={performerDeleteApi}
                                        onCancel={handleDeleteCancel}
                                        isLoading={performerLoading}
                                      />
                                      {performerLoading &&
                                        contactIndex === index && <Loader />}
                                    </div>
                                  )
                                })}
                              <div className="col position-relative">
                                <div
                                  className="podcast-profile-img width_add_performer"
                                  onClick={() => onClickContactAdd(data.event_id)}
                                >
                                  <button
                                    type="button"
                                    className="add_contact_person p-0 !rounded-full text-center flex items-center justify-center"
                                    onClick={() => onClickContactAdd(data.event_id)}
                                  >
                                    <img
                                      className="add_image_local"
                                      src={Images.Plus}
                                      alt="add library"
                                    />
                                  </button>
                                  <div className="podcast-profile-detail new_contact_add">
                                    <span className="new_contact_add">
                                      {English.G394}
                                    </span>
                                  </div>
                                </div>
                              </div>
                              {data.performer_details?.length > 3 && (
                                <div className="col-12 text-center">
                                  <CommonButton
                                    label={showAll ? 'Hide' : 'See More'}
                                    type="button"
                                    onClick={toggleShowAll}
                                    className="see-more-btn !w-full"
                                    context={English.G232}
                                  />
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="detail-grid">
                        <div className="deal-label flex items-center justify-between border-[#E4E4E4] border rounded-[5px] px-2.5 !py-1 bg-[#E7EFFF] !mb-5">
                          <p>{English.G395}</p>
                          <label>{data.date}</label>
                        </div>
                        <div className="deal-label flex items-center justify-between border-[#E4E4E4] border rounded-[5px] px-2.5 !py-1 !mb-5">
                          <p>{English.G131}</p>
                          <label>
                            {English.G283}
                            {data.price}
                          </label>
                        </div>
                      </div>
                      <div className="detail-grid">
                        <div className="deal-label flex items-center justify-between border-[#E4E4E4] border rounded-[5px] px-2.5 !py-1 !mb-5">
                          <p>{English.G396}</p>
                          <label>
                            {data.start_time.slice(0, 5)}
                            -
                            {data.end_time.slice(0, 5)}
                            {' '}
                            {/* {English.G397} */}
                          </label>
                        </div>
                        <div className="deal-label flex items-center justify-between border-[#E4E4E4] border rounded-[5px] px-2.5 !py-1 !mb-5">
                          <p>{English.G382}</p>
                          <label>{data.industry_type}</label>
                        </div>
                      </div>
                    </div>
                    <div
                      className={`gap-[15px] grid ${data?.images?.length === 1 ? 'grid-cols-1 h-[340px]' : data?.images?.length === 2 ? 'grid-cols-2' : 'grid-cols-3'}`}
                    >
                      {data.images.map((d, i) => {
                        return (
                          <div
                            className={`${data?.images?.length === 1 ? 'h-[360px] !object-none' : 'aspect-square'} `}
                            key={i}
                          >
                            <img
                              className="rounded-[15px] w-full h-full object_position object-center"
                              src={s3ImageOrganizer + d}
                              alt="roomImage"
                              key={i}
                            />
                          </div>
                        )
                      })}
                    </div>
                  </div>
                </div>
                <div className="item-details-input pt-0">
                  <div className="local_button_row local_sticky_btn">
                    <CommonButton
                      label={English.A196}
                      onClick={() => {
                        ShowConfirm(data)
                        setIsAddRoom(false)
                      }}
                      isPrimary={false}
                      context={English.A197}
                    />
                    <CommonButton
                      label={English.G81}
                      type="button"
                      isPrimary
                      onClick={() => clickOnParticularEdit(data)}
                      context={English.A197}
                    />
                  </div>
                </div>
              </div>
            ))}
          </div>
        )}

        {personDetails && (
          <div className="add-item-model">
            <div className="add-item-content local_type_modal">
              <div className="type_close mb-2  local_sticky_header">
                <div className="text-Main text-base italic font-black uppercase flex items-center !gap-3">
                  {English.G398}
                </div>
                <button
                  className="cancel_icon"
                  type="button"
                  onClick={cancelContact}
                >
                  <img src={Images.RemoveLocal} alt="" />
                </button>
              </div>
              <div className="item-details-input local_contact_scroll_bars pt-0">
                <p />
                <div className="logo_btn mb-5">
                  <div className="image_upload_section">
                    <div className="add_photos mb-4 mt-2">
                      <div className="add_logo_text text-center align-self-center">
                        <CommonImageUpload
                          name="local_upload_logo"
                          handleImageChange={(file) => onUploadImage(file, 'contact_profile_pic', 0)}
                          index={0}
                          forImage={English.G290}
                          className="profile-upload"
                          selectedImg={
                            inputFields.contact_profile_pic.length > 0
                              ? `${s3ImageOrganizer}${inputFields.contact_profile_pic}`
                              : ''
                          }
                          defaultImg={Images.local_add_img}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="person_detail_section w-full">
                    <div className="mb-3 performer_dropdown">
                      <label>{English.G399}</label>
                      <Dropdown
                        title={getPerformerName(inputFields.performer_type_id)}
                        name="performer_type_id"
                        onChanged={(d) => {
                          setInputFields({
                            ...inputFields,
                            performer_type_id: d.id
                          })
                        }}
                        Data={isPerformerType.map((d) => ({
                          title: d.performer_type,
                          id: d.id
                        }))}
                        customClass={
                          inputFields.performer_type_id
                            ? 'selected_class_local'
                            : 'local-dropdown'
                        }
                      />
                    </div>
                    <div>
                      <label>{English.G130}</label>
                      <CommonInput
                        placeholder={English.G130}
                        onChange={handleChange}
                        name="contact_name1"
                        allowTextOnly
                        value={capitalizeFirstLetter(inputFields.contact_name1)}
                      />
                    </div>
                  </div>
                </div>
                <p />
                <p />
              </div>
              <div className="local_button_row">
                <CommonButton
                  label={English.G116}
                  onClick={cancelContact}
                  isPrimary={false}
                  context={English.A197}
                />
                <CommonButton
                  label={English.G69}
                  type="button"
                  isPrimary
                  onClick={() => {
                    AddEditPerformer()
                  }}
                  disabled={!isStep4Valid()}
                  context={English.A197}
                />
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}
export default Event
