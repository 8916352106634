/* eslint-disable eqeqeq */
/* eslint-disable no-useless-escape */
import React, { useRef, useState, useEffect } from 'react'
import DatePicker from 'react-datepicker'
import { useNavigate } from 'react-router-dom'
import '../../App.css'
import { toast } from 'react-toastify'
import { useSelector } from 'react-redux'
import moment from 'moment/moment'
import { Avatar, Select } from 'antd'
import { English, Images } from '../../helpers'
import CommonInput from '../InputComponet/CommonInput'
import CommonButton from '../ButtonComponent/ButtonCommon'
import SideBarPageBusiness from './SideBarPageBusiness'
import CommonImageUpload from '../ImageCroper/StoreImageCroper'
import {
  compressImage,
  dataDecrypt,
  setUserSliceData
} from '../../services/http-services'
import { APICall, EndPoints } from '../../services'
import {
  allowAlphaNumericDashSingleSpaceKeydown,
  allowAlphaNumericDashSingleSpacePaste,
  preventInvalidKeydown,
  preventMultipleAtSignPaste,
  // preventNonAlphabeticKeydownNoSpaces,
  preventNonAlphabeticKeydownWithSingleSpace,
  // preventNonAlphabeticPasteNoSpaces,
  preventNonAlphabeticPasteWithSingleSpace
} from '../../helpers/Utility'
import countries from '../../Counties.json'
import NumberValidation from '../NumberValidation/NumberValidation'
import CountryStateCityDropDown from './CommonComponent/CountryStateCityDropDown'

const BusinessInfoPage = () => {
  const UserData = useSelector((state) => state.user?.userData)
  const navigate = useNavigate()
  const [inputFields, setInputFields] = useState({
    full_name: '',
    date_of_birth: '',
    telephone: '',
    company_country: '',
    company_city: '',
    company_street: '',
    company_zip_code: '',
    profile_image: ''
  })
  const [errors, setErrors] = useState({})
  const [submitting, setSubmitting] = useState(false)
  const [profileImg, setProfileImg] = useState(null)
  const [profileImgObj, setProfileImgObj] = useState(null)
  const datePickerRef = useRef(null)
  const [loading, setLoading] = useState(false)
  const [startDate, setStartDate] = useState(null)
  const [isButtonEnabled, setIsButtonEnabled] = useState(false)
  const [businessInfo, setBusinessInfo] = useState(false)
  const [selectedCountry, setSelectedCountry] = useState('')
  const [selectedCity, setSelectedCity] = useState('')

  const { Option } = Select

  const countryList = countries
    .filter((it) => it.idd.suffixes[0])
    .map((country) => ({
      name: country.name.common,
      code: country.cca2, // ISO 3166-1 alpha-2
      dialCode:
        country.idd.root +
        (country.idd.suffixes ? country.idd.suffixes[0] : ''),
      flag: `https://flagcdn.com/w320/${country.cca2.toLowerCase()}.png`
    }))

  const [selectCode, setSelectedCode] = useState(
    countryList?.find((country) => country?.name === 'Germany')?.dialCode
  )
  const [isDropdownOpen, setIsDropdownOpen] = useState(false)
  const selectRef = useRef(null)

  const handleSelectChange = (value) => {
    setSelectedCode(value)
    selectRef.current?.blur()
    setInputFields({
      ...inputFields,
      telephone: ''
    })
  }
  const handleChange = (e) => {
    setInputFields({ ...inputFields, [e.target.name]: e.target.value })
  }
  const allowedTypes = ['image/jpeg', 'image/png', 'image/jpg']
  const maxFileSizeMB = 8
  const maxFileSizeBytes = maxFileSizeMB * 1024 * 1024

  const handleImageUpload = (file) => {
    if (file) {
      if (allowedTypes.includes(file.type)) {
        if (file.size > maxFileSizeBytes) {
          toast.error(`File size exceeds ${maxFileSizeMB} MB`)
          return
        }
        const imgUrl = URL.createObjectURL(file)
        setProfileImg(imgUrl)
        setProfileImgObj(file)
      } else {
        toast.error('Please upload a valid image file (JPEG/JPG/PNG)')
      }
    }
  }

  function formatDate(date) {
    return moment(date).format('YYYY-MM-DD')
  }

  const validateValues = () => {
    const errors = {}
    if (!inputFields.full_name.match(/^[a-zA-ZÀ-Ÿ-. ]*$/)) {
      errors.full_name = English.G48
    }
    if (!formatDate(startDate).match(/^\d{4}-\d{2}-\d{2}$/)) {
      errors.date_of_birth = English.G49
    }
    if (!inputFields.company_zip_code.match(/^[0-9]*$/)) {
      errors.company_zip_code = English.G50
    }
    // if (!inputFields.telephone.match(/^\d{10,12}$/)) {
    //   errors.telephone = English.G24
    // }
    const regexMobile =
      NumberValidation[
        (
          countryList.find((item) => selectCode == item.dialCode) ||
          countryList[0]
        ).code
      ]
    // const regexMobile = /^[0-9]+$/
    if (!inputFields.telephone.match(regexMobile)) {
      errors.telephone = English.G24
    }
    return errors
  }

  const handleSubmit = (event) => {
    event.preventDefault()
    setErrors(validateValues(inputFields))
    setSubmitting(true)
    setBusinessInfo(true)
  }
  useEffect(() => {
    if (Object.keys(errors).length === 0 && submitting) {
      if (submitting) {
        finishSubmit()
      }
    }
  }, [errors])

  useEffect(() => {
    setInputFields({
      ...inputFields,
      company_city: selectedCity,
      company_country: selectedCountry
    })
  }, [selectedCity, selectedCountry])

  const userDataSubmit = () => {
    setLoading(true)
    inputFields.date_of_birth = formatDate(startDate)
    setTimeout(() => {
      navigate('/business-legal', { state: { inputFields } })
    }, 1000)
  }

  const finishSubmit = async () => {
    const compressedImage = await compressImage(profileImgObj)
    const formData = new FormData()
    formData.append('file', compressedImage)
    formData.append('type', 'business_profile')
    formData.append('sub_type', 'profile_image')
    APICall('post', formData, EndPoints.uploadImage, 'profile', true)
      .then((res) => {
        if (res.status === 200 && res.data?.code === 200) {
          inputFields.profile_image = dataDecrypt(res.data.data)
          setUserSliceData(businessInfo, 'businessInfo')
          userDataSubmit()
        } else {
          toast.error(res?.data?.message)
        }
      })
      .catch((e) => {
        toast.error(e?.data?.message)
      })
  }

  const handleButtonClick = () => {
    if (datePickerRef.current) {
      datePickerRef.current.setOpen(true)
    }
  }

  const handleDateChange = (date) => {
    const today = new Date()
    const minDate = new Date(
      today.getFullYear() - 15,
      today.getMonth(),
      today.getDate()
    )

    if (date <= minDate) {
      setStartDate(date)
    } else {
      setStartDate(null)
      if (datePickerRef.current) {
        datePickerRef.current.setOpen(true)
      }
      toast.error(English.G52)
    }
  }
  const today = new Date()
  const minYear = today.getFullYear() - 15
  const maxYear = today.getFullYear() - 100

  const years = []
  for (let year = minYear; year >= maxYear; year--) {
    years.push(year)
  }

  const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December'
  ]

  useEffect(() => {
    // const regexMobile =
    //   NumberValidation[
    //     (
    //       countryList.find((item) => selectCode == item.dialCode) ||
    //       countryList[0]
    //     ).code
    //   ]
    setIsButtonEnabled(
      inputFields.telephone.length > 3 &&
        // inputFields.telephone.match(regexMobile) &&
        inputFields.company_street.length > 0 &&
        inputFields.company_country.length > 0 &&
        inputFields.company_city.length > 0 &&
        inputFields.company_zip_code.length > 4 &&
        startDate &&
        inputFields.full_name.length > 0 &&
        profileImg
    )
  }, [inputFields, profileImg])

  return (
    <div className="auth-register business-register">
      <div className="bg-overlay row overlay-height">
        <div className="d-flex col-md-6 py-[20px]">
          <SideBarPageBusiness />
        </div>
        <div className="col-md-6 h-100 pt-[20px] pb-4">
          <div className="inline-padding bg-white rounded-2xl h-full d-flex flex-col mr-[20px] overflow-y-auto no-scrollbar">
            <h2 className="signup_text mb-0">{English.A59}</h2>
            <h5 className="bg-[#151515] text-center bg-transparent text-[24px] font-black italic">
              {English.A61}
            </h5>
            <form onSubmit={handleSubmit}>
              <div className="input-profile-img my-4">
                <div className="d-flex user-profile-crop">
                  <div>
                    <CommonImageUpload
                      handleImageChange={handleImageUpload}
                      index={0}
                      forImage="profile"
                      className="profile-upload"
                      selectedImg={profileImg}
                      defaultImg={Images.dummy_avatar}
                      defaultImgCamera={Images.AddImageIco}
                    />
                  </div>
                </div>
              </div>
              <div className="d-flex col-gap-20">
                <p className="w-50">
                  <label className="business_label_text capitalize">
                    {English.A62}
                  </label>
                  <div className="">
                    <CommonInput
                      name="full_name"
                      required
                      placeholder={English.G55}
                      value={inputFields.full_name}
                      onChange={handleChange}
                      onKeyDown={preventNonAlphabeticKeydownWithSingleSpace}
                      onPaste={preventNonAlphabeticPasteWithSingleSpace}
                    />
                  </div>
                  {errors.full_name ? (
                    <p className="error-text">{errors.full_name}</p>
                  ) : null}
                </p>
                <p className="w-50">
                  <label className="business_label_text">{English.A63}</label>
                  <div className="email-input input-container">
                    <img
                      src={Images.input_logo}
                      alt="Logo"
                      className="input-logo"
                    />
                    <CommonInput
                      type="text"
                      placeholder={UserData?.user_name}
                      className="custom-input business_email"
                      readOnly
                    />
                  </div>
                </p>
              </div>
              <div className="d-flex col-gap-20">
                <p className="w-50">
                  <label className="business_label_text">{English.G56}</label>
                  <div className="celender">
                    <DatePicker
                      ref={datePickerRef}
                      selected={startDate}
                      required
                      showMonthDropdown
                      autoComplete="off"
                      showYearDropdown
                      onChange={handleDateChange}
                      name="date_of_birth"
                      dateFormat="yyyy-MM-dd"
                      placeholderText={English.G57}
                      maxDate={
                        new Date(
                          today.getFullYear() - 15,
                          today.getMonth(),
                          today.getDate()
                        )
                      }
                      onKeyDown={(e) => {
                        if (
                          e.key !== 'Backspace' &&
                          e.key !== 'ArrowLeft' &&
                          e.key !== 'ArrowRight'
                        ) {
                          e.preventDefault()
                        }
                      }}
                      renderCustomHeader={({
                        date,
                        changeYear,
                        changeMonth,
                        decreaseMonth,
                        increaseMonth,
                        prevMonthButtonDisabled,
                        nextMonthButtonDisabled
                      }) => (
                        <div className="datepicker-header">
                          <button
                            type="button"
                            className="datepicker-button"
                            onClick={(e) => {
                              e.preventDefault()
                              decreaseMonth()
                            }}
                            disabled={prevMonthButtonDisabled}
                          >
                            {'<'}
                          </button>
                          <select
                            className="datepicker-select"
                            value={date.getFullYear()}
                            onChange={({ target: { value } }) => changeYear(Number(value))}
                          >
                            {years.map((option) => (
                              <option key={option} value={option}>
                                {option}
                              </option>
                            ))}
                          </select>
                          <select
                            className="datepicker-select"
                            value={months[date.getMonth()]}
                            onChange={({ target: { value } }) => {
                              changeMonth(months.indexOf(value))
                            }}
                          >
                            {months.map((option) => (
                              <option key={option} value={option}>
                                {option}
                              </option>
                            ))}
                          </select>
                          <button
                            type="button"
                            className="datepicker-button"
                            onClick={(e) => {
                              e.preventDefault()
                              increaseMonth()
                            }}
                            disabled={nextMonthButtonDisabled}
                          >
                            {'>'}
                          </button>
                        </div>
                      )}
                    />
                    <button
                      className="celender-button"
                      type="button"
                      onClick={handleButtonClick}
                    >
                      <img src={Images.calendar} alt="" />
                    </button>
                  </div>
                  {errors.date_of_birth ? (
                    <p className="error-text">{errors.date_of_birth}</p>
                  ) : null}
                </p>
                <p className="w-50">
                  <label className="business_label_text capitalize">
                    {English.A64}
                  </label>
                  <div className="mobile-container d-flex rounded-full pl-4 h-[60px]">
                    <div className="flex flex-row items-center">
                      <div className="inline-flex items-center gap-2">
                        <Avatar
                          className="!h-[30px] !w-[54px] rounded-3xl object-cover"
                          src={
                            (
                              countryList.find(
                                (item) => selectCode === item.dialCode
                              ) || countryList[0]
                            ).flag
                          }
                          alt={countryList[0].name}
                        />
                        <Select
                          showSearch
                          ref={selectRef}
                          value={selectCode}
                          onChange={handleSelectChange}
                          className="custom-select w-auto"
                          dropdownClassName="custom-dropdown"
                          placeholder="Select a country"
                          optionFilterProp="children"
                          suffixIcon={
                            <span className="custom-down-arrow">
                              <img src={Images.down_arrow_icon} alt="down" />
                            </span>
                          }
                          onDropdownVisibleChange={(open) => setIsDropdownOpen(open)}
                          filterOption={(input, option) => option.children
                              .toLowerCase()
                              .includes(input.toLowerCase())}
                        >
                          {countryList.map((country) => (
                            <Option
                              key={country.code}
                              value={country.dialCode}
                              label={country.dialCode}
                            >
                              {isDropdownOpen
                                ? `${country.dialCode} ${country.name}`
                                : `${country.dialCode}`}
                            </Option>
                          ))}
                        </Select>
                        <div className="border-l border-[#E4E4E4] h-[40px]" />
                        <input
                          value={inputFields.telephone}
                          placeholder={English.G19}
                          type="number"
                          name="telephone"
                          className="mobile-input telephone"
                          required
                          onKeyDown={preventInvalidKeydown}
                          onPaste={preventMultipleAtSignPaste}
                          onChange={(e) => {
                            const codec = (
                              countryList.find(
                                (item) => selectCode == item.dialCode
                              ) || countryList[0]
                            ).code
                            const regexMobile = NumberValidation[codec]
                            if (!inputFields.telephone.match(regexMobile)) {
                              // setErrors({
                              //   ...errors,
                              //   telephone: English.G24
                              // })
                              setInputFields({
                                ...inputFields,
                                telephone: e.target.value
                              })
                            }
                            if (e.target.value.match(regexMobile)) {
                              setErrors({
                                ...errors,
                                telephone: ''
                              })
                            }
                            if (
                              inputFields.telephone.length >
                              e.target.value.length
                            ) {
                              setInputFields({
                                ...inputFields,
                                telephone: e.target.value
                              })
                            }
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  {errors.telephone ? (
                    <p className="error-text">{errors.telephone}</p>
                  ) : null}
                </p>
              </div>
              <p className="bg-[#151515] bg-transparent text-[14px] font-black italic">
                {English.A70}
              </p>
              <div className="d-flex col-gap-20">
                <p className="w-50">
                  <label className="business_label_text">{English.A65}</label>
                  <div className="user-input">
                    <CountryStateCityDropDown
                      type="country"
                      onValueSelected={(value) => {
                        setSelectedCity('')
                        setSelectedCountry(value)
                      }}
                      value={inputFields.company_country || English.G408}
                      key="country"
                    />
                  </div>
                  {errors.company_country ? (
                    <p className="error-text">{errors.company_country}</p>
                  ) : null}
                </p>
                <p className="w-50">
                  <label className="business_label_text">{English.A66}</label>
                  <div className="email-input">
                    <CountryStateCityDropDown
                      type="city"
                      onValueSelected={(value) => {
                        setSelectedCity(value)
                      }}
                      selectedCountry={inputFields.company_country}
                      value={inputFields.company_city || English.G272}
                      cityDepended
                      key={selectedCountry}
                    />
                  </div>
                  {errors.company_city ? (
                    <p className="error-text">{errors.company_city}</p>
                  ) : null}
                </p>
              </div>
              <div className="d-flex col-gap-20">
                <p className="w-50 mb-0">
                  <label className="business_label_text capitalize">
                    {English.A67}
                  </label>
                  <div className="number-input">
                    <CommonInput
                      name="company_street"
                      placeholder={English.A71}
                      required
                      value={inputFields.company_street}
                      onChange={handleChange}
                      onKeyDown={allowAlphaNumericDashSingleSpaceKeydown}
                      onPaste={allowAlphaNumericDashSingleSpacePaste}
                    />
                  </div>
                  {errors.company_street ? (
                    <p className="error-text">{errors.company_street}</p>
                  ) : null}
                </p>
                <p className="w-50 mb-0">
                  <label className="business_label_text capitalize">
                    {English.A68}
                  </label>
                  <div className="password-input">
                    <CommonInput
                      name="company_zip_code"
                      placeholder={English.A71}
                      required
                      pattern="\d{5}"
                      inputMode="numeric"
                      onInput={(e) => {
                        e.target.value = e.target.value
                          .replace(/\D/g, '')
                          .slice(0, 5)
                      }}
                      value={inputFields.company_zip_code}
                      onChange={handleChange}
                    />
                  </div>
                  {errors.company_zip_code ? (
                    <p className="error-text">{errors.company_zip_code}</p>
                  ) : null}
                </p>
              </div>
              <p>
                <CommonButton
                  id="sub_btn"
                  label={English.A69}
                  type="submit"
                  className="primary-button m-auto custom-mt"
                  context="primary"
                  isPrimary
                  loading={loading}
                  disabled={!isButtonEnabled || loading}
                />
              </p>
            </form>
            <footer>
              <div className="text-[10px] text-placeholder text-center items-center justify-center !max-w-[550px] !mx-auto">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Dolor
                erat enim mattis nunc, eget volutpat. Aenean sed urna, facilisi
                vitae, volutpat faucibus.enim mattis nunc, eget volutpat. Aenean
                sed urna, facilisi vitae, volutpat faucibus
              </div>
            </footer>
          </div>
        </div>
      </div>
    </div>
  )
}
export default BusinessInfoPage
