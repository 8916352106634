import React, { useEffect, useState } from 'react'
import './Local.css'
import HotelPopup from './addPopup/hotelPopup'
// import Dropdown from '../Dropdown/Dropdown'
import RestaurantPopup from './addPopup/restaurantPopup'
import EventPopup from './addPopup/eventPopup'
// import CommonInput from '../InputComponet/CommonInput'
import CommonButton from '../ButtonComponent/ButtonCommon'
import { lockScroll, unlockScroll } from '../../helpers/Utility'
import English from '../../helpers/English'
import Images from '../../helpers/Images'
import Hotels from './Hotels'
import Restaurant from './Restaurant'
import Events from './Events'

const Local = () => {
  // const [visibleItems, setVisibleItems] = useState(3)
  const [selectLocalType, setSelectLocalType] = useState(false)
  const [isHotel, setIsHotel] = useState(false)
  const [isRestaurant, setIsRestaurant] = useState(false)
  const [isEvent, setIsEvent] = useState(false)
  const [selectedStep, setSelectedStep] = useState(0)

  // const [isExpanded, setIsExpanded] = useState(false)

  // const toggleItems = () => {
  //   if (isExpanded) {
  //     setVisibleItems(3)
  //   } else {
  //     setVisibleItems(locations.length)
  //   }
  //   setIsExpanded(!isExpanded)
  // }

  const nextClick = () => {
    if (selectedStep === null) return

    setSelectLocalType(false)

    setIsHotel(false)
    setIsRestaurant(false)
    setIsEvent(false)

    switch (selectedStep) {
      case 0:
        setIsHotel(true)
        break
      case 1:
        setIsRestaurant(true)
        break
      case 2:
        setIsEvent(true)
        break
      default:
        break
    }
  }
  const handleCancels = () => {
    setIsHotel(false)
    setIsRestaurant(false)
    setIsEvent(false)
    setSelectLocalType(false)
  }

  const addType = () => {
    setIsHotel(false)
    setIsRestaurant(false)
    setIsEvent(false)
    setSelectLocalType(true)
  }

  // const locations = [
  //   {
  //     name: 'FONTENAY HAMBURG',
  //     image: local_image
  //   },
  //   {
  //     name: 'FONTENAY HAMBURG',
  //     image: local_image
  //   },
  //   {
  //     name: 'FONTENAY HAMBURG',
  //     image: local_image
  //   },
  //   {
  //     name: 'FONTENAY HAMBURG',
  //     image: local_image
  //   },
  //   {
  //     name: 'FONTENAY HAMBURG',
  //     image: local_image
  //   },
  //   {
  //     name: 'FONTENAY HAMBURG',
  //     image: local_image
  //   }
  // ]

  const handleCancel = () => {
    setSelectLocalType(true)
    setSelectedStep(null)
    setIsHotel(false)
    setIsRestaurant(false)
    setIsEvent(false)
  }

  useEffect(() => {
    if (selectLocalType || isHotel || isRestaurant || isEvent) {
      lockScroll()
    } else {
      unlockScroll()
    }
    return () => {
      unlockScroll()
    }
  }, [selectLocalType, isHotel, isRestaurant, isEvent])

  return (
    <div className="creator-profile-main">
      <div className="feed-container d-block">
        <div className="details-tab-view common-card-body p-20">
          <div className="text-Main text-4xl italic font-black leading-none !mb-4 uppercase">
            Location Manager
          </div>
          <div className="grid grid-cols-4 !gap-4">
            <div className="border-BorderLight border-[0.5px] rounded-[15px] !py-4 !px-5 flex items-center !gap-5">
              <div className="w-[50px] h-[50px]">
                <img src={Images.Visitor} alt="visitor" />
              </div>
              <div className="">
                <p className="text-Main text-base italic font-black mb-[5px] uppercase">Locations</p>
                <span className="text-Secondary text-xl italic font-bold leading-none flex gap-2.5 items-center">
                  25
                  {/* <img src={Images.UpPath} alt="" />
                  <span>0%</span> */}
                </span>
              </div>
            </div>
            <div className="border-BorderLight border-[0.5px] rounded-[15px] !py-4 !px-5 flex items-center !gap-5">
              <div className="">
                <img src={Images.impressionLocal} alt="impression" />
              </div>
              <div className="">
                <p className="text-Main text-base italic font-black mb-[5px] uppercase">Footfall</p>
                <span className="text-Secondary text-xl italic font-bold leading-none flex gap-2.5 items-center">
                  2.5k
                  <img src={Images.UpPath} alt="" />
                  <span className="text-[#00B69B] text-sm font-semibold">1.3%</span>
                </span>
              </div>
            </div>
            <div className="border-BorderLight border-[0.5px] rounded-[15px] !py-4 !px-5 flex items-center !gap-5">
              <div className="">
                <img src={Images.ClicksLocal} alt="Clicks" />
              </div>
              <div className="">
                <p className="text-Main text-base italic font-black mb-[5px] uppercase">Clicks</p>
                <span className="text-Secondary text-xl italic font-bold leading-none flex gap-2.5 items-center">
                  358
                  <img src={Images.UpPath} alt="" />
                  <span className="text-[#00B69B] text-sm font-semibold">1.3%</span>
                </span>
              </div>
            </div>
            <div className="border-BorderLight border-[0.5px] rounded-[15px] !py-4 !px-5 flex items-center !gap-5">
              <div className="">
                <img src={Images.RequestsLocal} alt="request" />
              </div>
              <div className="">
                <p className="text-Main text-base italic font-black mb-[5px] uppercase">engagement</p>
                <span className="text-Secondary text-xl italic font-bold leading-none flex gap-2.5 items-center">
                  86.04%
                  <img src={Images.UpPath} alt="" />
                  <span className="text-[#00B69B] text-sm font-semibold">1.3%</span>
                </span>
              </div>
            </div>
          </div>

          <div className="feed-container mt-40 d-block">
            <div className="details-tab-view">
              <div className="d-flex align-items-center justify-content-between flex-wrap">
                <div className="bg-primaryGradient bg-clip-text text-transparent text-lg italic font-black uppercase pe-2">{English.G259}</div>
                {/* <div className="tool-option d-flex align-items-center drop-search-3-0">
                  <Dropdown
                    title="Sort"
                    Data={[
                      { title: 'Scaloo' },
                      { title: 'Spacure' },
                      { title: 'PETZINO' },
                      { title: 'A-Z FIT' },
                      { title: 'TESTIEE' }
                    ]}
                    customClass="content-filter disabledDropdown"
                  />
                  <Dropdown
                    title="Filter"
                    Data={[
                      { title: 'Scaloo' },
                      { title: 'Spacure' },
                      { title: 'PETZINO' },
                      { title: 'A-Z FIT' },
                      { title: 'TESTIEE' }
                    ]}
                    customClass="disabledDropdown"
                  />
                  <CommonInput
                    className="search-input disabledDropdown"
                    placeholder={English.G260}
                  />
                </div> */}
                <button type="button" className="uppercase bg-primaryGradient text-white text-sm italic font-black rounded-[100px] !py-2 !px-5" onClick={addType}>
                  {English.G255}
                </button>
              </div>
              <Hotels />
              <Restaurant />
              <Events />
              <div className="mt-2.5 local_product_grid">
                {/* Show only visible items */}
                {/* {locations.slice(0, visibleItems).map((location, index) => (
                  <div className="local_product" key={index}>
                    <div>
                      <img src={location.image} alt={location.name} />
                    </div>
                    <div className="local_location">
                      <h1>{location.name}</h1>
                    </div>
                    <div className="local_button_grp">
                      <CommonButton
                        label="VIEW LOCATION"
                        context="local"
                        isPrimary
                        className="local_view_btn"
                      />
                      <CommonButton
                        label="EDIT LOCATION"
                        context="local"
                        className="edit_local_btn"
                      />
                    </div>
                  </div>
                ))} */}

                {/* <div className="local_product">
                  <div className="add_new_border">
                    <div className="image_icon_text" onClick={addType}>
                      <img src={Images.local_add_img_All} alt="add_img" />
                      <h3>{English.G261}</h3>
                    </div>
                  </div>
                </div> */}
              </div>

              {/* <div className="collapse-button text-center mt-3">
                <button type="button" onClick={toggleItems}>
                  {isExpanded ? (
                    <img className="upIcon" alt="Show less" src={Images.DownIco} />
                  ) : (
                    <img className="downIcon" alt="Show more" src={Images.DownIco} />
                  )}
                </button>
              </div> */}

              {selectLocalType && (
                <div className="add-item-model">
                  <div className="add-item-content local_type_modal !max-w-[1200px] !m-5">
                    <div className="flex items-center justify-between">
                      {/* {step === 0 && <div>Select local type</div>} */}
                      <div className="text-Main text-base italic font-black uppercase flex items-center !gap-3">
                        <img className="cursor-pointer" src={Images.BackAerrow} alt="" onClick={handleCancels} />
                        Add Location
                      </div>
                      <button className="cancel_icon border-0 p-0" type="button" onClick={handleCancels}>
                        <img src={Images.RemoveLocal} alt="" />
                      </button>
                    </div>
                    <div className="flex items-center justify-between bg-[#E4E4E480] rounded-[10px] py-2.5 !px-5 mt-30">
                      <div className="text-main test-base font-bold">
                        Select Location Type
                      </div>
                      <div className="bg-primaryGradient bg-clip-text text-transparent text-sm flex gap-1 items-end leading-none">
                        <span className="font-black text-xl leading-none italic">01</span>
                        of 04
                      </div>
                    </div>
                    <div className="grid grid-cols-3 gap-2.5 !mt-5 mb-12">
                      <div
                        className={`border-[1.8px] rounded-[10px] !px-5 py-[15px] text-center cursor-pointer ${selectedStep === 0 ? 'border-[#001597]' : 'border-BorderLight'}`}
                        onClick={() => setSelectedStep(0)}
                      >
                        <img className="mx-auto" src={Images.Hotel} alt="hotel" />
                        <h1 className="bg-primaryGradient bg-clip-text text-transparent text-xl uppercase italic font-black leading-none my-2.5">{English.G263}</h1>
                        <h6 className="mb-0 text-Detail text-base font-medium">
                          {English.G269}
                        </h6>
                      </div>

                      <div
                        className={`border-[1.8px] rounded-[10px] !px-5 py-[15px] text-center cursor-pointer ${selectedStep === 1 ? 'border-[#001597]' : 'border-BorderLight'}`}
                        onClick={() => setSelectedStep(1)}
                      >
                        <img className="mx-auto" src={Images.Restaurant} alt="restaurant" />
                        <h1 className="bg-primaryGradient bg-clip-text text-transparent text-xl uppercase italic font-black leading-none my-2.5">{English.G264}</h1>
                        <h6 className="mb-0 text-Detail text-base font-medium">
                          {English.G269}
                        </h6>
                      </div>

                      <div
                        className={`border-[1.8px] rounded-[10px] !px-5 py-[15px] text-center cursor-pointer ${selectedStep === 2 ? 'border-[#001597]' : 'border-BorderLight'}`}
                        onClick={() => setSelectedStep(2)}
                      >
                        <img className="mx-auto" src={Images.Events} alt="events" />
                        <h1 className="bg-primaryGradient bg-clip-text text-transparent text-xl uppercase italic font-black leading-none my-2.5">{English.A194}</h1>
                        <h6 className="mb-0 text-Detail text-base font-medium">
                          {English.G269}
                        </h6>
                      </div>

                      <div
                        className={`border-[1.8px] rounded-[10px] !px-5 py-[15px] text-center cursor-pointer ${selectedStep === 3 ? 'border-[#001597]' : 'border-BorderLight'}`}
                        onClick={() => setSelectedStep(3)}
                      >
                        <img className="mx-auto" src={Images.Stores} alt="stores" />
                        <h1 className="bg-primaryGradient bg-clip-text text-transparent text-xl uppercase italic font-black leading-none my-2.5">{English.G266}</h1>
                        <h6 className="mb-0 text-Detail text-base font-medium">
                          {English.G269}
                        </h6>
                      </div>

                      <div
                        className={`border-[1.8px] rounded-[10px] !px-5 py-[15px] text-center cursor-pointer ${selectedStep === 4 ? 'border-[#001597]' : 'border-BorderLight'}`}
                        onClick={() => setSelectedStep(4)}
                      >
                        <img className="mx-auto" src={Images.ServiceProvider} alt="service-provider" />
                        <h1 className="bg-primaryGradient bg-clip-text text-transparent text-xl uppercase italic font-black leading-none my-2.5">{English.G267}</h1>
                        <h6 className="mb-0 text-Detail text-base font-medium">
                          {English.G269}
                        </h6>
                      </div>

                      <div
                        className={`border-[1.8px] rounded-[10px] !px-5 py-[15px] text-center cursor-pointer ${selectedStep === 5 ? 'border-[#001597]' : 'border-BorderLight'}`}
                        onClick={() => setSelectedStep(5)}
                      >
                        <img className="mx-auto" src={Images.Excursions} alt="excursion" />
                        <h1 className="bg-primaryGradient bg-clip-text text-transparent text-xl uppercase italic font-black leading-none my-2.5">{English.G268}</h1>
                        <h6 className="mb-0 text-Detail text-base font-medium">
                          {English.G269}
                        </h6>
                      </div>
                    </div>
                    <div className="local_button_row">
                      {/* <CommonButton
                        label={English.G116}
                        onClick={handleCancels}
                        context="local"
                        className="local_type_cancel"
                      />
                      <CommonButton
                        label={English.G69}
                        onClick={nextClick}
                        context="local"
                        className="local_type_next"
                        isPrimary
                      /> */}
                      <div>
                        {/* <CommonButton
                          label={English.G116}
                          onClick={handleCancels}
                          isPrimary={false}
                          context={English.G232}
                        /> */}
                      </div>
                      <div>
                        <CommonButton
                          label={English.G69}
                          type="button"
                          isPrimary
                          onClick={nextClick}
                          context={English.A197}
                          disabled={selectedStep === null}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              )}

              {isHotel && <HotelPopup onCancel={handleCancel} />}
              {isRestaurant && <RestaurantPopup onCancel={handleCancel} />}
              {isEvent && <EventPopup onCancel={handleCancel} />}
            </div>
          </div>
        </div>

      </div>
    </div>
  )
}

export default Local
