/* eslint-disable no-unused-vars */
/* eslint-disable max-len */
/* eslint-disable no-case-declarations */
/* eslint-disable no-restricted-globals */
/* eslint-disable no-useless-escape */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-lone-blocks */
/* eslint-disable import/no-duplicates */
import React, { useEffect, useMemo, useState } from 'react'
import { toast } from 'react-toastify'
import { useNavigate, useParams } from 'react-router-dom'
import CommonImageUpload from '../../ImageCroper/StoreImageCroper'
import {
  locationSuggestion,
  s3ImageRestaurant
} from '../../../services/Service'
import { compressImage, dataDecrypt } from '../../../services/http-services'
import Loader from '../../Loader/Loader'
import English from '../../../helpers/English'
import Images from '../../../helpers/Images'
import { APICall, EndPoints } from '../../../services'
import { emitter, EventType } from '../../../helpers/Emitter'
import LocationPopup from './LocationPopup'
import DetailsPopup from './DetailsPopup'
import ContactImagePopup from './ContactImagePopup'

const restaurantPopup = ({
  onCancel,
  defaultData = {},
  apiSuccess = () => {},
  setIsShowRestroList,
  setIsShow,
  // particularRestaurantApiCall = () => {},
  editRestaurant,
  isFromRestaurantComponent,
  platformData
}) => {
  const { id } = useParams()
  const navigate = useNavigate()
  const DataTrue = editRestaurant
  const [inputFields, setInputFields] = useState({
    ...defaultData,
    location_title: '',
    name: '',
    description: '',
    city_id: '',
    zip_code: '',
    longitude: '',
    latitude: '',
    website: '',
    min_price: '',
    max_price: '',
    logo: '',
    opening_time: '',
    closing_time: '',
    images: [],
    contact_name1: '',
    contact_name2: '',
    contact_designation1: '',
    contact_designation2: '',
    // contact_phone1: '',
    // contact_phone2: '',
    contact_profile_pic: [],
    restaurant_id: '',
    country_id: '',
    province_id: '',
    country: '',
    city_name: '',
    province_name: '',
    address: ''
  })

  const [isCountryList, setIsCountryList] = useState([])
  const [isProvinceList, setIsProvinceList] = useState([])
  const [isCityListData, setIsCityListData] = useState([])
  const [isImageLoading, setIsImageLoading] = useState({
    index: null,
    loading: false
  })
  const [draggedIndex, setDraggedIndex] = useState(null)
  const [markerPosition, setMarkerPosition] = useState(null)
  const [errors, setErrors] = useState('')
  const [isStep1Modified, setIsStep1Modified] = useState(false)
  const [isStep2Modified, setIsStep2Modified] = useState(false)
  const [isStep3Modified, setIsStep3Modified] = useState(false)
  const [isStep4Modified, setIsStep4Modified] = useState(false)
  const [error, setError] = useState({
    min_price: '',
    max_price: '',
    opening_time: '',
    closing_time: ''
  })
  const [initialImages, setInitialImages] = useState(defaultData.images || [])
  const isEditMode = Object.keys(defaultData)?.length > 0
  const [isFieldChanged, setIsFieldChanged] = useState(false)

  const clearValue = () => {
    setInputFields({
      location_title: '',
      name: '',
      description: '',
      city_id: '',
      zip_code: '',
      longitude: '',
      latitude: '',
      website: '',
      min_price: '',
      max_price: '',
      logo: '',
      opening_time: '',
      closing_time: '',
      images: [],
      contact_name1: '',
      contact_name2: '',
      contact_designation1: '',
      contact_designation2: '',
      // contact_phone1: '',
      // contact_phone2: '',
      contact_profile_pic: [],
      restaurant_id: '',
      country_id: '',
      province_id: '',
      country: '',
      city_name: '',
      province_name: '',
      address: ''
    })
    setIsFieldChanged(false)
  }

  const onChangeOpenTime = (time, timeString) => {
    if (timeString) {
      setInputFields((prevState) => ({
        ...prevState,
        opening_time: timeString
      }))
      setError((prev) => ({
        ...prev,
        opening_time: ''
      }))
    }
  }

  const onChangeCloseTime = (time, timeString) => {
    if (timeString) {
      if (inputFields?.opening_time && timeString < inputFields?.opening_time) {
        setError((prev) => ({
          ...prev,
          closing_time: English.G325
        }))
      } else {
        setInputFields((prevState) => ({
          ...prevState,
          closing_time: timeString
        }))
        setError((prev) => ({
          ...prev,
          closing_time: ''
        }))
      }
    }
  }
  const [imagesCleared, setImagesCleared] = useState(false)

  const urlRegex =
    /^(https?|ftp):\/\/(([a-z\d]([a-z\d-]*[a-z\d])?\.)+[a-z]{2,}|localhost)(\/[-a-z\d%_.~+]*)*(\?[;&a-z\d%_.~+=-]*)?(\#[-a-z\d_]*)?$/i

  const handleCancelStep1 = () => {
    // setLocationDetails(false)
    onCancel()
  }

  const handleCancels = () => {
    setCurrentStep(0)
    onCancel()
    clearValue()
  }

  const validatePrices = (minPrice, maxPrice) => {
    if (!isNaN(minPrice) && !isNaN(maxPrice)) {
      if (maxPrice <= minPrice) {
        setError((prev) => ({
          ...prev,
          max_price: English.G410
        }))
      } else {
        setError((prev) => ({
          ...prev,
          max_price: ''
        }))
      }
    }
  }

  const handleKeyDown = (e) => {
    const value = e.target.value.trim()
    if (e.key === 'Enter' && value.length <= 4 && !value.includes('-')) {
      setInputFields((prev) => ({
        ...prev,
        price_range: `${value} - `
      }))
      e.preventDefault()
    }
  }

  const handleOnClick = (e) => {
    const { value } = e.target
    const dashIndex = value.indexOf('-')
    if (dashIndex > -1) {
      const { selectionStart } = e.target
      if (selectionStart <= dashIndex) {
        return
      }
      if (selectionStart > dashIndex + 2) {
        return
      }
      e.target.setSelectionRange(dashIndex + 2, dashIndex + 2)
    }
  }

  const onChange = (e) => {
    const { name, value } = e.target
    const cursorPosition = e.target.selectionStart

    if (name === 'price_range') {
      const cleanedValue = value.replace(/[^0-9-]/g, '')
      const priceParts = cleanedValue.split('-').map((p) => p.trim())
      if (!cleanedValue) {
        setInputFields((prev) => ({
          ...prev,
          price_range: '',
          min_price: '',
          max_price: ''
        }))
        return
      }

      if (priceParts.length === 1) {
        const min = priceParts[0]

        if (!min) {
          setInputFields((prev) => ({
            ...prev,
            price_range: '',
            min_price: ''
          }))
          return
        }

        if (min.length === 4 && !cleanedValue.includes('-')) {
          setInputFields((prev) => ({
            ...prev,
            price_range: `${min} - `,
            min_price: min
          }))
          setTimeout(() => e.target.setSelectionRange(min.length + 3, min.length + 3), 0)
        } else {
          setInputFields((prev) => ({
            ...prev,
            price_range: min,
            min_price: min
          }))
        }

        // setTimeout(() => e.target.setSelectionRange(min.length, min.length), 0)
      }

      if (priceParts.length === 2) {
        const min = priceParts[0]
        const max = priceParts[1]

        if (
          cursorPosition === min.length + 3 &&
          e.nativeEvent.inputType === 'deleteContentBackward'
        ) {
          setInputFields((prev) => ({
            ...prev,
            price_range: min,
            min_price: min
          }))

          setTimeout(
            () => e.target.setSelectionRange(min.length, min.length),
            0
          )
          return
        }

        const minPrice = min.slice(0, 4)
        const maxPrice = max.slice(0, 4)

        setInputFields((prev) => ({
          ...prev,
          min_price: minPrice,
          max_price: maxPrice,
          price_range: maxPrice
            ? `${minPrice} - ${maxPrice}`
            : `${minPrice} - `
        }))

        validatePrices(parseFloat(minPrice), parseFloat(maxPrice))

        if (max) {
          setTimeout(() => e.target.setSelectionRange(cursorPosition, cursorPosition), 0)
        } else {
          setTimeout(() => e.target.setSelectionRange(min.length + 3, min.length + 3), 0)
        }
      }
    } else {
      setInputFields((prev) => ({
        ...prev,
        [name]: value
      }))

      if (name === 'website' && urlRegex.test(value)) {
        setErrors('')
      }
    }
    setIsFieldChanged(true)
  }

  const onUploadImage = async (file, fieldName, index) => {
    if (file) {
      const reader = new FileReader()
      reader.onload = async () => {
        try {
          const imageFile = await compressImage(file)
          const coverFormData = new FormData()
          coverFormData.append('file', imageFile)

          if (fieldName === 'contact_profile_pic') {
            coverFormData.append('type', 'restaurant_contact')
          } else if (fieldName === 'logo') {
            coverFormData.append('type', 'restaurant_logo')
          } else {
            coverFormData.append('type', 'restaurant')
          }

          try {
            const res = await APICall(
              'post',
              coverFormData,
              '',
              'imageUploadLocal',
              true
            )
            if (res.status === 200 && res.data?.code === 200) {
              const decryptedImageData = dataDecrypt(res.data.data)
              setInputFields((prev) => {
                if (fieldName === 'logo') {
                  return {
                    ...prev,
                    [fieldName]: decryptedImageData
                  }
                }
                const updatedImages = [...(prev[fieldName] || [])]

                if (index !== undefined && index !== null) {
                  updatedImages[index] = decryptedImageData
                } else {
                  updatedImages.push(decryptedImageData)
                }

                return {
                  ...prev,
                  [fieldName]: updatedImages
                }
              })

              setIsStep4Modified(true)
              setIsFieldChanged(true)
            } else {
              toast.error(res?.data?.message || 'Error uploading image')
            }
          } catch (err) {
            toast.error(err?.response?.data?.message || 'Upload failed')
          }
        } catch (error) {
          console.error('Error uploading image:', error)
        }
      }
      reader.readAsDataURL(file)
    }
  }

  const handleMapPositionChange = (lat, lng) => {
    setMarkerPosition({ lat, lng })
    setInputFields((prev) => ({
      ...prev,
      latitude: lat,
      longitude: lng
    }))
  }

  // api call

  const provinceListData = (data) => {
    APICall('post', data, `${EndPoints.provinceList}`, 'local')
      .then((res) => {
        if (res.status === 200 && res.data?.code === 200) {
          setIsProvinceList(res?.decryptedData)
        } else {
          toast.error(res?.data?.message)
        }
      })
      .catch((error) => {
        toast.error(error?.data?.message)
      })
  }

  const cityListData = (data) => {
    APICall('post', data, `${EndPoints.cityList}`, 'local')
      .then((res) => {
        if (res.status === 200 && res.data?.code === 200) {
          setIsCityListData(res?.decryptedData.data)
        } else {
          toast.error(res?.data?.message)
        }
      })
      .catch((error) => {
        toast.error(error?.data?.message)
      })
  }

  const restaurantAddApiCall = () => {
    let payload = {
      location_title: inputFields.location_title,
      name: inputFields.name,
      description: inputFields.description,
      address: inputFields.address,
      city_id: inputFields.city_id,
      zip_code: inputFields.zip_code,
      longitude: inputFields.longitude,
      latitude: inputFields.latitude,
      website: inputFields.website,
      min_price: inputFields.min_price,
      max_price: inputFields.max_price,
      opening_time: inputFields.opening_time,
      closing_time: inputFields.closing_time,
      logo: inputFields.logo,
      images: inputFields.images,
      contact_name: [
        inputFields.contact_name1,
        inputFields.contact_name2
      ].filter((item) => item !== '' && item !== null),
      contact_designation: [
        inputFields.contact_designation1,
        inputFields.contact_designation2
      ].filter((item) => item !== '' && item !== null),
      // contact_phone: [
      //   inputFields.contact_phone1,
      //   inputFields.contact_phone2
      // ].filter((item) => item !== '' && item !== null),
      contact_profile_pic: inputFields.contact_profile_pic,
      restaurant_id: inputFields.restaurant_id,
      ...(isFromRestaurantComponent && { app_id: [platformData[0]] })
    }
    payload = Object.fromEntries(
      Object.entries(payload).filter(
        ([key, value]) => !(
            value === '' ||
            (Array.isArray(value) &&
              (value.length === 0 || value.every((item) => item === '')))
          )
      )
    )

    if (Object.keys(defaultData).length > 0) {
      APICall('post', payload, `${EndPoints.editRestaurant}`, 'local')
        .then((res) => {
          if (res.status === 200 && res.data?.code === 200) {
            toast.success(res?.data?.message)
            setTimeout(() => {
              emitter.emit(EventType.particularRestaurant, {
                action: 'edit'
              })
              apiSuccess()
            }, 3000)
            // particularRestaurantApiCall(inputFields.restaurant_id)
            setCurrentStep(0)
            setIsShowRestroList(true)
            setIsShow(false)
          } else {
            toast.error(res?.data?.message)
          }
        })
        .catch((error) => {
          toast.error(error?.data?.message)
        })
    } else {
      APICall('post', payload, `${EndPoints.addRestaurant}`, 'local')
        .then((res) => {
          if (res.status === 200 && res.data?.code === 200) {
            toast.success(res?.data?.message)
            setTimeout(() => {
              emitter.emit(EventType.addRestaurantList, {
                action: 'add'
              })
              apiSuccess()
              if (isFromRestaurantComponent) {
                navigate(`/local-builder/${id}`)
              } else {
                navigate('/local')
              }
            }, 3000)
            setCurrentStep(0)
          } else {
            toast.error(res?.data?.message)
          }
        })
        .catch((error) => {
          toast.error(error?.data?.message)
        })
    }
  }

  const removeImage = (index) => {
    const updatedImages = [...inputFields.images]
    updatedImages.splice(index, 1)
    setInputFields((prev) => ({
      ...prev,
      images: updatedImages
    }))
    setIsStep4Modified(true)
  }

  const handleDragStart = (index) => {
    setDraggedIndex(index)
  }

  const handleDrop = (index) => {
    if (index !== draggedIndex) {
      setInputFields((prev) => {
        const copy = [...prev.images]
        const b = copy[draggedIndex]
        copy[draggedIndex] = copy[index]
        copy[index] = b
        return {
          ...prev,
          images: copy
        }
      })

      setDraggedIndex(null)
    }
  }

  const handleLoad = (index) => {
    setIsImageLoading({
      index,
      loading: true
    })
    setTimeout(() => {
      setIsImageLoading({
        index: null,
        loading: false
      })
    }, 500)
  }

  const renderImg = useMemo(() => {
    return (
      <>
        {inputFields.images.map((image, index) => {
          return (
            <div
              className={`image position-relative !shadow-none ${
                index === 0 ? 'first-image' : ''
              }`}
              key={index}
              alt={`image-${index}`}
              draggable
              onDragStart={() => {
                handleDragStart(index)
              }}
              onDragOver={(event) => event.preventDefault()}
              onDragEnter={(event) => event.preventDefault()}
              onDrop={(event) => {
                event.preventDefault()
                handleDrop(index)
              }}
            >
              {isImageLoading.index === index && isImageLoading.loading && (
                <Loader />
              )}
              <img
                className={`image position-relative !shadow-none ${
                  index === 0 ? 'first-image' : ''
                }`}
                src={
                  typeof image === 'string'
                    ? s3ImageRestaurant + image
                    : URL.createObjectURL(image)
                }
                alt={`image-${index}`}
                style={{
                  width: '100%',
                  height: '100%',
                  objectFit: 'cover'
                }}
                onLoad={() => handleLoad(index)}
              />
              <button
                className="image_remove_icon"
                type="button"
                onClick={() => removeImage(index)}
              >
                <img src={Images.RemoveLocal} alt="" />
              </button>
              <button type="button" className="plus-img">
                <CommonImageUpload
                  handleImageChange={(file) => {
                    onUploadImage(file, 'images', index)
                  }}
                  selectedImg=""
                  defaultImg={Images.plusimg}
                  className="plsimg"
                />
              </button>
            </div>
          )
        })}
      </>
    )
  }, [inputFields.images, handleDragStart])

  useEffect(() => {
    if (Object.keys(defaultData).length > 0) {
      setInputFields(defaultData)
      provinceListData({
        country_id: defaultData.country_id
      })
      cityListData({
        province_id: defaultData.province_id
      })
    }
  }, [defaultData])

  const [currentStep, setCurrentStep] = useState(1)

  const handleStartStep2 = () => {
    setErrors('')
    if (inputFields.website && !urlRegex.test(inputFields.website)) {
      setErrors(English.G73)
      return
    }
    setCurrentStep(2)
  }

  const handleNextClick = () => {
    if (currentStep === 2) {
      if (!inputFields.website || !urlRegex.test(inputFields.website)) {
        setErrors(English.G73)
        return
      }
    }
    if (currentStep < 3) {
      if (currentStep === 2 && editRestaurant) {
        setCurrentStep(3)
      } else {
        setCurrentStep(currentStep + 1)
      }
    } else {
      restaurantAddApiCall()
    }
  }

  const handleBackClick = () => {
    if (currentStep > 1) {
      if (currentStep === 3 && editRestaurant) {
        setCurrentStep(2)
      } else {
        setCurrentStep(currentStep - 1)
      }
    }
  }

  useEffect(() => {
    setIsStep1Modified(
      inputFields.location_title !== defaultData.location_title ||
        inputFields.country_id !== defaultData.country_id ||
        inputFields.province_id !== defaultData.province_id ||
        inputFields.city_id !== defaultData.city_id ||
        inputFields.zip_code !== defaultData.zip_code
    )
    setIsStep2Modified(
      inputFields.name !== defaultData.name ||
        inputFields.logo !== defaultData.logo ||
        inputFields.price_range !== defaultData.price_range ||
        inputFields.min_price !== defaultData.min_price ||
        inputFields.max_price !== defaultData.max_price ||
        inputFields.description !== defaultData.description ||
        inputFields.website !== defaultData.website
    )
    setIsStep3Modified(
      inputFields.contact_profile_pic !== defaultData.contact_profile_pic ||
        inputFields.contact_name1 !== defaultData.contact_name1 ||
        inputFields.contact_name2 !== defaultData.contact_name2 ||
        inputFields.contact_designation1 !== defaultData.contact_designation1 ||
        inputFields.contact_designation2 !== defaultData.contact_designation2 ||
        // inputFields.contact_phone1 !== defaultData.contact_phone1 ||
        // inputFields.contact_phone2 !== defaultData.contact_phone2
        inputFields?.images?.length !== defaultData?.images?.length
    )
    // setIsStep4Modified(
    //   inputFields?.images?.length !== defaultData?.images?.length
    // )
  }, [inputFields])

  const isStep1Valid = () => {
    return (
      (!isEditMode ? isStep1Modified : isEditMode) &&
      inputFields.location_title &&
      inputFields.country_id &&
      inputFields.province_id &&
      inputFields.city_id &&
      inputFields.zip_code.length > 4 &&
      markerPosition
    )
  }

  const isStep2Valid = () => {
    return (
      (!isEditMode ? isStep2Modified : isEditMode) &&
      inputFields.logo &&
      inputFields.name &&
      inputFields.price_range &&
      inputFields.website &&
      inputFields.min_price &&
      inputFields.max_price &&
      inputFields.opening_time &&
      inputFields.closing_time &&
      inputFields.description &&
      error.max_price === ''
    )
  }

  const isStep3Valid = () => {
    const hasImagesChanged =
      JSON.stringify(inputFields.images) !== JSON.stringify(initialImages)
    return (
      isStep3Modified &&
      inputFields.images.length > 0 &&
      hasImagesChanged &&
      (editRestaurant ||
        (inputFields.contact_profile_pic.length === 2 &&
          inputFields.contact_name1 &&
          inputFields.contact_name2 &&
          inputFields.contact_designation1 &&
          // inputFields.contact_phone1.length > 10 &&
          // inputFields.contact_phone2.length > 10
          inputFields.contact_designation2))
    )
  }

  // const isStep4Valid = () => {
  //   return isStep4Modified && inputFields.images.length > 0
  // }

  useEffect(() => {
    if (inputFields.latitude && inputFields.longitude) {
      setMarkerPosition({
        lat: Number(inputFields.latitude),
        lng: Number(inputFields.longitude)
      })
    }
  }, [inputFields.latitude, inputFields.longitude])

  const clearCurrentStepData = (step) => {
    switch (step) {
      // case 2:
      //   setInputFields({
      //     ...inputFields,
      //     logo: '',
      //     name: '',
      //     price_range: '',
      //     min_price: '',
      //     max_price: '',
      //     description: '',
      //     opening_time: '',
      //     closing_time: '',
      //     website: ''
      //   })
      //   break
      case 3:
        setInputFields({
          ...inputFields,
          contact_name1: '',
          contact_designation1: '',
          // contact_phone1: '',
          contact_name2: '',
          contact_designation2: '',
          // contact_phone2: '',
          contact_profile_pic: ['', '']
        })
        const hasImages = inputFields.images?.length > 0
        setInputFields({
          ...inputFields,
          images: []
        })
        if (hasImages) {
          setImagesCleared(true)
        } else {
          restaurantAddApiCall()
        }
        break
      default:
        break
    }
  }

  const handleSkipClick = () => {
    if (currentStep >= 2 && currentStep <= 3) {
      clearCurrentStepData(currentStep)
      if (currentStep === 2 && editRestaurant) {
        setCurrentStep(3)
      } else if (currentStep < 3) {
        setCurrentStep(currentStep + 1)
      }
    }
  }

  useEffect(() => {
    if (imagesCleared) {
      restaurantAddApiCall()
      setImagesCleared(false)
    }
  }, [imagesCleared])

  useEffect(() => {
    setInitialImages(defaultData.images || [])
  }, [])

  return (
    <div className="content-manager">
      {currentStep === 1 && (
        <LocationPopup
          inputFields={inputFields}
          setInputFields={setInputFields}
          onCancel={handleCancelStep1}
          onSubmit={handleStartStep2}
          countryList={isCountryList}
          provinceList={isProvinceList}
          cityList={isCityListData}
          locationSuggestion={locationSuggestion}
          onMapPositionChange={handleMapPositionChange}
          markerPosition={markerPosition}
          defaultData={defaultData}
          disabled={!isStep1Valid()}
        />
      )}

      {currentStep === 2 && (
        <DetailsPopup
          inputFields={inputFields}
          errors={errors}
          error={error}
          text="Restaurant"
          s3Image={s3ImageRestaurant}
          handleKeyDown={handleKeyDown}
          handleOnClick={handleOnClick}
          validatePrices={validatePrices}
          handleBackClick={handleBackClick}
          handleCancels={handleCancels}
          setInputFields={setInputFields}
          onChangeOpenTime={onChangeOpenTime}
          onChangeCloseTime={onChangeCloseTime}
          setError={setError}
          onChange={onChange}
          onUploadImage={onUploadImage}
          onCancel={handleSkipClick}
          onSubmit={handleNextClick}
          defaultData={defaultData}
          isEditMode={isEditMode}
          disabled={!isStep2Valid()}
        />
      )}

      {currentStep === 3 && (
        <ContactImagePopup
          text="Restaurant"
          s3Image={s3ImageRestaurant}
          handleBackClick={handleBackClick}
          handleCancels={handleCancels}
          onChange={onChange}
          renderImg={renderImg}
          editFrom={DataTrue}
          contactImage0={inputFields.contact_profile_pic[0]}
          contactImage1={inputFields.contact_profile_pic[1]}
          contactName1={inputFields.contact_name1}
          contactName2={inputFields.contact_name2}
          contactDesignation1={inputFields.contact_designation1}
          contactDesignation2={inputFields.contact_designation2}
          ImagesData={inputFields?.images}
          onUploadImage={onUploadImage}
          onCancel={handleSkipClick}
          onSubmit={handleNextClick}
          defaultData={defaultData}
          isEditMode={isEditMode}
          disabled={isEditMode ? !isFieldChanged : !isStep3Valid()}
        />
      )}
    </div>
  )
}
export default restaurantPopup
