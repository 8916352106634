/* eslint-disable no-nested-ternary */
/* eslint-disable max-len */
import React from 'react'
import { TimePicker } from 'antd'
import dayjs from 'dayjs'
import { English, Images } from '../../../helpers'
import CommonInput from '../../InputComponet/CommonInput'
import CommonButton from '../../ButtonComponent/ButtonCommon'
import { capitalizeFirstLetter } from '../../../helpers/Utility'
import CommonImageUpload from '../../ImageCroper/StoreImageCroper'
import '../Local.css'

const DetailsPopup = ({
  onChange,
  inputFields,
  // onCancel,
  onSubmit,
  disabled,
  handleBackClick,
  handleCancels,
  errors,
  handleKeyDown,
  handleOnClick,
  error,
  validatePrices,
  onUploadImage,
  text,
  setInputFields,
  onChangeOpenTime,
  onChangeCloseTime,
  setError,
  s3Image
}) => {
  return (
    <div className="add-item-model">
      <div className="add-item-content local_type_modal !max-w-[1200px] !m-5">
        <div className="flex items-center justify-between local_sticky_header">
          <div className="text-Main text-base italic font-black uppercase flex items-center !gap-3">
            <img
              className="cursor-pointer"
              onClick={handleBackClick}
              src={Images.BackAerrow}
              alt="Back"
            />
            Add Location
          </div>
          <button
            className="cancel_icon border-0 p-0"
            type="button"
            onClick={handleCancels}
          >
            <img src={Images.RemoveLocal} alt="Close" />
          </button>
        </div>

        <div className="flex items-center justify-between bg-[#E4E4E480] rounded-[10px] py-2.5 !px-5 mt-3">
          <div className="text-main test-base font-bold">
            {text === 'Hotel'
              ? 'Hotel Details'
              : text === 'Restaurant'
                ? 'Restaurant Details'
                : text === 'Organizer'
                  ? 'Event Details'
                  : ''}
          </div>
          <div className="bg-primaryGradient bg-clip-text text-transparent text-sm flex gap-1 items-end leading-none">
            <span className="font-black text-xl leading-none italic">03</span>
            of 04
          </div>
        </div>
        <div className={`item-details-input pt-0 local_contact_scroll_bars !max-h-[calc(80vh-205px)] ${error?.closing_time ? '!overflow-auto' : '!overflow-hidden'} !mt-2`}>
          <div className="logo_btn mb-2">
            <div className="add_logo">
              <CommonImageUpload
                id="upload_button"
                name="local_upload_logo"
                handleImageChange={(file) => onUploadImage(file, 'logo')}
                index={0}
                forImage="local_logo"
                className="profile-upload mb-0 h-full flex items-center"
                selectedImg={
                  inputFields.logo ? `${s3Image}${inputFields.logo}` : ''
                }
                defaultImg={Images.local_add_img}
              />
            </div>
            <div className="upload_btn_logo">
              <p className="image_validation mb-0">
                {English.G317}
                <br />
                {English.G318}
              </p>
            </div>
          </div>
          <div className="grid grid-cols-2 !gap-5">
            <div>
              <div>
                <label>
                  {text === 'Hotel'
                    ? 'Hotel Name'
                    : text === 'Restaurant'
                      ? 'Restaurant Name'
                      : text === 'Organizer'
                        ? English.G332
                        : ''}
                </label>
                <CommonInput
                  placeholder={English.A201}
                  name="name"
                  onChange={onChange}
                  allowTextOnly
                  maxLength={40}
                  value={capitalizeFirstLetter(inputFields.name)}
                />
              </div>
              <div>
                <label>Price Range</label>
                <CommonInput
                  placeholder="€€ - €€€ / Night"
                  value={inputFields.price_range || ''}
                  onChange={onChange}
                  onKeyDown={handleKeyDown}
                  onClick={handleOnClick}
                  name="price_range"
                  className={`${error.max_price ? 'mb-0' : ''}`}
                  maxLength={11}
                  // onKeyPress={validateInput}
                  type="text"
                  onBlur={validatePrices}
                />
                {error.max_price && (
                  <span className="error-message mt-0">{error.max_price}</span>
                )}
              </div>

              {text === 'Restaurant' ? (
                <div className="input-grid">
                  <div>
                    <label className="block">{English.G323}</label>
                    <TimePicker
                      name="opening_time"
                      onChange={onChangeOpenTime}
                      showNow={false}
                      showOk={false}
                      suffixIcon={null}
                      placeholder={English.G324}
                      value={
                        inputFields?.opening_time
                          ? dayjs(inputFields?.opening_time, 'HH:mm')
                          : null
                      }
                      // use12Hours
                      format="HH:mm"
                      // format="HH:mm A"
                      className="ant-design event_time w-full"
                      style={{ 'margin-top': '0px' }}
                      onSelect={(time) => {
                        const timeString = time ? time.format('HH:mm') : ''
                        setInputFields((prevState) => ({
                          ...prevState,
                          opening_time: timeString
                        }))
                        setError((prev) => ({
                          ...prev,
                          closing_time: ''
                        }))
                      }}
                      popupClassName="custom-timepicker-dropdown"
                    />
                  </div>
                  <div>
                    <label className="block">{English.G387}</label>
                    <TimePicker
                      name="closing_time"
                      onChange={onChangeCloseTime}
                      showNow={false}
                      showOk={false}
                      suffixIcon={null}
                      placeholder={English.G324}
                      value={
                        inputFields?.closing_time
                          ? dayjs(inputFields?.closing_time, 'HH:mm')
                          : null
                      }
                      format="HH:mm"
                      // use12Hours // Enables AM/PM
                      // format="HH:mm A"
                      className="ant-design event_time w-full"
                      style={{ 'margin-top': '0px' }}
                      onSelect={(time) => {
                        const timeString = time ? time.format('HH:mm') : ''
                        setInputFields((prevState) => ({
                          ...prevState,
                          closing_time: timeString
                        }))
                        if (
                          inputFields?.opening_time &&
                          timeString < inputFields?.opening_time
                        ) {
                          setError((prev) => ({
                            ...prev,
                            closing_time: English.G325
                          }))
                        } else {
                          setError((prev) => ({
                            ...prev,
                            closing_time: ''
                          }))
                        }
                      }}
                      popupClassName="custom-timepicker-dropdown"
                    />
                    {error.closing_time && (
                      <span className="error-message">
                        {error.closing_time}
                      </span>
                    )}
                  </div>
                </div>
              ) : null}
            </div>
            <div>
              <div>
                <label>{English.A200}</label>
                <CommonInput
                  placeholder={English.A167}
                  name="website"
                  onChange={onChange}
                  value={inputFields.website}
                  className={`${errors ? 'mb-0' : ''}`}
                />
                {errors && <div className="error-message">{errors}</div>}
              </div>
              <div className="">
                <div className="local_event_description">
                  <label>{English.G112}</label>
                  <CommonInput
                    placeholder={English.G326}
                    name="description"
                    onChange={onChange}
                    maxLength={350}
                    value={capitalizeFirstLetter(inputFields.description)}
                    isTextArea
                    className="mb-4"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="local_button_row !justify-end">
          {/* <CommonButton
            label={English.G333}
            onClick={onCancel}
            isPrimary={false}
            context={English.A197}
          /> */}
          <CommonButton
            label={English.G69}
            type="button"
            isPrimary
            onClick={onSubmit}
            context={English.A197}
            disabled={disabled}
          />
        </div>
      </div>
    </div>
  )
}

export default DetailsPopup
