/* eslint-disable no-unused-vars */
/* eslint-disable no-case-declarations */
/* eslint-disable no-restricted-globals */
/* eslint-disable no-useless-escape */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-lone-blocks */
/* eslint-disable no-nested-ternary */
/* eslint-disable max-len */
/* eslint-disable import/no-duplicates */
import React, { useEffect, useMemo, useState } from 'react'
import { toast } from 'react-toastify'
import { useNavigate, useParams } from 'react-router-dom'
import CommonImageUpload from '../../ImageCroper/StoreImageCroper'
import CommonInput from '../../InputComponet/CommonInput'
import CommonButton from '../../ButtonComponent/ButtonCommon'
import {
  locationSuggestion,
  s3ImageHotel,
  s3ImageLocalIcon
} from '../../../services/Service'
import 'leaflet/dist/leaflet.css'
import { compressImage, dataDecrypt } from '../../../services/http-services'
import Loader from '../../Loader/Loader'
import English from '../../../helpers/English'
import Images from '../../../helpers/Images'
import { APICall, EndPoints } from '../../../services'
import { emitter, EventType } from '../../../helpers/Emitter'
import LocationPopup from './LocationPopup'
import DetailsPopup from './DetailsPopup'
import ContactImagePopup from './ContactImagePopup'

const hotelPopup = ({
  onCancel,
  defaultData = {},
  apiSuccess = () => {},
  setIsShowHotelList,
  setIsShow,
  // particularHotelApiCall = () => {},
  editHotel,
  isFromHotelComponent,
  platformData
}) => {
  const { id } = useParams()
  const navigate = useNavigate()
  const [inputFields, setInputFields] = useState({
    ...defaultData,
    location_title: '',
    name: '',
    description: '',
    city_id: '',
    zip_code: '',
    longitude: '',
    latitude: '',
    website: '',
    amenities: [],
    min_price: '',
    max_price: '',
    logo: '',
    images: [],
    contact_name1: '',
    contact_name2: '',
    contact_designation1: '',
    contact_designation2: '',
    // contact_phone1: '',
    // contact_phone2: '',
    contact_profile_pic: [],
    hotel_id: '',
    country_id: '',
    province_id: '',
    country: '',
    city_name: '',
    province_name: '',
    address: '',
    check_in: '',
    check_out: '',
    price_range: ''
  })

  const [error, setError] = useState({
    min_price: '',
    max_price: ''
  })

  const [selectedAmenities, setSelectedAmenities] = useState([])
  const [isImageLoading, setIsImageLoading] = useState({
    index: null,
    loading: false
  })
  const [draggedIndex, setDraggedIndex] = useState(null)
  const [markerPosition, setMarkerPosition] = useState(null)
  const [currentStep, setCurrentStep] = useState(1)
  const [errors, setErrors] = useState('')
  const [isStep1Modified, setIsStep1Modified] = useState(false)
  const [isStep2Modified, setIsStep2Modified] = useState(false)
  const [isStep3Modified, setIsStep3Modified] = useState(false)
  const [isStep4Modified, setIsStep4Modified] = useState(false)
  const [isStep5Modified, setIsStep5Modified] = useState(false)
  const [isCountryList, setIsCountryList] = useState([])
  const [isProvinceList, setIsProvinceList] = useState([])
  const [isCityListData, setIsCityListData] = useState([])
  const [isHotelAmenitiesListData, setIsHotelAmenitiesListData] = useState([])
  const [imagesCleared, setImagesCleared] = useState(false)
  const [initialImages, setInitialImages] = useState(defaultData.images || [])
  const isEditMode = Object.keys(defaultData)?.length > 0
  const [isFieldChanged, setIsFieldChanged] = useState(false)

  const urlRegex =
    /^(https?|ftp):\/\/(([a-z\d]([a-z\d-]*[a-z\d])?\.)+[a-z]{2,}|localhost)(\/[-a-z\d%_.~+]*)*(\?[;&a-z\d%_.~+=-]*)?(\#[-a-z\d_]*)?$/i

  const clearValue = () => {
    setInputFields({
      ...inputFields,
      location_title: '',
      name: '',
      description: '',
      address: '',
      city_id: '',
      zip_code: '',
      longitude: '',
      latitude: '',
      website: '',
      amenities: [],
      min_price: '',
      max_price: '',
      logo: '',
      images: [],
      contact_name1: '',
      contact_name2: '',
      contact_designation1: '',
      contact_designation2: '',
      // contact_phone1: '',
      // contact_phone2: '',
      contact_profile_pic: [],
      hotel_id: '',
      country_id: '',
      province_id: '',
      country: '',
      city_name: '',
      province_name: ''
    })
    setIsFieldChanged(false)
  }

  const DataTrue = editHotel

  const handleNextClick = () => {
    if (currentStep === 2) {
      if (!inputFields.website || !urlRegex.test(inputFields.website)) {
        setErrors(English.G73)
        return
      }
    }
    if (currentStep < 4) {
      if (currentStep === 3 && editHotel) {
        setCurrentStep(4)
      } else {
        setCurrentStep(currentStep + 1)
      }
    } else {
      hotelAddApiCall()
    }
  }

  const handleBackClick = () => {
    if (currentStep > 1) {
      if (currentStep === 4 && editHotel) {
        setCurrentStep(3)
      } else {
        setCurrentStep(currentStep - 1)
      }
    }
  }

  const handleStartStep2 = () => {
    setErrors('')
    if (inputFields.website && !urlRegex.test(inputFields.website)) {
      setErrors(English.G73)
      return
    }
    hotelAmenitiesListData()
    setCurrentStep(2)
  }

  const handleCancelStep1 = () => {
    // setLocationDetails(false)
    onCancel()
  }

  const handleCancels = () => {
    setCurrentStep(0)
    onCancel()
    clearValue()
  }

  const validatePrices = (minPrice, maxPrice) => {
    if (!isNaN(minPrice) && !isNaN(maxPrice)) {
      if (maxPrice <= minPrice) {
        setError((prev) => ({
          ...prev,
          max_price: English.G410
        }))
      } else {
        setError((prev) => ({
          ...prev,
          max_price: ''
        }))
      }
    }
  }

  const handleKeyDown = (e) => {
    const value = e.target.value.trim()
    if (e.key === 'Enter' && value.length <= 4 && !value.includes('-')) {
      setInputFields((prev) => ({
        ...prev,
        price_range: `${value} - `
      }))
      e.preventDefault()
    }
  }

  const handleOnClick = (e) => {
    const { value } = e.target
    const dashIndex = value.indexOf('-')
    if (dashIndex > -1) {
      const { selectionStart } = e.target
      if (selectionStart <= dashIndex) {
        return
      }
      if (selectionStart > dashIndex + 2) {
        return
      }
      e.target.setSelectionRange(dashIndex + 2, dashIndex + 2)
    }
  }

  const onChange = (e) => {
    const { name, value } = e.target
    const cursorPosition = e.target.selectionStart

    if (name === 'price_range') {
      const cleanedValue = value.replace(/[^0-9-]/g, '')
      const priceParts = cleanedValue.split('-').map((p) => p.trim())
      if (!cleanedValue) {
        setInputFields((prev) => ({
          ...prev,
          price_range: '',
          min_price: '',
          max_price: ''
        }))
        return
      }

      if (priceParts.length === 1) {
        const min = priceParts[0]

        if (!min) {
          setInputFields((prev) => ({
            ...prev,
            price_range: '',
            min_price: ''
          }))
          return
        }

        if (min.length === 4 && !cleanedValue.includes('-')) {
          setInputFields((prev) => ({
            ...prev,
            price_range: `${min} - `,
            min_price: min
          }))
          setTimeout(() => e.target.setSelectionRange(min.length + 3, min.length + 3), 0)
        } else {
          setInputFields((prev) => ({
            ...prev,
            price_range: min,
            min_price: min
          }))
        }

        // setTimeout(() => e.target.setSelectionRange(min.length, min.length), 0)
      }

      if (priceParts.length === 2) {
        const min = priceParts[0]
        const max = priceParts[1]

        if (
          cursorPosition === min.length + 3 &&
          e.nativeEvent.inputType === 'deleteContentBackward'
        ) {
          setInputFields((prev) => ({
            ...prev,
            price_range: min,
            min_price: min
          }))

          setTimeout(
            () => e.target.setSelectionRange(min.length, min.length),
            0
          )
          return
        }

        const minPrice = min.slice(0, 4)
        const maxPrice = max.slice(0, 4)

        setInputFields((prev) => ({
          ...prev,
          min_price: minPrice,
          max_price: maxPrice,
          price_range: maxPrice
            ? `${minPrice} - ${maxPrice}`
            : `${minPrice} - `
        }))

        validatePrices(parseFloat(minPrice), parseFloat(maxPrice))

        if (max) {
          setTimeout(() => e.target.setSelectionRange(cursorPosition, cursorPosition), 0)
        } else {
          setTimeout(() => e.target.setSelectionRange(min.length + 3, min.length + 3), 0)
        }
      }
    } else {
      setInputFields((prev) => ({
        ...prev,
        [name]: value
      }))

      if (name === 'website' && urlRegex.test(value)) {
        setErrors('')
      }
    }
    setIsFieldChanged(true)
  }

  const onUploadImage = async (file, fieldName, index) => {
    if (file) {
      const reader = new FileReader()
      reader.onload = async () => {
        try {
          const imageFile = await compressImage(file)
          const coverFormData = new FormData()
          coverFormData.append('file', imageFile)

          if (fieldName === 'contact_profile_pic') {
            coverFormData.append('type', 'hotel_contact')
          } else if (fieldName === 'logo') {
            coverFormData.append('type', 'hotel_logo')
          } else {
            coverFormData.append('type', 'hotel')
          }

          try {
            const res = await APICall(
              'post',
              coverFormData,
              '',
              'imageUploadLocal',
              true
            )
            if (res.status === 200 && res.data?.code === 200) {
              const decryptedImageData = dataDecrypt(res.data.data)
              setInputFields((prev) => {
                if (fieldName === 'logo') {
                  return {
                    ...prev,
                    [fieldName]: decryptedImageData
                  }
                }
                const prevData = prev[fieldName] || []
                const updatedData = [...prevData]

                if (
                  index !== undefined &&
                  index !== null &&
                  Array.isArray(prevData)
                ) {
                  updatedData[index] = decryptedImageData
                } else {
                  updatedData.push(decryptedImageData)
                }

                return {
                  ...prev,
                  [fieldName]: updatedData
                }
              })
              setIsStep5Modified(true)
              setIsFieldChanged(true)
            } else {
              toast.error(res?.data?.message || 'Error uploading image')
            }
          } catch (err) {
            toast.error(err?.response?.data?.message || 'Upload failed')
          }
        } catch (error) {
          console.error('Error uploading image:', error)
        }
      }

      reader.readAsDataURL(file)
    }
  }

  const handleMapPositionChange = (lat, lng) => {
    setMarkerPosition({ lat, lng })
    setInputFields((prev) => ({
      ...prev,
      latitude: lat,
      longitude: lng
    }))
  }

  const toggleAmenitySelection = (amenitiesId) => {
    let updatedAmenities

    if (selectedAmenities.includes(amenitiesId)) {
      updatedAmenities = selectedAmenities.filter((id) => id !== amenitiesId)
    } else {
      updatedAmenities = [...selectedAmenities, amenitiesId]
    }

    setSelectedAmenities(updatedAmenities)

    setInputFields({
      ...inputFields,
      amenities: updatedAmenities
    })
  }

  // api call

  const provinceListData = (data) => {
    APICall('post', data, `${EndPoints.provinceList}`, 'local')
      .then((res) => {
        if (res.status === 200 && res.data?.code === 200) {
          setIsProvinceList(res?.decryptedData)
        } else {
          toast.error(res?.data?.message)
        }
      })
      .catch((error) => {
        toast.error(error?.data?.message)
      })
  }

  const cityListData = (data) => {
    APICall('post', data, `${EndPoints.cityList}`, 'local')
      .then((res) => {
        if (res.status === 200 && res.data?.code === 200) {
          setIsCityListData(res?.decryptedData.data)
        } else {
          toast.error(res?.data?.message)
        }
      })
      .catch((error) => {
        toast.error(error?.data?.message)
      })
  }

  const hotelAmenitiesListData = () => {
    APICall('post', {}, `${EndPoints.hotelAmenities}`, 'local')
      .then((res) => {
        if (res.status === 200 && res.data?.code === 200) {
          setIsHotelAmenitiesListData(res?.decryptedData)
        } else {
          toast.error(res?.data?.message)
        }
      })
      .catch((error) => {
        toast.error(error?.data?.message)
      })
  }

  const hotelAddApiCall = () => {
    let payload = {
      location_title: inputFields.location_title,
      name: inputFields.name,
      description: inputFields.description,
      address: inputFields.address,
      city_id: inputFields.city_id,
      zip_code: inputFields.zip_code,
      longitude: inputFields.longitude,
      latitude: inputFields.latitude,
      website: inputFields.website,
      amenities: inputFields.amenities,
      min_price: inputFields.min_price,
      max_price: inputFields.max_price,
      logo: inputFields.logo,
      images: inputFields.images,
      contact_name: [
        inputFields.contact_name1,
        inputFields.contact_name2
      ].filter((item) => item !== '' && item !== null),
      contact_designation: [
        inputFields.contact_designation1,
        inputFields.contact_designation2
      ].filter((item) => item !== '' && item !== null),
      // contact_phone: [
      //   inputFields.contact_phone1,
      //   inputFields.contact_phone2
      // ].filter((item) => item !== '' && item !== null),
      contact_profile_pic: inputFields.contact_profile_pic,
      hotel_id: inputFields.hotel_id,
      ...(isFromHotelComponent && { app_id: [platformData[0]] })
    }

    payload = Object.fromEntries(
      Object.entries(payload).filter(
        ([key, value]) => !(
            value === '' ||
            value === undefined ||
            (Array.isArray(value) && value.length === 0)
          )
      )
    )

    if (Object.keys(defaultData).length > 0) {
      APICall('post', payload, `${EndPoints.editHotel}`, 'local')
        .then((res) => {
          if (res.status === 200 && res.data?.code === 200) {
            toast.success(res?.data?.message)
            // particularHotelApiCall(inputFields.hotel_id)
            setTimeout(() => {
              emitter.emit(EventType.particularHotel, {
                action: 'edit'
              })
              apiSuccess()
            }, 3000)
            setCurrentStep(0)
            setIsShowHotelList(true)
            setIsShow(false)
          } else {
            toast.error(res?.data?.message)
          }
        })
        .catch((error) => {
          toast.error(error?.data?.message)
        })
    } else {
      APICall('post', payload, `${EndPoints.addHotel}`, 'local')
        .then((res) => {
          if (res.status === 200 && res.data?.code === 200) {
            setTimeout(() => {
              emitter.emit(EventType.addHotelList, {
                action: 'add'
              })
              apiSuccess()
              if (isFromHotelComponent) {
                navigate(`/local-builder/${id}`)
              } else {
                navigate('/local')
              }
            }, 3000)
            setCurrentStep(0)
            toast.success(res?.data?.message)
          } else {
            toast.error(res?.data?.message)
          }
        })
        .catch((error) => {
          toast.error(error?.data?.message)
        })
    }
  }

  const removeImage = (index) => {
    const updatedImages = [...inputFields.images]
    updatedImages.splice(index, 1)
    setInputFields((prev) => ({
      ...prev,
      images: updatedImages
    }))
    setIsStep5Modified(true)
  }

  const handleDragStart = (index) => {
    setDraggedIndex(index)
  }

  const handleDrop = (index) => {
    if (index !== draggedIndex) {
      setInputFields((prev) => {
        const copy = [...prev.images]
        const b = copy[draggedIndex]
        copy[draggedIndex] = copy[index]
        copy[index] = b
        return {
          ...prev,
          images: copy
        }
      })

      setDraggedIndex(null)
    }
  }

  const handleLoad = (index) => {
    setIsImageLoading({
      index,
      loading: true
    })
    setTimeout(() => {
      setIsImageLoading({
        index: null,
        loading: false
      })
    }, 500)
  }

  const renderImg = useMemo(() => {
    return (
      <>
        {inputFields.images.map((image, index) => {
          return (
            <div
              className={`image position-relative !shadow-none ${
                index === 0 ? 'first-image' : ''
              }`}
              key={index}
              alt={`image-${index}`}
              draggable
              onDragStart={() => {
                handleDragStart(index)
              }}
              onDragOver={(event) => event.preventDefault()}
              onDragEnter={(event) => event.preventDefault()}
              onDrop={(event) => {
                event.preventDefault()
                handleDrop(index)
              }}
            >
              {isImageLoading.index === index && isImageLoading.loading && (
                <Loader />
              )}
              <img
                className={`image position-relative !shadow-none ${
                  index === 0 ? 'first-image' : ''
                }`}
                src={
                  typeof image === 'string'
                    ? s3ImageHotel + image
                    : URL.createObjectURL(image)
                }
                alt={`image-${index}`}
                style={{
                  width: '100%',
                  height: '100%',
                  objectFit: 'cover'
                }}
                onLoad={() => handleLoad(index)}
              />
              <button
                className="image_remove_icon"
                type="button"
                onClick={() => removeImage(index)}
              >
                <img src={Images.RemoveLocal} alt="" />
              </button>
              <button type="button" className="plus-img">
                <CommonImageUpload
                  handleImageChange={(file) => {
                    onUploadImage(file, 'images', index)
                  }}
                  selectedImg=""
                  defaultImg={Images.plusimg}
                  className="plsimg"
                />
              </button>
            </div>
          )
        })}
      </>
    )
  }, [inputFields.images, handleDragStart])

  useEffect(() => {
    if (Object.keys(defaultData).length > 0) {
      setInputFields(defaultData)
      provinceListData({
        country_id: defaultData.country_id
      })
      cityListData({
        province_id: defaultData.province_id
      })
      setSelectedAmenities(defaultData.amenities)
    }
  }, [defaultData])

  useEffect(() => {
    setIsStep1Modified(
      inputFields.location_title !== defaultData.location_title ||
        inputFields.country_id !== defaultData.country_id ||
        inputFields.province_id !== defaultData.province_id ||
        inputFields.city_id !== defaultData.city_id ||
        inputFields.zip_code !== defaultData.zip_code
    )
    setIsStep2Modified(
      inputFields.logo !== defaultData.logo ||
        inputFields.name !== defaultData.name ||
        inputFields.price_range !== defaultData.price_range ||
        inputFields.min_price !== defaultData.min_price ||
        inputFields.max_price !== defaultData.max_price ||
        inputFields.description !== defaultData.description ||
        inputFields.website !== defaultData.website
    )
    setIsStep3Modified(inputFields.amenities !== defaultData.amenities)
    setIsStep4Modified(
      inputFields.contact_profile_pic !== defaultData.contact_profile_pic ||
        inputFields.contact_name1 !== defaultData.contact_name1 ||
        inputFields.contact_name2 !== defaultData.contact_name2 ||
        inputFields.contact_designation1 !== defaultData.contact_designation1 ||
        inputFields.contact_designation2 !== defaultData.contact_designation2 ||
        // inputFields.contact_phone1 !== defaultData.contact_phone1 ||
        // inputFields.contact_phone2 !== defaultData.contact_phone2
        inputFields?.images?.length !== defaultData?.images?.length
    )
    // setIsStep5Modified(
    //   inputFields?.images?.length !== defaultData?.images?.length
    // )
  }, [inputFields])

  const isStep1Valid = () => {
    return (
      (!isEditMode ? isStep1Modified : isEditMode) &&
      inputFields.location_title &&
      inputFields.country_id &&
      inputFields.province_id &&
      inputFields.city_id &&
      inputFields?.zip_code?.length > 4 &&
      markerPosition
    )
  }

  const isStep2Valid = () => {
    return (
      (!isEditMode ? isStep2Modified : isEditMode) &&
      inputFields.logo &&
      inputFields.name &&
      inputFields.price_range &&
      inputFields.min_price &&
      inputFields.max_price &&
      inputFields.description &&
      inputFields.website &&
      error.max_price === ''
    )
  }

  const isStep3Valid = () => {
    return (!isEditMode ? isStep3Modified : isEditMode) && inputFields.amenities.length > 0
  }

  const isStep4Valid = () => {
    const hasImagesChanged =
      JSON.stringify(inputFields.images) !== JSON.stringify(initialImages)
    return (
      isStep4Modified &&
      inputFields.images.length > 0 &&
      hasImagesChanged &&
      (editHotel ||
        (inputFields.contact_profile_pic.length === 2 &&
          inputFields.contact_name1 &&
          inputFields.contact_name2 &&
          inputFields.contact_designation1 &&
          // inputFields.contact_phone1.length > 10 &&
          // inputFields.contact_phone2.length > 10
          inputFields.contact_designation2))
    )
  }

  useEffect(() => {
    if (inputFields.latitude && inputFields.longitude) {
      setMarkerPosition({
        lat: Number(inputFields.latitude),
        lng: Number(inputFields.longitude)
      })
    }
  }, [inputFields.latitude, inputFields.longitude])

  const clearCurrentStepData = (step) => {
    switch (step) {
      // case 2:
      //   setInputFields({
      //     ...inputFields,
      //     logo: '',
      //     name: '',
      //     price_range: '',
      //     min_price: '',
      //     max_price: '',
      //     description: ''
      //   })
      //   break
      case 3:
        if (!editHotel) {
          setSelectedAmenities([])
        } else {
          setSelectedAmenities(defaultData.amenities)
        }
        break
      case 4:
        setInputFields({
          ...inputFields,
          contact_name1: '',
          contact_designation1: '',
          // contact_phone1: '',
          contact_name2: '',
          contact_designation2: '',
          // contact_phone2: '',
          contact_profile_pic: ['', '']
        })
        const hasImages = inputFields.images?.length > 0
        setInputFields({
          ...inputFields,
          images: []
        })
        if (hasImages) {
          setImagesCleared(true)
        } else {
          hotelAddApiCall()
        }
        break
      // case 5:
      //   const hasImages = inputFields.images?.length > 0
      //   setInputFields({
      //     ...inputFields,
      //     images: []
      //   })
      //   if (hasImages) {
      //     setImagesCleared(true)
      //   } else {
      //     hotelAddApiCall()
      //   }
      //   break
      default:
        break
    }
  }

  const handleSkipClick = () => {
    if (currentStep >= 2 && currentStep <= 4) {
      clearCurrentStepData(currentStep)
      if (currentStep === 3) {
        if (!editHotel) {
          setSelectedAmenities([])
        } else {
          setSelectedAmenities(defaultData.amenities)
        }
      }
      if (currentStep === 3 && editHotel) {
        setCurrentStep(4)
      } else if (currentStep < 4) {
        setCurrentStep(currentStep + 1)
      }
    }
  }

  useEffect(() => {
    if (imagesCleared) {
      hotelAddApiCall()
      setImagesCleared(false)
    }
  }, [imagesCleared])

  const isStep5Valid = () => {
    const hasImagesChanged =
      JSON.stringify(inputFields.images) !== JSON.stringify(initialImages)

    return hasImagesChanged && inputFields.images.length > 0
  }
  useEffect(() => {
    setInitialImages(defaultData.images || [])
  }, [])

  return (
    <div className="content-manager">
      {currentStep === 1 && (
        <LocationPopup
          inputFields={inputFields}
          setInputFields={setInputFields}
          onCancel={handleCancelStep1}
          onSubmit={handleStartStep2}
          countryList={isCountryList}
          provinceList={isProvinceList}
          cityList={isCityListData}
          locationSuggestion={locationSuggestion}
          onMapPositionChange={handleMapPositionChange}
          markerPosition={markerPosition}
          defaultData={defaultData}
          isEditMode={isEditMode}
          disabled={!isStep1Valid()}
        />
      )}

      {currentStep === 2 && (
        <DetailsPopup
          inputFields={inputFields}
          errors={errors}
          error={error}
          text="Hotel"
          s3Image={s3ImageHotel}
          handleKeyDown={handleKeyDown}
          handleOnClick={handleOnClick}
          validatePrices={validatePrices}
          handleBackClick={handleBackClick}
          handleCancels={handleCancels}
          onChange={onChange}
          onUploadImage={onUploadImage}
          onCancel={handleSkipClick}
          onSubmit={handleNextClick}
          defaultData={defaultData}
          isEditMode={isEditMode}
          disabled={!isStep2Valid()}
        />
      )}

      {currentStep === 3 && (
        <div className="add-item-model">
          <div className="add-item-content local_type_modal !max-w-[1200px] !m-5">
            <div className="flex items-center justify-between  local_sticky_header">
              {/* {step === 0 && <div>Select local type</div>} */}
              <div className="text-Main text-base italic font-black uppercase flex items-center !gap-3">
                <img
                  className="cursor-pointer"
                  onClick={handleBackClick}
                  src={Images.BackAerrow}
                  alt=""
                />
                Add Location
              </div>
              <button
                className="cancel_icon border-0 p-0"
                type="button"
                onClick={handleCancels}
              >
                <img src={Images.RemoveLocal} alt="" />
              </button>
            </div>
            <div className="flex items-center justify-between bg-[#E4E4E480] rounded-[10px] py-2.5 !px-5 mt-3">
              <div className="text-main test-base font-bold">Hotel Details</div>
              <div className="bg-primaryGradient bg-clip-text text-transparent text-sm flex gap-1 items-end leading-none">
                <span className="font-black text-xl leading-none italic">
                  04
                </span>
                of 04
              </div>
            </div>
            <div className="!mt-5 rounded-[15px] border border-BorderLight !p-5 local_contact_scroll_bars">
              <h6 className="font-bold text-base text-Secondary leading-none !mb-5">
                Amenities
              </h6>
              <div className="tool-option drop-search-3-0 !mb-5">
                <CommonInput
                  className="w-100 search-input disabledDropdown"
                  placeholder="Search..."
                />
              </div>
              <div className="amenities_name">
                {isHotelAmenitiesListData.map((amenity) => (
                  <div
                    key={amenity.amenities_id}
                    className={`type_amenities cursor-pointer ${selectedAmenities.includes(amenity.amenities_id) ? 'selected' : ''}`}
                    onClick={() => toggleAmenitySelection(amenity.amenities_id)}
                  >
                    <img
                      className="hotel_amenities"
                      src={s3ImageLocalIcon + amenity.icon}
                      alt={amenity.name}
                    />
                    <h6 className="text-Main text-sm font-semibold">
                      {amenity.name}
                    </h6>
                  </div>
                ))}
              </div>
            </div>
            <div className="local_button_row">
              <CommonButton
                label={English.G333}
                onClick={handleSkipClick}
                isPrimary={false}
                context={English.A197}
              />
              <CommonButton
                label={English.G69}
                type="button"
                isPrimary
                onClick={handleNextClick}
                context={English.A197}
                disabled={!isStep3Valid()}
              />
            </div>
          </div>
        </div>
      )}

      {currentStep === 4 && (
        <ContactImagePopup
          text="Hotel"
          s3Image={s3ImageHotel}
          handleBackClick={handleBackClick}
          handleCancels={handleCancels}
          onChange={onChange}
          renderImg={renderImg}
          editFrom={DataTrue}
          contactImage0={inputFields.contact_profile_pic[0]}
          contactImage1={inputFields.contact_profile_pic[1]}
          contactName1={inputFields.contact_name1}
          contactName2={inputFields.contact_name2}
          contactDesignation1={inputFields.contact_designation1}
          contactDesignation2={inputFields.contact_designation2}
          ImagesData={inputFields?.images}
          onUploadImage={onUploadImage}
          onCancel={handleSkipClick}
          onSubmit={handleNextClick}
          defaultData={defaultData}
          isEditMode={isEditMode}
          disabled={
            isEditMode ? !isFieldChanged : !isStep4Valid() || !isStep5Valid()
          }
        />
      )}
    </div>
  )
}
export default hotelPopup
