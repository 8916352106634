import React, { useState } from 'react'
import Skeleton from 'react-loading-skeleton'
import { useSelector } from 'react-redux'
import English from '../../../helpers/English'
import CommonButton from '../../ButtonComponent/ButtonCommon'
import { Images } from '../../../helpers'

const SelectApplications = ({
  isAppLoading,
  appData,
  setAppData,
  nextStep,
  prevStep,
  UserDataRaw,
  urlSystemIcon
}) => {
  const [modalData, setModalData] = useState(null)
  const userType = useSelector((state) => state.user?.user_type)

  const openModal = (app) => {
    setModalData(app)
  }

  const closeModal = () => {
    setModalData(null)
  }

  return (
    <div className="rounded-3xl p-[30px] bg-white h-100">
      <div className="flex justify-between">
        <div>
          <h1 className="business_type_header_text italic text-3xl font-black pr-1">
            {userType === 'business' ? English.A96 : English.B60}
          </h1>
          <p className="sub-text-2 font-medium text-[15px] text-[#303030]">
            {userType === 'business' ? English.A95 : English.B59}
          </p>
        </div>
        <div className="flex items-baseline gap-3">
          {!UserDataRaw?.user_type_id && UserDataRaw?.user_type_id === 0 && userType === 'business' && (
            <CommonButton
              label={English.A116}
              type="button"
              onClick={prevStep}
              className="btn-prev secondary-btns w-auto"
              context="secondary-btn"
            />
          )}
          <CommonButton
            label={English.G69}
            type="button"
            onClick={nextStep}
            className="primary-btns btn-next"
            disabled={appData.filter(({ checked }) => checked).length === 0}
            context="primary-btn"
            isPrimary
          />
        </div>
      </div>
      <div className="border border-[##E4E4E4]" />
      <div className="step-2 flex gap-6 py-8">
        {isAppLoading ? (
          <div
            className="w-[265.5px] h-auto p-3 rounded-2xl bg-white"
          >
            <div className="flex flex-col gap-3">
              <div>
                <div className="flex items-center justify-between mb-4">
                  <div className="flex items-center gap-2">
                    <Skeleton width={55} height={55} />
                  </div>
                  <div className="flex-row items-center gap-2">
                    <Skeleton width={120} height={20} />
                    <Skeleton width={80} height={20} />
                  </div>
                  <div className="">
                    <div className="flex items-center">
                      <Skeleton width={30} height={30} circle />
                    </div>
                  </div>
                </div>
                <Skeleton width={210} height={10} />
                <Skeleton width={210} height={10} />
                <Skeleton width={210} height={10} />
              </div>
              <div
                className="flex justify-between items-center border-t border-[#D4D4D4] border-dashed cursor-pointer"
              >
                <div
                  className="text-[#0B0A0A] font-extrabold text-base underline italic mt-2"
                  type="button"
                >
                  <Skeleton width={100} height={25} />
                </div>
                <Skeleton width={30} height={25} />
              </div>
            </div>
          </div>
        ) : (
          appData.map((i, index) => (
            <div
              key={i.id}
              className={`w-[265.5px] h-auto p-3 rounded-2xl bg-white ${
                i.isSelected ? 'selected' : 'selectable'
              }`}
              onClick={() => {
                const newCheckboxes = [...appData]
                newCheckboxes[index].isSelected =
                  !newCheckboxes[index].isSelected
                newCheckboxes[index].checked = newCheckboxes[index].isSelected
                setAppData(newCheckboxes)
              }}
            >
              <div className="flex flex-col gap-3">
                <div>
                  <div className="flex items-center justify-between mb-4">
                    <div className="flex items-center gap-[10px]">
                      <div className="flex items-center gap-2">
                        <img src={urlSystemIcon + i.new_icon} alt="" />
                      </div>
                      <div className="flex-row items-center gap-2">
                        <h3 className="text-lg font-semibold mb-0">{i.description}</h3>
                        <p className="text-sm text-gray-600 mb-0">PRO APP</p>
                      </div>
                    </div>
                    <div className="">
                      <div className="flex items-center">
                        <input
                          id="inline-2-checkbox"
                          type="checkbox"
                          value=""
                          className="!w-6 !h-6 text-blue-600 bg-gray-100 border-gray-300 !rounded-full dark:bg-gray-700 dark:border-gray-600"
                          checked={i.checked}
                          onChange={(e) => {
                            const newCheckboxes = [...appData]
                            newCheckboxes[index].checked = e.target.checked
                            newCheckboxes[index].isSelected = e.target.checked
                            setAppData(newCheckboxes)
                          }}
                          onClick={(e) => e.stopPropagation()}
                        />
                      </div>
                    </div>
                  </div>
                  <p className="text-xs font-medium text-[#303030]">
                    Lorem ipsum dolor sit amet, cons ectetur adipiscing elit.
                    Amet et lobortis at.
                  </p>
                </div>
                <div
                  className="flex justify-between items-center border-t border-[#D4D4D4] border-dashed cursor-pointer"
                  onClick={() => openModal(i)}
                >
                  <div
                    className="text-[#0B0A0A] font-extrabold text-base underline italic mt-2"
                    type="button"
                  >
                    {English.A97}
                  </div>
                  <img
                    className="ml-1 mt-2"
                    src={Images.arrow_left_black}
                    alt="arrow"
                  />
                </div>
              </div>
            </div>
          ))
        )}
      </div>
      {modalData && (
        <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex justify-center items-center">
          <div className="bg-white rounded-xl w-[800px] p-6 relative  ">
            <button
              onClick={closeModal}
              type="button"
              className="absolute top-4 right-4 text-gray-500 hover:text-gray-800"
            >
              <img
                src={Images.Close_Icon_New}
                alt="Close Icon"
                className="w-5 h-5 mt-[15px] mr-[12px]"
              />
            </button>
            <div className="flex gap-6">
              <div className="w-1/2 flex justify-center items-center bg-[#F9F9F9] rounded-lg">
                <div className="bg-blue-500 w-12 h-12 flex justify-center items-center rounded-full">
                  <img
                    src={Images.Play_Blue_Icon}
                    alt="Play Icon"
                    className="w-12 h-12"
                  />
                </div>
              </div>
              <div className="w-1/2">
                <div className="mb-2">
                  <img
                    src={urlSystemIcon + modalData.icon}
                    alt="App Icon"
                    className="w-12 h-12 mb-2"
                  />
                  <div>
                    <h2 className="text-2xl font-extrabold italic text-[#751E67] mb-0">
                      {modalData.description}
                    </h2>
                    <p className="text-sm text-[#242f86] italic font-bold">
                      LITE APP
                    </p>
                  </div>
                </div>
                <p className="text-[#0B0A0A] font-normal text-base">
                  Lorem ipsum has been the industrys standard dummy text ever
                  since the 1500s, when an unknown printer took a galley of type
                  and scrambled it to make a type specimen book.
                </p>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default SelectApplications
