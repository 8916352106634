/* eslint-disable max-len */
/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable no-nested-ternary */
/* eslint-disable arrow-parens */
import React, { useEffect, useState } from 'react'
import Skeleton from 'react-loading-skeleton'
import './header.css'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import { Dropdown, Menu } from 'antd'
import { toast } from 'react-toastify'
import { s3ImageProfile } from '../../services/Service'
import CommonInput from '../InputComponet/CommonInput'
import Images from '../../helpers/Images'
import { English } from '../../helpers'
import { UserType } from '../../helpers/Constant'
import Utility from '../../helpers/Utility'
import { setUserSliceData } from '../../services/http-services'
import { Logout, removeToken } from '../../redux/UserSlice/UserSlice'

const Header = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const UserData = useSelector((state) => state.user?.userData)
  const stayLogin = useSelector((state) => state.user.stay_loginData)
  const user_type = useSelector((state) => state.user?.user_type)
  const [isLoading, setIsLoading] = useState(false)

  const location = useLocation()

  const isProfileCreationPage =
    location.pathname === '/creator-create-profile' ||
    location.pathname === '/create-profile'

  const handleButtonClick = () => {
    navigate('/dashboard/smart-hub')
  }
  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false)
    }, 500)
  }, [])
  const [dropdownVisible, setDropdownVisible] = useState(false)

  const changeUserType = (type) => {
    const userData = Utility.deepClone(UserData)
    const data_data =
    type === UserType.Creator
      ? userData?.creator_data
      : type === UserType.Business
        ? userData?.business_data
        : userData?.member_data
    let newObj = (user_type === UserType.Creator ? UserData.creator_data : user_type === UserType.Business ? UserData.business_data : UserData.creator_data)
    newObj = newObj || UserData.business_data || UserData.creator_data || UserData.member_data
    const result = Object.keys(UserData)
      .filter(key => !(key in newObj))
      .reduce((acc, key) => {
        acc[key] = UserData[key]
        return acc
      }, {})
    const userDataUpdated = { ...result, ...data_data }
    const typeData = (type === UserType.Creator ? userDataUpdated.creator_data : type === UserType.Business ? userDataUpdated.business_data : userDataUpdated.member_data)
    setUserSliceData(!!typeData, 'onBoardPage')
    setUserSliceData(userDataUpdated, 'userInfoNew')
    setUserSliceData(type, 'user_type')
    console.log(!!typeData, typeData)
    if (typeData === null || typeData.user_type_id === 0 || newObj === null || newObj.user_type_id === 0) {
      navigate('/', { replace: true })
    }
  }

  const LogoutClick = () => {
    dispatch(removeToken())
    toast.success('User logged out successfully')
    if (stayLogin) {
      navigate('/re-login')
    } else {
      dispatch(Logout())
      navigate('/login')
    }
  }

  const menu = (
    <Menu className="w-[240px] rounded-lg shadow-md">
      {user_type !== UserType.Member &&
      <Menu.Item onClick={() => changeUserType(UserType.Member)} key="1" className="header_item !flex !items-center !gap-2">
        <img src={Images.member_header_icon} alt="Switch to Member" />
        <span>{English.A188}</span>
      </Menu.Item>}
      {user_type !== UserType.Creator &&
      <Menu.Item onClick={() => changeUserType(UserType.Creator)} key="1" className="header_item !flex !items-center !gap-2">
        <img src={Images.creator} alt="Switch to Creator" />
        <span>{English.A192}</span>
      </Menu.Item>}
      {user_type !== UserType.Business &&
      <Menu.Item onClick={() => changeUserType(UserType.Business)} key="2" className="header_item flex items-center gap-2">
        <img src={Images.input_logo} alt="Switch to Business" />
        <span>{English.A189}</span>
      </Menu.Item>}
      <Menu.Item key="3" className="header_item flex items-center gap-2 disableSideMenu opacity-40">
        <img src={Images.wallete_header_icon} alt="Wallet" />
        <span>{English.A190}</span>
      </Menu.Item>
      <Menu.Item key="4" className="header_item flex items-center gap- disableSideMenu opacity-40">
        <img src={Images.Settings} alt="Settings" />
        <span>{English.G241}</span>
      </Menu.Item>
      <Menu.Item key="5" className="header_item flex items-center gap-2 disableSideMenu opacity-40">
        <img src={Images.ServiceCenter} alt="Service Center" />
        <span>{English.G240}</span>
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item onClick={LogoutClick} key="6" className="header_item flex items-center gap-2 text-red-500">
        <img src={Images.Logout} alt="Log Out" />
        <span>{English.A191}</span>
      </Menu.Item>
    </Menu>
  )

  const Header = () => {
    return (
      <>
        <div className="serch-input position-relative ml-5">
          <CommonInput
            disabled
            placeholder="Search..."
            className="max-[1550px]:!w-[182px]"
          />
          <div className="serch-icon">
            <img src={Images.SearchIco} alt="" />
          </div>
        </div>
        <div className="flex gap-[44px] items-center max-[1600px]:gap-[20px] max-[1440px]:hidden">
          <button
            type="button"
            className={`${(user_type === 'member' || user_type === 'business') && 'd-none'} right-button !w-auto py-[9px] px-[20px]`}
            disabled
          >
            <div className="flex gap-2">
              <img src={Images.File_Icon} alt="" />
              <p className="text-[#0B0A0A] text-sm font-medium mb-0">
                {English.A170}
              </p>
            </div>
          </button>
          <button
            type="button"
            className={`${(user_type === 'member' || user_type === 'business') && 'd-none'} right-button !w-auto py-[9px] px-[20px]`}
            disabled
          >
            <div className="flex gap-2">
              <img src={Images.File_Icon} alt="" />
              <p className="text-[#0B0A0A] text-sm font-medium mb-0">
                {English.A171}
              </p>
            </div>
          </button>
          <button
            type="button"
            className={`${(user_type === 'member' || user_type === 'business') && 'd-none'} right-button !w-auto py-[9px] px-[20px]`}
            disabled={isProfileCreationPage}
            onClick={handleButtonClick}
          >
            <div className="flex gap-2">
              <img src={Images.smart_hub_icon} alt="" />
              <p className="text-[#0B0A0A] text-sm font-medium mb-0">
                {English.A164}
              </p>
            </div>
          </button>
          <button
            type="button"
            className={`${(user_type === 'member' || user_type === 'business') && 'd-none'} right-button !w-auto py-[9px] px-[20px]`}
            disabled
          >
            <div className="flex gap-2">
              <img src={Images.ServiceCenter} alt="" />
              <p className="text-[#0B0A0A] text-sm font-medium mb-0">
                {English.A2}
              </p>
            </div>
          </button>
          <button
            type="button"
            className={`${(user_type === 'member' || user_type === 'creator') && 'd-none'} right-button !w-auto py-[9px] px-[20px]`}
            disabled
          >
            <div className="flex gap-2">
              <img src={Images.media_business_icon} alt="" />
              <p className="text-[#0B0A0A] text-sm font-medium mb-0">
                {English.A170}
              </p>
            </div>
          </button>
          <button
            type="button"
            className={`${(user_type === 'member' || user_type === 'creator') && 'd-none'} right-button !w-auto py-[9px] px-[20px]`}
            disabled
          >
            <div className="flex gap-2">
              <img src={Images.Recruiting_icon} alt="" />
              <p className="text-[#0B0A0A] text-sm font-medium mb-0">
                {English.A172}
              </p>
            </div>
          </button>
          <button
            type="button"
            className={`${(user_type === 'member' || user_type === 'creator') && 'd-none'} right-button !w-auto py-[9px] px-[20px]`}
            disabled
          >
            <div className="flex gap-2">
              <img src={Images.Crm_icon} alt="" />
              <p className="text-[#0B0A0A] text-sm font-medium mb-0">
                {English.A173}
              </p>
            </div>
          </button>
          <button
            type="button"
            className={`${(user_type === 'member' || user_type === 'creator') && 'd-none'} right-button !w-auto py-[9px] px-[20px]`}
            disabled
          >
            <div className="flex gap-2">
              <img src={Images.profileBuilder_icon} alt="" />
              <p className="text-[#0B0A0A] text-sm font-medium mb-0">
                {English.A2}
              </p>
            </div>
          </button>
        </div>
        <div className="header-right-bar d-flex align-items-center mr-5">
          <div className="relative min-[1440px]:hidden">
            <div
              className="dropdown-toggles relative bg-[#fafafa] rounded-[35px] h-[50px] cursor-pointer"
              onClick={() => setDropdownVisible((prev) => !prev)}
            >
              Menu
            </div>
            <div
              className={`flex flex-col gap-[20px] items-center bg-[#F9F9F9] shadow-md rounded-[16px] absolute w-full top-[calc(100%+20px)] transition-all ${dropdownVisible ? 'h-auto opacity-100' : 'h-0 pointer-events-none overflow-hidden opacity-0'}`}
            >
              <button
                type="button"
                className={`${(user_type === 'member' || user_type === 'business') && 'd-none'} right-button !w-auto py-[9px] px-[20px] hover:bg-white`}
                disabled
              >
                <div className="flex gap-2">
                  <img src={Images.File_Icon} alt="" />
                  <p className="text-[#0B0A0A] text-sm font-medium mb-0">
                    {English.A170}
                  </p>
                </div>
              </button>
              <button
                type="button"
                className={`${(user_type === 'member' || user_type === 'business') && 'd-none'} right-button !w-auto py-[9px] px-[20px] hover:bg-white`}
                disabled
              >
                <div className="flex gap-2">
                  <img src={Images.File_Icon} alt="" />
                  <p className="text-[#0B0A0A] text-sm font-medium mb-0">
                    {English.A171}
                  </p>
                </div>
              </button>
              <button
                type="button"
                className={`${(user_type === 'member' || user_type === 'business') && 'd-none'} right-button !w-auto py-[9px] px-[20px] hover:bg-white`}
                disabled={isProfileCreationPage}
                onClick={handleButtonClick}
              >
                <div className="flex gap-2">
                  <img src={Images.smart_hub_icon} alt="" />
                  <p className="text-[#0B0A0A] text-sm font-medium mb-0">
                    {English.A164}
                  </p>
                </div>
              </button>
              <button
                type="button"
                className={`${(user_type === 'member' || user_type === 'business') && 'd-none'} right-button !w-auto py-[9px] px-[20px] hover:bg-white`}
                disabled
              >
                <div className="flex gap-2">
                  <img src={Images.ServiceCenter} alt="" />
                  <p className="text-[#0B0A0A] text-sm font-medium mb-0">
                    {English.A2}
                  </p>
                </div>
              </button>
              <button
                type="button"
                className={`${(user_type === 'member' || user_type === 'creator') && 'd-none'} right-button !w-auto py-[9px] px-[20px] hover:bg-white`}
                disabled
              >
                <div className="flex gap-2">
                  <img src={Images.media_business_icon} alt="" />
                  <p className="text-[#0B0A0A] text-sm font-medium mb-0">
                    {English.A170}
                  </p>
                </div>
              </button>
              <button
                type="button"
                className={`${(user_type === 'member' || user_type === 'creator') && 'd-none'} right-button !w-auto py-[9px] px-[20px] hover:bg-white`}
                disabled
              >
                <div className="flex gap-2">
                  <img src={Images.Recruiting_icon} alt="" />
                  <p className="text-[#0B0A0A] text-sm font-medium mb-0">
                    {English.A172}
                  </p>
                </div>
              </button>
              <button
                type="button"
                className={`${(user_type === 'member' || user_type === 'creator') && 'd-none'} right-button !w-auto py-[9px] px-[20px] hover:bg-white`}
                disabled
              >
                <div className="flex gap-2">
                  <img src={Images.Crm_icon} alt="" />
                  <p className="text-[#0B0A0A] text-sm font-medium mb-0">
                    {English.A173}
                  </p>
                </div>
              </button>
              <button
                type="button"
                className={`${(user_type === 'member' || user_type === 'creator') && 'd-none'} right-button !w-auto py-[9px] px-[20px] hover:bg-white`}
                disabled
              >
                <div className="flex gap-2">
                  <img src={Images.profileBuilder_icon} alt="" />
                  <p className="text-[#0B0A0A] text-sm font-medium mb-0">
                    {English.A2}
                  </p>
                </div>
              </button>
            </div>
          </div>
          <button type="button" className="right-button" disabled>
            <img src={Images.Message} alt="" />
          </button>
          <button type="button" className="right-button" disabled>
            <img src={Images.Notification} alt="" />
          </button>
          <div className="w-[1px] h-9 bg-[#f1f1f1] rounded-3xl" />
          <Dropdown overlay={menu} trigger={['click']} className="right-button w-auto">
            <button className="dropdown-toggles flex items-center" id="navbarDropdown" type="button">
              <img
                className="object-cover object-center w-10 h-10 rounded-full"
                src={s3ImageProfile + UserData?.profile_image}
                alt="Profile"
              />
              <div className="flex flex-col items-start px-2 gap-[2px]">
                <span className="text-[12px] bg-primaryGradient bg-clip-text text-transparent leading-none font-medium">
                  {UserData?.full_name}
                </span>
                <span className="text-[10px] text-secondary leading-none">
                  {UserData?.email}
                </span>
              </div>
            </button>
          </Dropdown>
        </div>
      </>
    )
  }

  return (
    <div className="navbar-custom d-flex items-center justify-between">
      {isLoading ? (
        <>
          <div className="serch-input position-relative">
            <Skeleton className="rounded-3" width={329} height={39} />
          </div>
          <div className="header-right-bar d-flex align-items-center">
            <button type="button" className="right-button">
              <Skeleton width={25} height={25} />
            </button>
            <button type="button" className="right-button">
              <Skeleton width={25} height={25} />
            </button>
            <div className="profile-dropdown d-flex gap-2">
              <Skeleton className="rounded-3" width={30} height={30} />
              <Skeleton className="mt-2" width={15} height={15} />
            </div>
          </div>
        </>
      ) : (
        <Header />
      )}
    </div>
  )
}
export default Header
