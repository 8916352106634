/* eslint-disable eqeqeq */
/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react'
import './sidebar.css'
import { NavLink, useLocation, useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import Skeleton from 'react-loading-skeleton'
import { useDispatch, useSelector } from 'react-redux'
import English from '../../helpers/English'
import Images from '../../helpers/Images'
import { Logout, removeToken } from '../../redux/UserSlice/UserSlice'
import { APICall, EndPoints } from '../../services'

const Sidebar = () => {
  const UserData = useSelector((state) => state.user?.userData)
  const stayLogin = useSelector((state) => state.user.stay_loginData)
  const user_type = useSelector((state) => state.user?.user_type)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [isLoading, setIsLoading] = useState()
  const location = useLocation()
  const isSideMenuDisable = !UserData?.user_type_id

  // isSideMenuDisable ? navigate('/create-profile') : null
  if (isSideMenuDisable) {
    // navigate('/create-profile')
  }

  const isRestrictedPath =
    location.pathname === '/creator-create-profile' ||
    location.pathname === '/create-profile'

  const [isOpen, setIsOpen] = useState(false)
  const [userData, setUserData] = useState([])
  const toggleMenu = () => {
    setIsOpen(!isOpen)
  }
  const LogoutClick = () => {
    dispatch(removeToken())
    toast.success('User logged out successfully')
    if (stayLogin) {
      navigate('/re-login')
    } else {
      dispatch(Logout())
      navigate('/login')
    }
  }

  const getUserProfiles = async () => {
    APICall('get', '', `${EndPoints.userProfile}?type=${user_type}`, 'profile')
      .then((res) => {
        if (res.status === 200 && res.data?.code === 200) {
          setUserData(res?.decryptedData)
        } else {
          toast.error(res.data?.message)
        }
      })
      .catch((error) => {
        toast.error(error?.data?.message)
      })
  }

  const isMenuItemActive = (name) => {
    const allUserTypeNames = userData.flatMap(
      (user) => user.user_type_data?.map((d) => d.name) || []
    )
    return allUserTypeNames.includes(name)
  }

  useEffect(() => {
    setTimeout(() => {
      getUserProfiles()
      setIsLoading(false)
    }, 500)
  }, [UserData])

  const HeaderLogo = () => {
    return (
      <div className="flex gap-3 items-center">
        <img src={Images.GbnLogo} className="w-[38px] h-[38px]" alt="Alt" />
        <div className="flex-col flex items-center">
          <div className="bg-primaryGradient inline-block md:text-xs lg:text-sm leading-0 font-black pr-1 italic bg-clip-text text-transparent">
            {English.A1}
          </div>
          {user_type !== 0 && (
            <div className="bg-primaryGradient inline-block text-xs pr-1 font-normal leading-0 italic bg-clip-text text-transparent">
              {user_type === 'member'
                ? English.B57
                : user_type === 'creator'
                  ? English.A13
                  : English.A14}
            </div>
          )}
        </div>
      </div>
    )
  }

  return (
    <div className="custom-sidebar">
      {isLoading ? (
        <>
          <Skeleton className="rounded-5 mb-3" height={54} width={193} />
          <ul className="main-navigation mt-4">
            <Skeleton
              className="rounded-5 mb-3"
              height={54}
              width={193}
              count={9}
            />
          </ul>
        </>
      ) : (
        <>
          <div className="logo !flex-col d-block">
            <HeaderLogo />
          </div>
          <ul className="main-navigation flex-1 flex flex-col">
            <li className={isRestrictedPath ? 'disableSideMenu' : ''}>
              <NavLink to="/dashboard">
                <div className="home_profile">
                  <div className="image-block img-width">
                    <img src={Images.HomeNew} alt="" />
                  </div>
                  {English.G222}
                </div>
              </NavLink>
            </li>
            {user_type === 'member' && (
              <>
                <li className="">
                  <NavLink to="/about-me">
                    <div className="home_profile">
                      <div className="image-block img-width">
                        <img src={Images.Profile} alt="" />
                      </div>
                      {English.A133}
                    </div>
                  </NavLink>
                </li>
                <li className="">
                  <NavLink to="/friends">
                    <div className="home_profile">
                      <div className="image-block img-width">
                        <img src={Images.Friends_Icon} alt="" />
                      </div>
                      {English.A139}
                    </div>
                  </NavLink>
                </li>
              </>
            )}
            <li
              className={`${user_type === 'member' ? 'd-none' : ''} ${isRestrictedPath ? 'disableSideMenu' : ''}`}
            >
              <NavLink to="/profiles">
                <div className="home_profile">
                  <div className="image-block img-width">
                    <img src={Images.ProfileNew} alt="" />
                  </div>
                  {English.G232}
                </div>
              </NavLink>
            </li>
            <li
              className={`disableSideMenu ${user_type === 'member' && 'd-none'}`}
            >
              <NavLink to="/workspace">
                <div>
                  <div className="image-block img-width">
                    <img src={Images.Workspace} alt="" />
                  </div>
                  {English.G233}
                </div>
              </NavLink>
            </li>
            {(user_type === 'creator' || user_type === 'business') && (
              <div
                className={`font-black text-[10px] italic ${
                  isRestrictedPath ? 'text-placeholder' : 'text-[#0B0A0A]'
                }`}
              >
                {English.A174}
              </div>
            )}
            {user_type === 'member' && <div className="saprator" />}
            <li
              className={`${user_type === 'member' && 'd-none'} ${isMenuItemActive('Creator') ? '' : 'disableSideMenu'} ${isRestrictedPath ? 'disableSideMenu' : ''}`}
            >
              <NavLink to="/content-manager">
                <div>
                  <div className="image-block img-width">
                    <img src={Images.ContentManagerNew} alt="" />
                  </div>
                  {English.G234}
                </div>
              </NavLink>
            </li>
            <li
              className={`disableSideMenu ${user_type === 'member' && 'd-none'}`}
            >
              {/* className={isMenuItemActive(4) ? '' : 'disableSideMenu'} */}
              <NavLink to="/store">
                <div>
                  <div className="image-block img-width">
                    <img src={Images.MyStoreNew} alt="" />
                  </div>
                  {English.G235}
                </div>
              </NavLink>
            </li>
            <li
              className={`${user_type === 'member' && 'd-none'} ${isMenuItemActive('Local') ? '' : 'disableSideMenu'}`}
            >
              <div className="d-flex align-items-center justify-content-between">
                <NavLink to="/local" className="w-100">
                  <div className="position-relative">
                    <div className="image-block img-width">
                      <img src={Images.localNew} alt="" />
                    </div>
                    {English.A193}
                    <div
                      onClick={toggleMenu}
                      className="cursor-pointer image-block position-absolute"
                      type="button"
                    >
                      {/* <img src={Images.RightIco} alt="" /> */}
                    </div>
                  </div>
                </NavLink>
              </div>
              {/* {isOpen && (
                <ul className="custom-menu-options ms-4">
                  <li>
                    <NavLink to="/hotels">
                      <div>{English.G227}</div>
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="/restaurant">
                      <div>{English.G228}</div>
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="/event">
                      <div>{English.G229}</div>
                    </NavLink>
                  </li>
                </ul>
              )} */}
            </li>
            {(user_type === 'creator' || user_type === 'business') && (
              <div
                className={`font-black text-[10px] italic ${
                  isRestrictedPath ? 'text-placeholder' : 'text-[#0B0A0A]'
                }`}
              >
                {English.A175}
              </div>
            )}
            <li
              className={`disableSideMenu ${user_type === 'member' && 'd-none'}`}
            >
              <NavLink to="cloud-store">
                <div>
                  <div className="image-block img-width">
                    <img src={Images.CloudStore} alt="" />
                  </div>
                  {English.G237}
                </div>
              </NavLink>
            </li>
            <li
              className={`disableSideMenu ${user_type === 'member' && 'd-none'}`}
            >
              <NavLink to="/ads">
                <div>
                  <div className="image-block img-width">
                    <img src={Images.ADS} alt="" />
                  </div>
                  {English.G238}
                </div>
              </NavLink>
            </li>
            {user_type === 'member' && <div className="flex flex-1" />}
            {user_type === 'member' && <div className="saprator" />}
            {user_type === 'member' && (
              <div className="font-black text-[10px] text-placeholder italic">
                {English.A176}
              </div>
            )}
            <li
              className={`disableSideMenu ${(user_type === 'member' || user_type === 'creator' || user_type === 'business') && 'd-none'}`}
            >
              <NavLink to="/affiliate">
                <div>
                  <div className="image-block img-width">
                    <img src={Images.Affiliate} alt="" />
                  </div>
                  {English.G239}
                </div>
              </NavLink>
            </li>
            <li
              className={`disableSideMenu ${(user_type === 'creator' || user_type === 'business') && 'd-none'}`}
            >
              <NavLink to="/service-center">
                <div>
                  <div className="image-block img-width">
                    <img src={Images.ServiceCenter} alt="" />
                  </div>
                  {English.G240}
                </div>
              </NavLink>
            </li>
            <li
              className={`disableSideMenu ${(user_type === 'creator' || user_type === 'business') && 'd-none'}`}
            >
              <NavLink to="/settings">
                <div>
                  <div className="image-block img-width">
                    <img src={Images.Settings} alt="" />
                  </div>
                  {English.G241}
                </div>
              </NavLink>
            </li>
            <li className="">
              <NavLink
                to="/logout"
                onClick={(e) => {
                  e.preventDefault()
                  LogoutClick()
                }}
              >
                <div>
                  <div className="image-block img-width">
                    <img src={Images.Logout} alt="" />
                  </div>
                  {English.G242}
                </div>
              </NavLink>
            </li>
            <p>
              <p className="mb-[4px] text-placeholder font-normal text-[11px]">
                PART OF
              </p>
              <div className="flex gap-[5px] border p-2 items-center rounded-xl">
                <div className="">
                  <img
                    className="!w-[30px] !h-[30px]"
                    src={Images.Withe_bg_logo}
                    alt=""
                  />
                </div>
                <p className="text-gradient font-bold italic text-[12px] m-0">
                  {English.A57}
                </p>
              </div>
            </p>
          </ul>
        </>
      )}
    </div>
  )
}
export default Sidebar
