import React from 'react'
import CommonButton from '../ButtonComponent/ButtonCommon'
import { English, Images } from '../../helpers'
import Loader from '../Loader/Loader'
import CommonImageUpload from '../ImageCroper/StoreImageCroper'
import CommonInput from '../InputComponet/CommonInput'
import { capitalizeFirstLetter } from '../../helpers/Utility'

const ContactModal = ({
    contactLoading,
    cancelContact,
    onUploadImage,
    inputFields,
    s3Image,
    onChange,
    nextClickApiCall,
    disabled
}) => {
  return (
    <div className="add-item-model">
      {contactLoading && <Loader />}
      <div className="add-item-content local_type_modal">
        <div className="type_close mb-2 local_sticky_header">
          <div className="text-Main text-base italic font-black uppercase flex items-center !gap-3">
            {English.G289}
          </div>
          <button
            className="cancel_icon"
            type="button"
            onClick={cancelContact}
          >
            <img src={Images.RemoveLocal} alt="" />
          </button>
        </div>
        <div className="item-details-input pt-0 local_contact_scroll_bars !overflow-hidden">
          <div className="logo_btn">
            <div className="image_upload_section">
              <div className="add_photos mb-4 mt-2">
                <div className="add_logo_text text-center align-self-center">
                  <CommonImageUpload
                    name="local_upload_logo"
                    handleImageChange={(file) => onUploadImage(file, 'contact_profile_pic')}
                    index={0}
                    forImage={English.G290}
                    className="profile-upload"
                    selectedImg={
                          inputFields?.contact_profile_pic
                            ? `${s3Image}${inputFields?.contact_profile_pic}`
                            : ''
                        }
                    defaultImg={Images.local_add_img}
                  />
                </div>
              </div>
            </div>
            <div className="person_detail_section">
              <div className="input-grid">
                <div>
                  <label>{English.G130}</label>
                  <CommonInput
                    placeholder={English.G130}
                    onChange={onChange}
                    name="contact_name"
                    value={capitalizeFirstLetter(inputFields?.contact_name)}
                    allowTextOnly
                  />
                </div>
                <div>
                  <label>{English.G291}</label>
                  <CommonInput
                    placeholder={English.G291}
                    onChange={onChange}
                    name="contact_designation"
                    value={capitalizeFirstLetter(
                          inputFields?.contact_designation
                        )}
                    allowTextOnly
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="local_button_row">
          <CommonButton
            label={English.G116}
            onClick={cancelContact}
            isPrimary={false}
            context={English.A197}
          />
          <CommonButton
            label={English.G69}
            type="button"
            isPrimary
            onClick={nextClickApiCall}
            disabled={disabled}
            context={English.A197}
          />
        </div>
      </div>
    </div>
  )
}

export default ContactModal
