/* eslint-disable no-nested-ternary */
/* eslint-disable no-undef */
/* eslint-disable eqeqeq */
/* eslint-disable no-useless-escape */
/* eslint-disable max-len */
import React, { useState, useEffect, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import { APICall, EndPoints } from '../../services'
import { English, Images } from '../../helpers'
import {
  compressImage,
  dataDecrypt,
  setUserSliceData
} from '../../services/http-services'
import { s3ImageProfile, urlSystemIcon } from '../../services/Service'
import CommonImageUpload from '../ImageCroper/StoreImageCroper'
import SelectApplications from './CreateProfileScreen/SelectApplicationsPage'
import ProfileVerification from './CreateProfileScreen/ProfilePage'
import AddOns from './CreateProfileScreen/AddOnsPage'
import Loader from '../Loader/Loader'
import './createprofile.css'
import { socialMediaOptions, urlRegex } from '../../helpers/Constant'
// import Utility from '../../helpers/Utility'

const CreatorCreateProfile = () => {
  const navigate = useNavigate()
  const userType = useSelector((state) => state.user?.user_type)
  const UserData = useSelector((state) => state.user?.userData)
  const UserDataRaw =
    typeof UserData === 'string' ? JSON.parse(UserData) : UserData
  const [appData, setAppData] = useState([])
  const [certificateUrl, setCertificateUrl] = useState([])
  const [addOnsData, setAddOnsData] = useState([])
  const [icon, setIcon] = useState([])
  const [userTypeId, setUserTypesId] = useState(1)
  const [organizationData, setOrganizationData] = useState([])
  const [verificationData, setVerificationData] = useState({
    description: '',
    website_link: '',
    certificate: '',
    local_address: '',
    local_address_image: [],
    store_link: '',
    organization_type: '',
    title: ''
  })
  const [iconImg, setIconImg] = useState(null)
  const [iconObj, setIconObj] = useState(null)
  const [errors, setErrors] = useState('')
  const [isAppLoading, setIsAppLoading] = useState(false)
  const [isAddonsLoading, setIsAddonsLoading] = useState(false)
  const [currentStep, setCurrentStep] = useState(1)
  const [socialMediaLinks, setSocialMediaLinks] = useState([])
  const [isImageLoading, setIsImageLoading] = useState({
    index: null,
    loading: false
  })
  const [draggedIndex, setDraggedIndex] = useState(null)

  // const [selectedImages, setSelectedImages] = useState([])

  const getOrganizationData = async () => {
    APICall('get', '', EndPoints.getOrganization, 'profile')
      .then((res) => {
        if (res.status === 200 && res.data?.code === 200) {
          setOrganizationData(res?.decryptedData)
        } else {
          toast.error(res.data?.message)
        }
      })
      .catch((error) => {
        toast.error(error?.data?.message)
      })
  }

  const handleChange = (e) => {
    setVerificationData({
      ...verificationData,
      [e.target.name]: e.target.value
    })
  }

  const getAddonsData = async (name = 'Creator') => {
    setIsAddonsLoading(true)
    APICall('get', '', `${EndPoints.addons}?type=creator`, 'profile')
      .then((res) => {
        if (res.status === 200 && res.data?.code === 200) {
          const data = res?.decryptedData
          if (name == 'Creator') {
            data.find((o) => o.name == 'Entertainment').checked = true
            data.find((o) => o.name == 'Entertainment').disabled = true
          }
          setAddOnsData(data)
          setIsAddonsLoading(false)
        } else {
          toast.error(res.data?.message)
          setIsAddonsLoading(false)
        }
      })
      .catch((error) => {
        toast.error(error?.data?.message)
        setIsAddonsLoading(false)
      })
  }

  const getApplicationsData = async () => {
    setIsAppLoading(true)
    APICall('get', '', EndPoints.applications, 'profile')
      .then((res) => {
        if (res.status === 200 && res.data?.code === 200) {
          const decryptedData = res?.decryptedData
          setAppData(decryptedData)
          setIsAppLoading(false)
        } else {
          toast.error(res.data?.message)
          setIsAppLoading(false)
        }
      })
      .catch((error) => {
        toast.error(error?.data?.message)
        setIsAppLoading(false)
      })
  }

  const clearValue = () => {
    setVerificationData({
      ...verificationData,
      description: '',
      website_link: '',
      certificate: '',
      local_address: '',
      local_address_image: [],
      store_link: '',
      organization_type: '',
      title: ''
    })
    setIconObj(null)
    setIconImg(null)
  }

  const nextStep = () => {
    setErrors('')
    // if (verificationData.store_link !== '') {
    //   if (!urlRegex.test(verificationData.store_link)) {
    //     setErrors(English.G73)
    //     return false
    //   }
    // } else if (verificationData.website_link !== '') {
    //   if (!urlRegex.test(verificationData.website_link)) {
    //     setErrors(English.G73)
    //     return false
    //   }
    // } else if (currentStep > 2 && socialMediaLinks.length > 0) {
    //   const allTrue = socialMediaLinks.every((obj) => urlRegex.test(obj?.link))
    //   if (!allTrue) {
    //     setErrors(English.G73)
    //     return false
    //   }
    // }
    setCurrentStep(currentStep + 1)
  }

  const prevStep = () => {
    setCurrentStep(currentStep - 1)
  }

  const handleInputChange = (index, value, img, socialMediaName) => {
    const updatedLinks = [...socialMediaLinks]
    updatedLinks[index] = {
      ...updatedLinks[index],
      link: value,
      img,
      name: socialMediaName
    }
    setSocialMediaLinks(updatedLinks)
  }

  const addInputField = () => {
    const options = socialMediaOptions.filter((option) => !socialMediaLinks.some((link) => link.name === option.name))
    if (socialMediaLinks.length < 7 && options.length != 0) {
      setSocialMediaLinks([
        ...socialMediaLinks,
        {
          img: options[0]?.img,
          name: options[0]?.name,
          link: ''
        }
      ])
    }
  }

  const removeInputField = (index) => {
    const updatedLinks = socialMediaLinks.filter((_, i) => i !== index)
    setSocialMediaLinks(updatedLinks)
  }

  const maxFileSizeMB = 10
  const maxFileSizeBytes = maxFileSizeMB * 1024 * 1024

  const handleImageUpload = async (file) => {
    if (!file) return

    if (file.size > maxFileSizeBytes) {
      toast.error(`File size exceeds ${maxFileSizeMB} MB`)
      return
    }
    try {
      const imgUrl = URL.createObjectURL(file)
      setIconImg(imgUrl)
      setIconObj(file)
      const compressedImage = await compressImage(file)
      const formData = new FormData()
      formData.append('file', compressedImage)
      formData.append('type', 'creator')
      formData.append('sub_type', 'profile_image')
      APICall('post', formData, EndPoints.uploadImage, 'profile', true)
        .then((res) => {
          if (res.status === 200 && res.data?.code === 200) {
            setIcon(dataDecrypt(res?.data?.data))
          } else {
            toast.error(English.G74)
          }
        })
        .catch((e) => {
          toast.error(e?.data?.message)
        })
    } catch (error) {
      toast.error(English.G75)
    }
  }

  const handleCertificateUpload = async (e) => {
    const file = e.target.files[0]
    if (file) {
      const compressedImage = await compressImage(file)
      const formData = new FormData()
      formData.append('image', compressedImage)
      APICall('post', formData, '', 'imageUpload', true)
        .then((res) => {
          if (res.status === 200 && res.data?.code === 200) {
            setCertificateUrl(s3ImageProfile + dataDecrypt(res.data.data))
          } else {
            toast.error(res?.data?.message)
          }
        })
        .catch((e) => {
          toast.error(e?.data?.message)
        })
    }
  }

  const handleImageChange = async (file, replaceIndex = null) => {
    if (file) {
      const reader = new FileReader()
      reader.onload = async () => {
        const compressedImage = await compressImage(file)
        const formData = new FormData()
        formData.append('file', compressedImage)
        formData.append('type', 'local')
        formData.append('sub_type', 'local_address_image')
        APICall('post', formData, EndPoints.uploadImage, 'profile', true)
          .then((res) => {
            if (res.status === 200 && res.data?.code === 200) {
              const decryptedImageData = dataDecrypt(res.data.data)
              setVerificationData((prev) => {
                const updatedImages = [...prev.local_address_image]

                if (replaceIndex !== null) {
                  updatedImages[replaceIndex] = decryptedImageData
                } else {
                  updatedImages.push(decryptedImageData)
                }

                return {
                  ...prev,
                  local_address_image: updatedImages
                }
              })
            } else {
              toast.error(res?.data?.message)
            }
          })
          .catch((e) => {
            toast.error(e?.data?.message)
          })
      }
      reader.readAsDataURL(file)
    }
  }

  const createProfileClick = () => {
    if (socialMediaLinks.length > 0) {
      const allTrue = socialMediaLinks.every((obj) => urlRegex.test(obj?.link))
      if (!allTrue) {
        setErrors(English.G73)
        return false
      }
    }
    const app_id = appData.filter(({ checked }) => checked).map((e) => e.id)
    const add_ons = addOnsData
      .filter(({ checked }) => checked)
      .map((e) => e.id)
    const socialMediaObject = socialMediaLinks.reduce((acc, item) => {
      acc[item.name] = item.link
      return acc
    }, {})
    const data = {
      user_type_id: userTypeId,
      verification_data: {
        ...verificationData,
        social_media: socialMediaObject
      },
      app_id,
      icon,
      add_ons
    }
    APICall('post', data, EndPoints.createProfile, 'profile')
      .then((res) => {
        if (res.status === 200 && res.data?.code === 200) {
          const userData = { ...UserData }
          userData.user_type_id = userTypeId
          const data_data = { ...userData?.creator_data, user_type_id: userTypeId }
          userData.creator_data = data_data
          const userDataNew = { ...userData, ...data_data }
          setUserSliceData(userDataNew, 'userInfo')
          toast.success(res?.data?.message)
          if (userType === 'creator') {
            navigate('/creator-slide', { state: { showThirdScreen: true } })
          }
          clearValue()
        } else {
          toast.error(res.data?.message)
        }
      })
      .catch((error) => {
        toast.error(error?.data?.message)
      })
  }

  const handleCheckboxChange = (index) => (e) => {
    const newCheckboxes = [...addOnsData]
    newCheckboxes[index].checked = e.target.checked
    setAddOnsData(newCheckboxes)
  }

  const handleCancelClick = (id) => {
    const newCheckboxes = addOnsData.map((addOn) => {
      if (addOn.id == id) {
        return { ...addOn, checked: false }
      }
      return addOn
    })
    setAddOnsData(newCheckboxes)
  }

  const getSelectedAddOns = () => {
    return addOnsData.filter((addOn) => addOn.checked)
  }

  useEffect(() => {
    if (UserData?.user_type_id && UserData?.user_type_id !== 0) {
      nextStep()
      setUserTypesId(1)
    }
    getApplicationsData()
    getAddonsData()
    getOrganizationData()
  }, [])

  const removeImage = (index) => {
    const updatedImages = [...verificationData.local_address_image]
    updatedImages.splice(index, 1)
    setVerificationData((prev) => ({
      ...prev,
      local_address_image: updatedImages
    }))
  }

  const handleDragStart = (index) => {
    setDraggedIndex(index)
  }

  const handleDrop = (index) => {
    if (index !== draggedIndex) {
      setVerificationData((prev) => {
        const copy = [...prev.local_address_image]
        const b = copy[draggedIndex]
        copy[draggedIndex] = copy[index]
        copy[index] = b
        return {
          ...prev,
          local_address_image: copy
        }
      })

      setDraggedIndex(null)
    }
  }

  const handleLoad = (index) => {
    setIsImageLoading({
      index,
      loading: true
    })
    setTimeout(() => {
      setIsImageLoading({
        index: null,
        loading: false
      })
    }, 500)
  }

  const renderImg = useMemo(() => {
    return (
      <>
        {verificationData.local_address_image.map((image, index) => {
          return (
            <div
              className={`image position-relative ${
                index === 0 ? 'first-image' : ''
              }`}
              key={index}
              alt={`image-${index}`}
              draggable
              onDragStart={() => {
                handleDragStart(index)
              }}
              onDragOver={(event) => event.preventDefault()}
              onDragEnter={(event) => event.preventDefault()}
              onDrop={(event) => {
                event.preventDefault()
                handleDrop(index)
              }}
            >
              {isImageLoading.index === index && isImageLoading.loading && (
                <Loader />
              )}
              <img
                className={`image position-relative ${
                  index === 0 ? 'first-image' : ''
                }`}
                src={
                  typeof image === 'string'
                    ? s3ImageProfile + image
                    : URL.createObjectURL(image)
                }
                alt={`image-${index}`}
                style={{
                  width: '100%',
                  height: '100%',
                  objectFit: 'cover'
                }}
                onLoad={() => handleLoad(index)}
              />
              <button type="button" onClick={() => removeImage(index)}>
                <img src={Images.Remove} alt="" />
              </button>
              <button type="button" className="plus-img">
                <CommonImageUpload
                  handleImageChange={(file) => {
                    handleImageChange(file, index)
                  }}
                  selectedImg=""
                  defaultImg={Images.plusimg}
                  className="plsimg"
                />
              </button>
            </div>
          )
        })}
      </>
    )
  }, [verificationData.local_address_image, handleDragStart])

  const renderContent = () => {
    switch (currentStep) {
      case 1:
        return (
          <SelectApplications
            isAppLoading={isAppLoading}
            appData={appData}
            setAppData={setAppData}
            nextStep={nextStep}
            prevStep={prevStep}
            UserDataRaw={UserDataRaw}
            urlSystemIcon={urlSystemIcon}
            Images={Images}
          />
        )
      case 2:
        return (
          <AddOns
            isAddonsLoading={isAddonsLoading}
            addOnsData={addOnsData}
            getSelectedAddOns={getSelectedAddOns}
            handleCancelClick={handleCancelClick}
            handleCheckboxChange={handleCheckboxChange}
            prevStep={prevStep}
            createProfileClick={nextStep}
            s3ImagePath={s3ImageProfile}
            urlSystemIcon={urlSystemIcon}
            Images={Images}
          />
        )
      case 3:
        return (
          <ProfileVerification
            iconImg={iconImg}
            handleImageUpload={handleImageUpload}
            verificationData={verificationData}
            handleChange={handleChange}
            socialMediaLinks={socialMediaLinks}
            socialMediaOptions={socialMediaOptions}
            handleInputChange={handleInputChange}
            removeInputField={removeInputField}
            errors={errors}
            addInputField={addInputField}
            certificateUrl={certificateUrl}
            handleCertificateUpload={handleCertificateUpload}
            renderImg={renderImg}
            handleImageChange={handleImageChange}
            organizationData={organizationData}
            prevStep={prevStep}
            nextStep={createProfileClick}
            iconObj={iconObj}
            Images={Images}
          />
        )
      default:
        return null
    }
  }

  return (
    <div className="profile-left-wrapper">
      <div
        className={`create-profile ${currentStep === 2 || currentStep === 3 || currentStep === 4 ? 'case-4-active' : ''}`}
      >
        {renderContent()}
      </div>
    </div>
  )
}

export default CreatorCreateProfile
