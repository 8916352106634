import { EventEmitter } from 'fbemitter'

export const emitter = new EventEmitter()

export const EventType = {
    ProfileUpdate: 'ProfileUpdate',
    StoreUpdate: 'StoreUpdate',
    ProductLibraryDelete: 'ProductLibraryDelete',
    addHotelList: 'addHotelList',
    particularHotel: 'particularHotel',
    addRestaurantList: 'addRestaurantList',
    particularRestaurant: 'particularRestaurant',
    addOrganizerList: 'addOrganizerList',
    particularOrganizer: 'particularOrganizer'
}
