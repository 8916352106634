/* eslint-disable no-useless-escape */
import React, { useState, useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import SideBarPage from './SidebarPage'
import '../../App.css'
import CommonButton from '../ButtonComponent/ButtonCommon'
import CommonInput from '../InputComponet/CommonInput'
import { preventSpaceInput, preventSpacePaste } from '../../helpers/Utility'
import English from '../../helpers/English'
import Images from '../../helpers/Images'
import { APICall, EndPoints } from '../../services'

const ResetPasswordPage = () => {
  const navigate = useNavigate()
  const location = useLocation()

  const [showPassword1, setShowPassword1] = useState(false)
  const [showPassword2, setShowPassword2] = useState(false)
  const [inputFields, setInputFields] = useState({
    email: location.state?.email || '',
    otp: '',
    password: '',
    confirm_password: '',
    app_id: 2
  })
  const [errors, setErrors] = useState({})
  const [submitting, setSubmitting] = useState(false)
  const [isButtonEnabled, setIsButtonEnabled] = useState(false)
  const [countdown, setCountdown] = useState(59)
  const [canResend, setCanResend] = useState(false)
  const [firstTime, setFirstTime] = useState(true)
  const [loading, setLoading] = useState(false)

  const validateValues = (inputValues) => {
    const errors = {}
    if (inputValues.otp.length !== 6) {
      errors.otp = English.G33
    }
    const regexPass =
      /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[!@#$%^&*()\-_=+\|[\]{};:/?.>])[A-Za-z\d!@#$%^&*()\-_=+\|[\]{};:/?.>]{8,}$/
    if (!inputValues.password.match(regexPass)) {
      errors.password = English.G25
    }
    if (inputValues.password !== inputValues.confirm_password) {
      errors.confirm_password = English.G26
    }
    return errors
  }

  const handleChange = (e) => {
    const { name, value } = e.target
    setInputFields((prevFields) => {
      const updatedFields = { ...prevFields, [name]: value }

      const updatedErrors = { ...errors }

      if (name === 'password') {
        if (
          updatedFields.password.match(
            /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[!@#$%^&*()\-_=+\|[\]{};:/?.>])[A-Za-z\d!@#$%^&*()\-_=+\|[\]{};:/?.>]{8,}$/
          )
        ) {
          delete updatedErrors.password
        }
        if (updatedFields.password === updatedFields.confirm_password) {
          delete updatedErrors.confirm_password
        }
      }

      if (name === 'confirm_password') {
        if (updatedFields.password === updatedFields.confirm_password) {
          delete updatedErrors.confirm_password
        } else {
          // updatedErrors.confirm_password = 'Password miss match'
        }
      }

      setErrors(updatedErrors)
      return updatedFields
    })
  }

  const handleSubmit = (event) => {
    event.preventDefault()
    setErrors(validateValues(inputFields))
    setSubmitting(true)
  }

  const finishSubmit = () => {
    setLoading(true)
    APICall('post', inputFields, EndPoints.resetPassword, 'auth')
      .then((res) => {
        if (res.status === 200 && res.data?.code === 200) {
          navigate('/login')
          setLoading(false)
          toast.success(res?.data?.message)
        } else {
          toast.error(res.data?.message)
          setLoading(false)
        }
      })
      .catch((error) => {
        toast.error(error?.data?.message)
        setLoading(false)
      })
  }

  const togglePasswordVisibility1 = () => {
    setShowPassword1(!showPassword1)
  }

  const togglePasswordVisibility2 = () => {
    setShowPassword2(!showPassword2)
  }

  const ResendOtp = () => {
    const payload = {
      email: inputFields.email,
      app_id: inputFields.app_id
    }
    APICall('post', payload, EndPoints.forgotPassword, 'auth')
      .then((res) => {
        if (res.status === 200 && res.data?.code === 200) {
          toast.success(English.G34)
          setCountdown(59)
          setCanResend(false)
          setInputFields((prev) => ({ ...prev, otp: '' }))
        } else {
          toast.error(res.data?.message)
        }
      })
      .catch((error) => {
        toast.error(error?.data?.message)
      })
  }

  useEffect(() => {
    if (firstTime) {
      setFirstTime(false)
    } else {
      if (canResend) {
        setCountdown(59)
      }
      const timer = setInterval(() => {
        setCountdown((prevCountdown) => {
          if (prevCountdown === 0) {
            clearInterval(timer)
            setCanResend(true)
          }
          return Math.max(prevCountdown - 1, 0)
        })
      }, 1000)
      return () => clearInterval(timer)
    }
  }, [firstTime, canResend])

  useEffect(() => {
    if (Object.keys(errors).length === 0 && submitting) {
      finishSubmit()
    }
  }, [errors])

  useEffect(() => {
    setIsButtonEnabled(
      inputFields.confirm_password.length > 0 &&
        inputFields.password.length > 0 &&
        inputFields.otp.length > 5
    )
  }, [inputFields])

  return (
    <div className=" auth-register">
      <div className=" bg-overlay row overlay-height">
        <div className="col-md-6 py-[20px]">
          <SideBarPage />
        </div>
        <div className="col-md-6 h-100 pt-[20px] pb-4">
          <div className="inline-padding bg-white rounded-2xl h-full d-flex flex-col mr-[20px] overflow-y-auto justify-center no-scrollbar">
            <h2 className="bg-[#151515] text-center login_sign_text">
              {English.A33}
              <br />
              {English.A34}
            </h2>
            <h6 className="bg-[#303030] text-center mb-12 !max-w-[500px] !mx-auto !mt-[12px]">{English.A35}</h6>
            <form onSubmit={handleSubmit}>
              <p className="mb-4">
                <div className="password-input input-container">
                  <img
                    src={Images.lock_icon}
                    alt="password"
                    className="input-logo"
                  />
                  <CommonInput
                    name="otp"
                    placeholder={English.G37}
                    onChange={handleChange}
                    required
                    maxLength={6}
                    pattern="\d{6}"
                    inputMode="numeric"
                    onInput={(e) => {
                      e.target.value = e.target.value
                        .replace(/\D/g, '')
                        .slice(0, 6)
                    }}
                    className="custom-input capitalize"
                    value={inputFields.otp || ''}
                  />
                </div>
                {errors.otp ? <p className="error-text">{errors.otp}</p> : null}
              </p>
              <p className="mb-4">
                <div className="password-input input-container">
                  <img
                    src={Images.lock_icon}
                    alt="password"
                    className="input-logo"
                  />
                  <CommonInput
                    type={showPassword1 ? 'text' : 'password'}
                    name="password"
                    placeholder={English.A37}
                    required
                    onChange={handleChange}
                    onKeyDown={preventSpaceInput}
                    onPaste={preventSpacePaste}
                    className="custom-input"
                  />
                  <button onClick={togglePasswordVisibility1} type="button">
                    {showPassword1 ? (
                      <img alt="" src={Images.eyeOn} />
                    ) : (
                      <img alt="" src={Images.eyeOff} />
                    )}
                  </button>
                </div>
                {errors.password ? (
                  <p className="error-text">{errors.password}</p>
                ) : null}
              </p>
              <p className="mb-4">
                <div className="password-input input-container">
                  <img
                    src={Images.lock_icon}
                    alt="password"
                    className="input-logo"
                  />
                  <CommonInput
                    type={showPassword2 ? 'text' : 'password'}
                    name="confirm_password"
                    placeholder={English.A38}
                    required
                    onChange={handleChange}
                    onKeyDown={preventSpaceInput}
                    onPaste={preventSpacePaste}
                    className="custom-input"
                  />
                  <button onClick={togglePasswordVisibility2} type="button">
                    {showPassword2 ? (
                      <img alt="" src={Images.eyeOn} />
                    ) : (
                      <img alt="" src={Images.eyeOff} />
                    )}
                  </button>
                </div>
                {errors.confirm_password ? (
                  <p className="error-text">{errors.confirm_password}</p>
                ) : null}
              </p>
              <div className="reset_password_btn">
                <p>
                  <CommonButton
                    label={
                      canResend
                        ? `${English.A39}`
                        : `${English.A39} in ${countdown}s`
                    }
                    type="button"
                    id="resend_btn"
                    onClick={ResendOtp}
                    className="primary-button m-auto !w-[280px]"
                    disabled={!canResend}
                    context="primary"
                    isPrimary
                  />
                </p>
                <p>
                  <CommonButton
                    label={English.A36}
                    type="submit"
                    id="submit_btn"
                    loading={loading}
                    className="primary-button m-auto !w-[280px] !px-[30px]"
                    disabled={!isButtonEnabled || loading}
                    context="primary"
                    isPrimary
                  />
                </p>
              </div>
            </form>
            <fotoor className="mt-[40px]">
              <div className="text-[10px] text-placeholder text-center items-center justify-center !max-w-[550px] !mx-auto">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                Dolor erat enim mattis nunc, eget volutpat. Aenean sed urna,
                facilisi vitae, volutpat faucibus.enim mattis nunc,
                eget volutpat. Aenean sed urna, facilisi vitae, volutpat faucibus
              </div>
            </fotoor>
          </div>
        </div>
      </div>
    </div>
  )
}
export default ResetPasswordPage
